import React from 'react';
import {
	Space,
	Table,
	Tag,
	Tooltip,
	Badge,
	Row,
	Col,
	Avatar,
	Typography,
	Image } from 'antd';
import { PictureOutlined } from "@ant-design/icons";

const TableStock = ({dataSource, loading}) => {
	const { Text, Link } = Typography;
	const columns = [
		{
			ellipsis: true,
			title: <Tooltip title="Dias sin Stock">Title</Tooltip>,
			dataIndex: 'title',
			width: 500,
			key: 'title',
			render: (value, record) => {
					return renderProduct(record)
			},
		},
		{
			title: 'Marketplace',
			dataIndex: 'marketplace',
			key: 'marketplace',
		},
		{
			title: 'Average Stock',
			dataIndex: 'averageStock',
			key: 'averageStock',
		},
		{
			title: 'Available Stock',
			dataIndex: 'availableStock',
			key: 'availableStock',
		},
		{
			ellipsis: true,
			title: <Tooltip title="Dias sin Stock">Status</Tooltip>,
			dataIndex: 'status',
			key: 'status',
			align: 'center',
		    filters: [
		      {
		        text: 'En transito',
		        value: 'en transito',
		      },
		      {
		        text: 'Enviar stock',
		        value: 'enviar stock',
		      },
		      {
		        text: 'Sin acción requerida',
		        value: 'ok',
		      },
		    ],
		    onFilter: (value, record) => record.status.startsWith(value),
		    filterSearch: true,		
	        render: (status) => (

	          <span>
	          {
	          	status === 'en transito' &&
		            <Tag color={'warning'} key={status}>
		            	{status.toUpperCase()}
		            </Tag>            	            
	          }
	          {
	          	status === 'ok' &&
		            <Tag color={'success'} key={status}>
		            	{status.toUpperCase()}
		            </Tag>       	            
	          }
	          {
	          	status === 'enviar stock' &&
		            <Tag color={'error'} key={status}>
		            	{status.toUpperCase()}
		            </Tag>	             	            
	          }
	          </span>
	        ),		
		} 
	];


    const renderProduct = (record) => {
        const imgUrl = record?.mainImages?.length > 0 ? record?.mainImages[0]?.url : null;
        return (<>
            <Row align="middle" justify="start">
                <Col span={24}>
                    <Space>
                        {imgUrl ?
                            <Avatar className="home-listing-table-avatar" src={<Image src={`${imgUrl}`} />} /> :
                            <Avatar className="home-listing-table-avatar" icon={<PictureOutlined />} />
                        }
                        <Space direction="vertical">
                            {getLinkToStore[record?.marketplace](record)}
                            <Tooltip title={record.name}>
                                <strong>SKU:</strong> {record.product}
                            </Tooltip>
                        </Space>

                    </Space>
                </Col>
            </Row></>
        );
    }

    const getLinkToStore = {
        "Amazon": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.com/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Mexico": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.mx/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Canada": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.ca/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Brazil": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.br/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.com/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Canada": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.ca/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Spain": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.es/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Germany": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.de/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Walmart": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.walmart.com/ip/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Shopify": (item) => {
            return <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
    }

	return (
		<Table columns={columns} loading={loading} dataSource={dataSource} />
	)
} 

export default TableStock;