import react, { useEffect, useState }from 'react'
import moment from 'moment';
import { DualAxes } from '@ant-design/plots';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import selectors from '../../../redux/analytic/dashboard/selectors';
import selectorsMkt from '../../../redux/analytic/marketing/selectors';
import { Row, Col, Card, Typography, Button, DatePicker, Tabs, Select, Spin } from 'antd';
import { thousandsSeparator, sumaDataArray, averageDataArray, mixPanelCreateEvent } from '../../../utils/functions';
import TableData from './TableData'
import actionsAnalitycs from '../../../redux/analytic/dashboard/actions';
import actionsMarketing from '../../../redux/analytic/marketing/actions';
import { dateTypes, sellerMarketplaces } from '../../../utils/const';
import marketingApi from '../../../api/marketing';
import ip from 'ip'

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const IndexGraphics = ({dataMarketing}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
	const clientsMkt = useSelector(selectorsMkt.selectClientsMkt);
	const rows = useSelector(selectors.selectRows);
	const session = useSelector(store => store.Session.session);
	const [dataMarketingLine, setDataMarketingLine] = useState([]) 
	const [listMarketplace, setListMarketplace] = useState([]) 
	const [dataMarketingColumns, setDataMarketingColumns] = useState([]) 
	const [sumaTotal, setSumaTotal] = useState(0) 
	const [reportId, setReportId] = useState('') 
	const [profileId, setProfileId] = useState(0) 
	const [statusReport, setStatusReport] = useState(false) 
	const [loadingData, setLoadingData] = useState(false) 
	const [dataReport, setDataReport] = useState([]) 
	const [dataReportCamp, setDataReportCamp] = useState([]) 
	const [dataReportCampMonth, setDataReportCampByMonth] = useState([]) 
	const [filterBase, setFilterBase] = useState({}) 
	const [ventasTotales, setVentasTotales] = useState(0) 
	const [dataReportMain, setDataReportMain] = useState([]) 

    const searchByPeriodAmount = (m, p) => {
        let ptrs = []
        ptrs = [session.userInfo.commercial_partner_id[0]]
        if (rows?.length > 0 && ptrs) {
            const defaultFilters = {
                sku: [],
                partner: ptrs,
                compare: false,
                dateRangeTwo: [],
                category: ['sales', 'stock'],
                marketplace: Object.values(sellerMarketplaces),
                dateType: dateTypes.PURCHASE_DATE,
                dateRangeOne: [moment().add(-m, p), moment()],
            };
            dispatch(actionsAnalitycs.doFetch(defaultFilters));
        }
    }    

    const searchDataMarketingForM = async (m, p) => {
        let date = { 
        	from: moment(moment().add(-m, p)).format("YYYY-MM-DD"),
        	to: moment().format("YYYY-MM-DD")
        }
       	await marketingApi.getReport(filterBase.report, filterBase.profile, date, session.userInfo.commercial_partner_id[0])
            .then((response) => {
				setDataReport(response.data.sort(function (a, b) { return new Date(a.date) - new Date(b.date) }))
            	setLoadingData(false)
            })
            .catch((error) => {
            	setLoadingData(false)
                //openNotification({ status: false, content: getErrorMessage(error) });
            });
    }    

   	const searchMarketplaceInMarketing = async (id) => {
   		setLoadingData(true)
        await marketingApi.generateReport(id, session.userInfo.commercial_partner_id[0])
            .then((response) => {
            	setProfileId(id)
                setReportId(response.data.reportId);
                let date = { 
                	from: moment(moment().add(-12, 'M')).format("YYYY-MM-DD"),
                	to: moment().format("YYYY-MM-DD")
                }
                setFilterBase({
                	profile: id,
                	report: response.data.reportId,
                	date,
                })
                searchDataMarketing(response.data.reportId, id, date)
                setLoadingData(false)
            })
            .catch((error) => {
                //openNotification({ status: false, content: getErrorMessage(error) });
            });    	
    }

    const reportCampaign = async (report, profile, partnerId) => {
       await marketingApi.getReportCampaign(report, profile, partnerId)
            .then((response) => {
            	mixPanelCreateEvent('API Rest', {
            		status: response.status,
            		message: 'Report Campaign API Data Returned',
            		method: 'GET',
            		url: response.config.url,
            		ipClient: ip.address()
            	})
        		setDataReportCamp(response.data.sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
        		orderByMonth(response.data)
            })
            .catch((error) => {
            	mixPanelCreateEvent(`API Rest`, {
            		status: error,
            		message: 'Marketing API Campaign Report Not Responding',
            	})          	
                //openNotification({ status: false, content: getErrorMessage(error) });
            });
    }

    const country = (cod) => {
    	if(cod === 'US') {
    		return 'Estados Unidos'
    	} else if (cod === 'CA') {
    		return 'Canadá'
    	} else if (cod === 'MX'){
    		return 'Mexico'
    	}
    }

    const searchDataMarketing = async (report, profile, date) => {
       await marketingApi.getReport(report, profile, date)
            .then((response) => {
        		setDataReport(response.data)
        		//setDataReportMain(response.data)
            })
            .catch((error) => {
                //openNotification({ status: false, content: getErrorMessage(error) });
            });
    }

    const searchDataMarketingAvailable = async () => {
    	mixPanelCreateEvent('Action Button', {action: 'Search Marketing Data'})
    	setDataReport([])
    	setLoadingData(true)
    	reportCampaign(filterBase.report, filterBase.profile, session.userInfo.commercial_partner_id[0])
       	await marketingApi.getReport(filterBase.report, filterBase.profile, filterBase.date, session.userInfo.commercial_partner_id[0])
            .then((response) => {
				setDataReport(response.data.sort(function (a, b) { return new Date(a.date) - new Date(b.date) }))
				setDataReportMain(response.data.sort(function (a, b) { return new Date(a.date) - new Date(b.date) }))
            	mixPanelCreateEvent('API Rest', {
            		status: response.status,
            		message: 'Marketing API Data Returned',
            		method: 'GET',
            		url: response.config.url,
            		ipClient: ip.address()
            	})
            	setLoadingData(false)
            })
            .catch((error) => {
            	mixPanelCreateEvent(`API Rest`, {
            		status: error,
            		message: 'Non-Responsive Marketing API'
            	})
            	setLoadingData(false)
                //openNotification({ status: false, content: getErrorMessage(error) });
            });
    }


    const orderByMonth = (array) => {
    	let baseArra = []
        let datesExist = []
	    array.map((e) => {
	    	let arrayEle = []
	        let yearAndMonth = e.date.slice(0,7)
	        let existeMes = datesExist.filter((ele) => ele === yearAndMonth)
	        if(existeMes.length < 1){
		        datesExist.push(yearAndMonth)
		        dataReportCamp.map((object) => {
		        	let dateB = object.date.slice(0,7)
		        	if(dateB === yearAndMonth) {
		        		arrayEle.push(object)
		        	}
		        })
				baseArra.push({
					advertisingCostOfSales: sumaDataArray(arrayEle, 'advertisingCostOfSales'),
					clicksThroughRate: sumaDataArray(arrayEle, 'clicksThroughRate'),
					conversionRate: sumaDataArray(arrayEle, 'conversionRate'),
					costPerClick: sumaDataArray(arrayEle, 'costPerClick') / arrayEle.length,
					date: yearAndMonth,
					clicks: sumaDataArray(arrayEle, 'clicks'),
					impressions: sumaDataArray(arrayEle, 'impressions'),
					orders: sumaDataArray(arrayEle, 'orders'),
					returnOnAdSpend: sumaDataArray(arrayEle, 'returnOnAdSpend'),
					sales: sumaDataArray(arrayEle, 'sales'),
					spend: sumaDataArray(arrayEle, 'spend'),				 	
				})
	        }
	    })
	    setDataReportCampByMonth(baseArra.sort(function (a, b) { return new Date(b.date) - new Date(a.date) }))
    }

	const orderLines = (data) => {
		let dataLine = []
		let dataColumns = []
		data.map((element) => {
			let params = Object.keys(element)
			params.find((search) => {
				if(search === 'spend') {
					dataColumns.push({
						date: element.date,
						values: element.spend,
						name: search
					})					
				}
				if(search === 'clicks') {
					dataLine.push({
						date: element.date,
						value: element.clicks,
						type: search
					})
				}
				if(search === 'orders') {
					dataLine.push({
						date: element.date,
						value: element.orders,
						type: search
					})					
				}
				if(search === 'sales') {
					dataColumns.push({
						date: element.date,
						values: element.sales,
						name: search
					})
				}				
			})
		})
		setDataMarketingLine(dataLine)
		setDataMarketingColumns(dataColumns)
	}

	useEffect(() => {
		let clientsFinal = clientsMkt
		if(clientsFinal.length > 0){
			setListMarketplace(clientsFinal)
		}
	}, [clientsMkt])

	useEffect( async () => {
		if(rows.length > 0){
			let suma = thousandsSeparator(rows[0]?.sumTotalSoldUSD)
			let sumaSpend = Math.round(sumaDataArray(dataReport, 'spend'))
		}
		await dispatch(actionsMarketing.getClients(session.userInfo.commercial_partner_id[0]));
		searchByPeriodAmount(1, 'M')
	}, [])

	useEffect(() => {
		orderLines(dataReport)
	}, [dataReport])

	const columns = [
	  {
	    title: 'Fecha',
	    dataIndex: 'date',
	    key: 'date',
	  },
	  {
	    title: 'Nombre',
	    dataIndex: 'campaignName',
	    key: 'campaignName',
	    render: (text) => <a>{text}</a>,
	  },
	  {
	    title: 'Visualizaciones',
	    dataIndex: 'impressions',
	    key: 'impressions',
	  },
	  {
	    title: 'Clicks',
	    dataIndex: 'clicks',
	    key: 'clicks',
	  },
	  {
	    title: 'CTR (C)',
	    dataIndex: 'clicksThroughRate',
	    key: 'clicksThroughRate',
	    render: (text) => <span>{Math.round(text * 100) / 100}</span>
	  },
	  {
	    title: 'Gastos',
	    dataIndex: 'spend',
	    key: 'spend',
	  },
	  {
	    title: 'CPC (C)',
	    dataIndex: 'costPerClick',
	    key: 'costPerClick',
	    render: (text) => <span>{Math.round(text * 100) / 100}</span>
	  },
	  {
	    title: 'Órdenes',
	    dataIndex: 'orders',
	    key: 'orders',
	  },
	  {
	    title: 'Ventas por campaña',
	    dataIndex: 'sales',
	    key: 'sales',
	  },
	  {
	    title: 'ACOS',
	    dataIndex: 'advertisingCostOfSales',
	    key: 'advertisingCostOfSales',
	    render: (text) => <span>{Math.round(text * 100) / 100}</span>
	  }
	];


	const columnsCampMonth = [
	  {
	    title: 'Mes',
	    dataIndex: 'date',
	    key: 'date',
	    render: (text) => <a>{text}</a>,
	  },
	  {
	    title: 'Visualizaciones',
	    dataIndex: 'impressions',
	    key: 'impressions',
	  },
	  {
	    title: 'Clicks',
	    dataIndex: 'clicks',
	    key: 'clicks',
	  },
	  {
	    title: 'CTR (C)',
	    dataIndex: 'clicksThroughRate',
	    key: 'clicksThroughRate',
	    render: (text) => <span>{Math.round(text * 100) / 100}%</span>
	  },
	  {
	    title: 'Gastos',
	    dataIndex: 'spend',
	    key: 'spend',
	    render: (text) => <span>${text}</span>
	  },
	  {
	    title: 'CPC (C)',
	    dataIndex: 'costPerClick',
	    key: 'costPerClick',
	    render: (text) => <span>${Math.round(text * 100) / 100}</span>
	  },
	  {
	    title: 'Órdenes',
	    dataIndex: 'orders',
	    key: 'orders',
	  },
	  {
	    title: 'Ventas por campaña',
	    dataIndex: 'sales',
	    key: 'sales',
	    render: (text) => <span>${Math.round(text * 100) / 100}</span>
	  },
	  {
	    title: 'ACOS',
	    dataIndex: 'advertisingCostOfSales',
	    key: 'advertisingCostOfSales',
	    render: (text) => <span>{Math.round(text * 100) / 100}%</span>
	  }
	];

	const dataSource = [
	  {
	    mes: '1',
	    name: 'John Brown',
	    age: 32,
	    address: 'New York No. 1 Lake Park',
	    tags: ['nice', 'developer'],
	  },
	  {
	    mes: '2',
	    name: 'Jim Green',
	    age: 42,
	    address: 'London No. 1 Lake Park',
	    tags: ['loser'],
	  },
	  {
	    mes: '3',
	    name: 'Joe Black',
	    age: 32,
	    address: 'Sidney No. 1 Lake Park',
	    tags: ['cool', 'teacher'],
	  },
	];

	const config = {
		data: [dataMarketingColumns, dataMarketingLine],
		xField: 'date',
		yField: ['values', 'value'],
		columnStyle: {
		    radius: [20, 20, 0, 0],
		},
	    yAxis: {
	      value: {
	        label: {
	            formatter: (v) => `${v}`,
	        }
	      },
	      values: {
	        label: {
	            formatter: (v) => `${v} USD`,
	        }
	      }
	    },
        tooltip: {
          formatter: (datum) => {
            return { name: datum.name || datum.type, value: datum.name ? '$' + Math.round(datum.values * 100) / 100 :  datum.value}
          }
        },
		geometryOptions: [
		  {
		    geometry: 'column',
		    isGroup: true,
		    seriesField: 'name',
		    columnWidthRatio: 0.4,
		  },
		  {
		    geometry: 'line',
		    seriesField: 'type',
		    lineStyle: ({ type }) => {
		      if (type === 'clicks') {
		        return {
		          lineDash: [1, 4],
		          opacity: 1,
		        };
		      }

		      return {
		        opacity: 0.5,
		      };
		    },
		  },
		],
	};

	return (
        <>
        	<Card bordered={false} style={{ width: '100%', marginTop: 5}}>
	        	<Row style={{ marginBottom: 20, marginTop: 20, marginRight: 10 }}>
	                <Col xs={24} md={24} lg={12} xl={12} span={12} style={{ textAlign: 'right' }}>
	                	<Title level={4}>Buscar por pais: </Title>
	                </Col>
	                <Col xs={24} md={24} lg={11} xl={11} span={11} style={{marginLeft:10, textAlign: 'left' }}>                 
					    <Select
					      defaultValue=""
					      style={{
					        width: '30%',
					      }}
					      onChange={searchMarketplaceInMarketing}
					    >
					    {
					    	listMarketplace.map((element) => 
					      		<Option value={element.profileId}>{country(element.countryCode)}</Option>
					    	) 
					    }
					    </Select>
					    <Button disabled={profileId != 0 ? false : true} onClick={searchDataMarketingAvailable}>{clientsMkt.length < 1 ? <Spin size="small" /> : 'CONSULTA' }</Button>
	                </Col>
	        	</Row>    
        	</Card>    
        	{
	        	dataReport.length > 0 ?
	        	<>
		        	<Row style={{ marginTop: 10 }}>
		                <Col xs={24} md={24} lg={12} xl={12} span={12} style={{ textAlign: 'left' }}>
		                    <Title level={4}>Datos Acumulados Ventas/Campañas</Title>
		                </Col>              
		        	</Row>
		            <Row className='intro-totales'>
		                <Col xs={24} md={24} lg={6} xl={6} span={6}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5}}>
		                        <Title level={4}>Ventas Totales (USD)</Title>
		                        <Title level={3}><center>${rows[0] ? thousandsSeparator(parseInt(rows[0]?.sumTotalSoldUSD) + parseInt(sumaDataArray(dataReportMain, 'sales'))) : 0}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={5} xl={5} span={5}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Ventas Campañas (USD)</Title>
		                        <Title style={{ color: 'green' }} level={3}><center>${sumaDataArray(dataReportMain, 'sales')}</center></Title>
		                    </Card>
		                </Col>                
		                <Col xs={24} md={24} lg={4} xl={4} span={4}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Cantidad</Title>
		                        <Title level={3}><center>{sumaDataArray(dataReportMain, 'orders')}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={5} xl={5} span={5}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Gastos</Title>
		                        <Title style={{ color: 'green' }} level={3}><center>${sumaDataArray(dataReportMain, 'spend')}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={4} xl={4} span={4}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>TACOS {}</Title>
		                        <Title style={{ color: 'green' }} level={3}><center>{ rows.length > 0 ? Math.round(sumaDataArray(dataReportMain, 'spend') / rows[0]?.sumTotalSoldUSD * 100) / 100 : 0}%</center></Title>
		                    </Card>
		                </Col>
		            </Row>   
		        	<Row>
		                <Col style={{ marginTop: 20, marginBottom: 10, textAlign: 'left' }} xs={24} md={24} lg={12} xl={12} span={12}>
		                    <Title level={4}>Datos de campañas por periodo</Title>
		                </Col> 
		                <Col style={{ marginTop: 20, marginBottom: 10 }} xs={24} md={24} lg={12} xl={12} span={12}>
		                    <Button style={{ marginLeft: 5 }} onClick={()=> searchDataMarketingForM(moment().format('DD'), 'd')}>Mes Actual</Button>
		                    <Button style={{ marginRight: 20 }} onClick={()=> searchDataMarketingForM(1, 'w')}>Ultimos 30 dias</Button>
						    {
						    /*<RangePicker
						      style={{ width: '50%' }}
						      dateRender={(current) => {
						        const style = {};

						        if (current.date() === 1) {
						          style.border = '1px solid #1890ff';
						          style.borderRadius = '50%';
						          style.width = '100%'
						        }

						        return (
						          <div className="ant-picker-cell-inner" style={style}>
						            {current.date()}
						          </div>
						        );
						      }}
						    />*/

						    }
		                </Col>        	
		        	</Row>            
		            <Row className='intro-totales'>
		                <Col xs={24} md={24} lg={6} xl={6} span={6}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Ventas Campañas</Title>
		                        <Title level={1}><center>${sumaDataArray(dataReport, 'sales')}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={5} xl={5} span={5}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5}}>
		                        <Title level={4}>Gastos</Title>
		                        <Title level={1}><center>${sumaDataArray(dataReport, 'spend')}</center></Title>
		                    </Card>
		                </Col>           
		                <Col xs={24} md={24} lg={4} xl={4} span={4}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>CPC (Average)</Title>
		                        <Title style={{ color: 'green' }} level={1}><center>${averageDataArray(dataReport, 'costPerClick')}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={4} xl={4} span={4}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Clicks</Title>
		                        <Title style={{ color: 'green' }} level={1}><center>{sumaDataArray(dataReport, 'clicks')}</center></Title>
		                    </Card>
		                </Col>
		                <Col xs={24} md={24} lg={5} xl={5} span={5}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Impresiones </Title>
		                        <Title style={{ color: 'green' }} level={1}><center>{sumaDataArray(dataReport, 'impressions')}</center></Title>
		                    </Card>
		                </Col>
		            </Row>                                
		            <Row style={{ textAlign: 'left' }}>
		                <Col span={24}>
		                    <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
		                        <Title level={4}>Resumen de ventas</Title>
		                        <DualAxes {...config} />
		                    </Card>
		                </Col>
		            </Row>
		            <Row style={{ textAlign: 'left' }}>
		                <Col xs={24} md={24} lg={24} xl={24} span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
		                    <Card className='cardTableDashboard' bordered={false} style={{ width: '100%', marginTop: 5, paddingLeft: '0px', paddingRight: '0px' }}>
								<Tabs defaultActiveKey="1">
									<Tabs.TabPane tab="Campañas" key="1">
		                        	  	<Title level={4}>Campañas</Title>
		                        	  	{
		                        	  		dataReportCamp.length > 0 ?
									  			<TableData columns={columns} data={dataReportCamp} />
									  		:
									  			<Spin size="small" />
		                        	  	}
									</Tabs.TabPane>
									<Tabs.TabPane tab="Productos" key="2">
										<Title level={4}>Productos</Title>
									  	<TableData columns={columns} data={dataSource} />
									</Tabs.TabPane>
									<Tabs.TabPane tab="Tags" key="3">
										<Title level={4}>Tags</Title>
									  	<TableData columns={columns} data={dataSource} />
									</Tabs.TabPane>
								</Tabs>
		                    </Card>
		                </Col>
		            </Row>
		            <Row style={{ textAlign: 'left' }}>
		                <Col xs={24} md={24} lg={24} xl={24} span={24} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
		                    <Card className='cardTableDashboard' bordered={false} style={{ width: '100%', marginTop: 5, paddingLeft: '0px', paddingRight: '0px' }}>
								<Title level={4}>Ventas por campañas (MESES)</Title>
							  	<TableData columns={columnsCampMonth} data={dataReportCampMonth} />
		                    </Card>
		                </Col>
		            </Row>
	            </>
	            :
	            <>
		            {
		            	loadingData ?
		            		<Spin style={{ marginTop: '15%', paddingBottom: '30%' }} size="large" />
		            	:
		            		!clientsMkt ?
		            			<Title level={4} style={{ marginTop: '15%', paddingBottom: '30%' }}>{t('marketing.msjSync')}<a href="/my-account?step=2"> aquí</a></Title>
		            		:
		            			<Title level={4} style={{ marginTop: '15%', paddingBottom: '30%' }}>{t('marketing.msjWaitingDataInitial')}</Title>
		            }
		        </>
        	}
        </>
	)
}

export default IndexGraphics