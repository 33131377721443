import React from 'react';
import { Modal } from 'antd';
import TableModal from './TableModal'

const ModalTable = ({ titleModal, isModalVisible, handleOk, handleCancel, data, fba, columns, columnsMain, width }) => {
	let title = ''
	if(titleModal === ''){
		if(fba){
			title = `Productos sin Stock ${fba ? 'FBA' : 'FBM'} Disponibles`
		} else {
			title = `Productos sin Stock ${fba ? 'FBA' : 'FBM'} Disponibles`
		}
	} else {
		title = titleModal ? titleModal : ''
	}

	return (
		<>
			<Modal style={{ position: 'relative', top: '5%' }} bodyStyle={{ height: '620px', position: 'relative', top: -10}} title={title} width={width} footer={null} visible={isModalVisible} onCancel={handleCancel}>
				<TableModal columns={columns.length > 0 ? columns : []} columnsMain={columnsMain} data={data} fba={fba} />
			</Modal>
		</>
	);
};

export default ModalTable;