import createReducer from "../../store/createReducer";
import * as types from "./type";

const initialData = {
  clientsMkt: [],
  rowsStock: [],
  loading: false,
  filter: {},
  rawFilter: {},
  loadingProgress: {}
};

const marketing = createReducer(initialData, {
  [types.GET_LIST_CLIENTS_MRKT](state, action) {
    let clients = action.payload.clientsMkt
    return {
      ...state,
      clientsMkt: clients
    };
  },



});

export default marketing;