import createReducer from "../../store/createReducer";
import * as types from "./types";

const initialData = {
	rows: [],
	rowsStock: [],
	loading: false,
	filter: {},
	rawFilter: {},
	loadingProgress: {},
	marketplaceAvailable: []
};

const dashboard = createReducer(initialData, {

	[types.DASHBOARD_FETCH_STARTED](state, action) {
		return {
			...state,
			rows: [],
			rowsStock: [],
			loading: true,
			filter: action.payload ? action.payload.filter : {},
			rawFilter: action.payload ? action.payload.rawFilter : {},
			loadingProgress: action.payload.loadingProgress
		};
	},

	[types.DASHBOARD_FETCH_STARTED_STOCK](state, action) {
		return {
			...state,
			rowsStock: [],
			loading: true,
			rawFilter: {},
			loadingProgress: action.payload.loadingProgress
		};
	},

	[types.DASHBOARD_FETCH_SUCCESS](state, action) {
		 let filteredRows = action.payload.rows.map((partner)=> {
			let newPartner = partner;
			newPartner.children = partner.children.map((market)=> {
				let childrenWithoutParents = [];
				let parents = [];
				market.children.map((listing) => {
					if(listing.children.length == 1 && !listing.children[0].isVariant){
						childrenWithoutParents.push(listing.children[0]);
					} else {
						parents.push(listing);
					}
				});
				market.children = [...childrenWithoutParents, ...parents];       
				return market;
			})
			return newPartner;
		})

		if(!action.payload.isAdmin){
			return {
				...state,
				loading: true,
				rows: action.payload.rows ? action.payload.rows : [],
				loadingProgress: action.payload.loadingProgress,
				marketplaceAvailable: action.payload.marketplaceAvailable
			};
		} else {
			return {
				...state,
				loading: true,
				rows: [...state.rows, ...action.payload.rows ],
				loadingProgress: action.payload.loadingProgress,
				marketplaceAvailable: action.payload.marketplaceAvailable
			};      
		}
	},

	[types.DASHBOARD_FETCH_SUCCESS_STOCK](state, action) {
		 let filteredRows = action.payload.rowsStock.map((partner)=> {
			let newPartner = partner;
			newPartner.children = partner.children.map((market)=> {
				let childrenWithoutParents = [];
				let parents = [];
				market.children.map((listing) => {
					if(listing.children.length == 1 && !listing.children[0].isVariant){
						childrenWithoutParents.push(listing.children[0]);
					} else {
						parents.push(listing);
					}
				});
				market.children = [...childrenWithoutParents, ...parents];       
				return market;
			})
			return newPartner;
		})
		return {
			...state,
			loading: true,
			rowsStock: [...state.rowsStock, ...action.payload.rowsStock ],
			loadingProgress: action.payload.loadingProgress
		};
	},

	[types.DASHBOARD_FETCH_FINISH](state, action) {
		return {
			...state,
			loading: false,
		};
	}
	,
	[types.DASHBOARD_FETCH_ERROR](state, action) {
		return {
			...state,
			loading: false,
			rows: [],
		};
	},
	[types.DASHBOARD_RESETED](state, action) {
		return {
			...initialData,
		};
	}

});

export default dashboard;