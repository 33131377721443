import * as types from "./types";
import dashboardApi from '../../../api/dashboard';
import { mixPanelCreateEvent } from '../../../utils/functions';
import chunk from 'lodash/chunk';
import ip from 'ip'

const dashboardActions = {

    doReset: () => async (dispatch) => {
        dispatch({
          type: types.DASHBOARD_RESETED,
        });    
    },

    doFetch: (rawFilter) => async (
        dispatch,
        getState,
    ) => {
        try {
            const batchSize = 10;
            const pendingBatches = chunk(rawFilter.partner, batchSize);

            dispatch({
                type: types.DASHBOARD_FETCH_STARTED,
                payload: { 
                    rawFilter,
                    rowsStock:[],
                    rows:[],
                    loadingProgress: {
                        totalToProcess: pendingBatches.length,
                        progress : 0
                    }
                },
            });

            for(let [index, batch] of pendingBatches.entries()){
                const response = await dashboardApi.search({
                    ...rawFilter,
                    partner: batch
                });

                const marketplaceAvailable = []
                if(response.data.length > 0){
                    for (const marketplace of response.data[0].children) {
                        if (marketplace?.children?.length > 0) { 
                            marketplaceAvailable.push(marketplace.name)
                        }
                    }                   
                    mixPanelCreateEvent('API Rest', {
                        status: response.status,
                        message: 'Dashboard Data Returned',
                        method: 'PUT',
                        url: response.config.url,                        
                        ipClient: ip.address()
                    })
                }


                dispatch({
                    type: types.DASHBOARD_FETCH_SUCCESS,
                    payload: {
                        rows: response.data,
                        isAdmin: rawFilter.isAdmin,
                        rawFilter,
                        marketplaceAvailable: marketplaceAvailable,
                        loadingProgress: {
                            totalToProcess: pendingBatches.length,
                            progress : index + 1
                        }
                        },
                    });
            }

            dispatch({
                type: types.DASHBOARD_FETCH_FINISH
            });
        } catch (error) {
            mixPanelCreateEvent('API Rest', {
                status: error,
                message: 'Dashboard data not responding'
            })              
            dispatch({
                type: types.DASHBOARD_FETCH_ERROR,
            });
        }
    },

    doFetchStock: (rawFilter) => async (
        dispatch,
        getState,
    ) => {
        try {
            const batchSize = 10;
            const pendingBatches = chunk(rawFilter.partner, batchSize);

            dispatch({
                type: types.DASHBOARD_FETCH_STARTED_STOCK,
                payload: { 
                    rawFilter,
                    loadingProgress: {
                        totalToProcess: pendingBatches.length,
                        progress: 0
                    }
                },
            });
           
            for(let [index, batch] of pendingBatches.entries()){
                
                const response = await dashboardApi.search({
                    ...rawFilter,
                    partner: batch
                });

                if(response.data.length > 0){
                    mixPanelCreateEvent('API Rest', {
                        status: response.status,
                        message: 'Returned sales filtering data',
                        method: 'PUT',
                        url: response.config.url,                        
                        ipClient: ip.address()                        
                    })
                }

                dispatch({
                    type: types.DASHBOARD_FETCH_SUCCESS_STOCK,
                    payload: {
                        rowsStock: response.data,
                        loadingProgress: {
                            totalToProcess: pendingBatches.length,
                            progress : index + 1
                        }
                        },
                    });
            }

            dispatch({
                type: types.DASHBOARD_FETCH_FINISH
            });
        } catch (error) {
            mixPanelCreateEvent('API Rest', {
                status: error,
                message: 'Unreturned sales filtering data',
                method: 'PUT',
                ipClient: ip.address()                 
            })                
            dispatch({
                type: types.DASHBOARD_FETCH_ERROR,
            });
        }
    },
};

export default dashboardActions;