import React from 'react';
import { Table } from 'antd';

const TableData = ({data, columns, columnsMain}) => {
	return <Table
			columns={columns}
			rowKey={data.id}
			dataSource={data}
		    pagination={{
		      pageSize: 10,
		    }}
		    scroll={{
		    }}/>;
}
export default TableData;