import { useCallback, useState } from "react";
import { Radio, Space, Divider, Typography, Col, Row, Form, Tooltip, Button, Image } from 'antd';
import { openNotification } from '../../../../components/Toastr';
import { getErrorMessage } from '../../../../api/api';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import FedexLogo from '../../../../assets/fedex-logo.png';
import partnerApi from '../../../../api/partner';


const ListingFulfillmentForm = (props) => {
    const { Text, Title } = Typography;
    const { Item } = Form;
    const { t } = useTranslation();
    const { fulfillmentInfo, listingId, partnerId, marketplace } = props;
    const [showFBMCouriers, setShowFBMCouriers] = useState(fulfillmentInfo.fbmCourier !== 'n/a');
    const [editItem, setEditItem] = useState(false);
    const [saving, setSaving] = useState(false);
    const [fulfillmentType, setFulfillmentType] = useState(fulfillmentInfo.fulfillmentType);
    const [fbmCourier, setFbmCourier] = useState(fulfillmentInfo.fbmCourier);
    const [fulfillmentForm] = Form.useForm()
    const [checkFBM, setCheckFBM] = useState(fulfillmentInfo.fulfillmentType === 'FBM')


    const handleOnChange = useCallback((e) => {
        if (e.target.value === 'FBM') setShowFBMCouriers(true)
        else setShowFBMCouriers(false);
    }, [showFBMCouriers]);

    const handleEdit = useCallback(() => {
        setEditItem(true);
    }, []);

    const handleSave = async (values) => {
        setSaving(true);
        setFulfillmentType(values.fulfillmentType);
        // Verifies FBM option is selected
        if (values.fulfillmentType === 'FBM') setFbmCourier(values.fbmCourier)
        else {
            values.fbmCourier = 'n/a';
        }
        let data = {defaultCode: fulfillmentInfo.defaultCode}
        let updateAttributes = [];
        for (const [key, value] of Object.entries(values)) {
            // Sets request body with params to update
            updateAttributes.push({
                attributeName: key,
                attributeValue: value,
            });
        };
        data.updateAttributes = updateAttributes;
        try {
            await partnerApi.updateProductsByListingAndMarketplace(partnerId, listingId, marketplace, data)
        } catch (error) {
            openNotification({ status: false, content: getErrorMessage(error) });
            setSaving(false);
        }
        setEditItem(false);
        setSaving(false)
    };

    const handleFulfillmentChecked = (e) => {
        (e.target.value === 'FBM')? setCheckFBM(true) : setCheckFBM(false)
    }

    const onCheck = async () => {
        try {
          const values = await fulfillmentForm.validateFields();
          console.log('Success:', values);
          fulfillmentForm.submit();
        } catch (errorInfo) {
          console.log('Failed:', errorInfo);
        }
      };
    
    const validateCourier = async (_, value) => {
        if (value === 'n/a') {
            return Promise.reject();
        }
    }


    return (
        <>
        <Form form={fulfillmentForm} initialValues={{fulfillmentType, fbmCourier}} onFinish={handleSave}>
            <Row>
                <Row>
                <Col span={8}>
                    <Space direction="vertical" size="small">
                        <Title level={5} className="title-primary">{t('onboarding.fulfillment.type')}</Title>
                        <Text type="secondary">{t('onboarding.fulfillment.typeDescription')}</Text>
                    </Space>
                </Col>
                <Col>
                    <Tooltip title={"Editar"} onClick={handleEdit}>
                        <EditOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                    </Tooltip>
                </Col>
                </Row>
                <Row>
                <Col span={16}>
                    <Item className="input-form-margin-bottom" name={"fulfillmentType"}
                        rules={[{ required: true, message: 'Fulfillment es requerido' }]}
                        tooltip={{
                            title: t('onboarding.order.step1Input3Description'),
                            icon: <InfoCircleOutlined />,
                        }}>
                        <Radio.Group className="card-radio-group" 
                                    disabled={!editItem} 
                                    onChange={handleOnChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Debes elegir un tipo de envío'
                                        },
                                    ]}>
                            <Space direction="horizontal" size="large" style={{ width: '100%' }}>
                                <>
                                    <Radio style={{ marginTop: 12 }} value={'FBA'} onChange={handleFulfillmentChecked}>
                                        <Space direction="vertical">
                                            <p className="text-card-radio">{t('onboarding.fulfillment.FBA')}</p>
                                            <Text type="secondary">{t('onboarding.fulfillment.FBADescription')}</Text>
                                        </Space>
                                    </Radio>
                                    <Divider style={{ margin: 0 }} />
                                </>
                                <>
                                    <Radio value={'FBM'} onChange={handleFulfillmentChecked}>
                                        <Space direction="vertical">
                                            <p className="text-card-radio">{t('onboarding.fulfillment.FBM')}</p>
                                            <Text type="secondary">{t('onboarding.fulfillment.FBMDescription')}</Text>
                                        </Space>
                                    </Radio>
                                </>
                            </Space>
                        </Radio.Group>
                    </Item>
                </Col>
                </Row>
            </Row>
            <Row>
                {showFBMCouriers && 
                        <Item 
                        className="input-form-margin-bottom" 
                        name={"fbmCourier"}
                        rules = {[
                            {
                                required: checkFBM,
                                message: 'Debes elegir un método de envío'
                            },
                            {
                                validator: validateCourier,
                                message: 'Debes elegir un método de envío'

                            }]}>
                        <Radio.Group 
                        className="card-radio-group-fbm" 
                        disabled={showFBMCouriers && !editItem}
                        >
                            <Radio value={"Fedex"} disabled={showFBMCouriers && !editItem}>
                                <Image style={{ cursor: "pointer" }} height={100}
                                src={FedexLogo} preview={false} />
                            </Radio>
                            <Radio value={"Partner"}>
                                <Text disabled={showFBMCouriers && !editItem}>Yo me encargo del envío</Text>
                            </Radio>
                        </Radio.Group>
                        </Item>}
            </Row>

            <Row>  
                {editItem &&
                    <Row>
                    <Button onClick={onCheck} loading={saving}>Guardar</Button>
                    </Row>}
            </Row>
        </Form>
    </>
    )
}

export default ListingFulfillmentForm