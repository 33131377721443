import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import actionsAnalitycs from '../../../../redux/analytic/dashboard/actions';
import { Row, Col, Card, Typography } from 'antd';
import TopSalesPieChartBySku from './TopSalesPieChartBySku';
import DashboardTable from '../DashboardTable';
import TableStock from '../TableStock';
import StockData from './ProductStockData';
import 'antd/dist/antd.css';
import { thousandsSeparator } from '../../../../utils/functions';
import { dateTypes, fulfillmentType, averageSearch } from '../../../../utils/const';
const { Title } = Typography;

const Index = (props) => {
    const { orderRestock } = props;
    const dispatch = useDispatch();
    const session = useSelector(store => store.Session.session);
    const rows = useSelector(selectors.selectRows);
    const rawfilter = useSelector(selectors.selectRawFilter)
    const rowsStock = useSelector(selectors.selectRowsStock);
    const [totalVentas, setTotalVentas] = useState(0)
    const [dataStockProductFBA, setDataStockProductFBA] = useState([])
    const [dataStockProductFBM, setDataStockProductFBM] = useState([])
    const [stockAndRestock, setStockAndRestock] = useState([]);
    const [dataTopSalesSkuAndStock, setDataTopSalesSkuAndStock] = useState([]);
    const objectQuiebreStock = []
    let allProductsQuiebreStock = []
    const quiebreStockAndRestock = []
    const dataClients = []
    const valuesMainA = []
    const valuesMainB = []
    const salesPerMarketplace = []
    const topFiveSalesTable = []
    const topSalesSkuAndStock = []
    const topSalesMarketplaceAndStock = []
    const todaslasUnidadesVendidas = []
    const cantProductsStock = []
    const cantProductsStockAvailable = []
    let stockDataFBA = []
    let stockDataFBM = []
    const reducer = (accumulator, curr) => accumulator + curr;

    const columnsModalStockFbaAndFbm = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'defaultCode',
            key: 'defaultCode',
        },
        {
            title: 'Dias sin Stock',
            dataIndex: 'daysOutStock',
            key: 'daysOutStock'
        }
    ];

    const columnsMainModalStockFbaAndFbm = [
        {
            title: 'Client',
            dataIndex: 'title',
            key: 'title',
        }
    ];

    const searchByPeriodAmount = (m, p) => {
        let ptrs = []
        ptrs = rawfilter.partner
        if (rows?.length > 0 && ptrs && rawfilter.marketplace) {
            const defaultFilters = {
                sku: [],
                partner: ptrs,
                compare: false,
                dateRangeTwo: [],
                category: ['sales', 'stock'],
                marketplace: rawfilter.marketplace,
                dateType: rawfilter.dateType || dateTypes.PURCHASE_DATE,
                dateRangeOne: [moment().add(-m, p), moment()],
            };
            dispatch(actionsAnalitycs.doFetchStock(defaultFilters));
        }
    }

    useEffect(() => {
        let i = 0
        const salesClientsAdmin = [];
        // Admin
        if(rows?.length > 0) {
            if (!session?.userInfo?.isAdmin) {
                if (rows?.length > 0 && Array.isArray(rows[0].children)) {
                    dataClients.push(rows[0])
                    salesClientsAdmin.push(rows[0].sumTotalSoldUSD)
                    if (rows[0]?.children?.length > 0) {
                        for (const marketplace of rows[0].children) {
                            if (marketplace?.children?.length > 0) {
                                soldUnits(marketplace)
                                topSalesMarketplaceAndStock.push({
                                    value: marketplace?.sumTotalSoldUSD,
                                    valueMkp: marketplace?.sumTotalSold,
                                    currency: marketplace?.currency,
                                    type: marketplace?.name,
                                });
                                for (const purchaseDate of Object.keys(marketplace?.salesByPurchaseDateUSD)) {
                                    let yearAndMonth = purchaseDate.slice(0,7)
                                    let amounts = [];
                                    Object.keys(marketplace?.salesByPurchaseDateUSD).forEach((e) => {
                                        let date = e.slice(0,7)
                                        if (yearAndMonth === date){
                                            amounts.push(marketplace.salesByPurchaseDateUSD[e])
                                        }
                                    })
                                    salesPerMarketplace.push({
                                        purchaseDate: yearAndMonth,
                                        daysInMonths: purchaseDate,
                                        nameClient: dataClients[0].name,
                                        marketplace: marketplace.name,
                                        currency: marketplace.currency,
                                        totalSaleUSD: Math.round(amounts.reduce(reducer) * 100) / 100,
                                        totalSaleMkp: marketplace.salesByPurchaseDate[purchaseDate],
                                        id: 1,
                                        status: false
                                    });
                                }
                                for (const listing of marketplace?.children) {
                                    if (listing?.children?.length > 0) {
                                        for (const product of listing?.children) {
                                           if (product?.inventory && product.fulfillmentType !== fulfillmentType.FBM) {
                                                if (product?.inventory?.availableStock === 0) {
                                                    stockDataFBA.push({
                                                        name: product.title,
                                                        value: product.inventory.availableStock,
                                                        valueMkp: product.inventory.availableStock,
                                                        defaultCode: product.defaultCode,
                                                        daysOutStock: product.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                    });                                                  
                                                }
                                            } else {
                                                if (product?.inventory?.availableStock === 0) {
                                                    stockDataFBM.push({
                                                        name: product.title,
                                                        value: product.inventory.availableStock,
                                                        valueMkp: product.inventory.availableStock,
                                                        defaultCode: product.defaultCode,
                                                        daysOutStock: product.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                    });
                                                }
                                            }                                        
                                            topFiveSalesTable.push({
                                                title: product.title,
                                                defaultCode: product.defaultCode,
                                                marketplace: product.marketplace,
                                                sumQuantitySold: product.sumTotalSoldUSD
                                            })
                                            topSalesSkuAndStock.push({
                                                value: product.sumTotalSoldUSD,
                                                valueMkp: product.sumTotalSold,
                                                currency: product.marketplaceCurrency,
                                                type: `${product.title} - ${product.marketplace}`,
                                            });
                                            cantProductsStock.push(product.defaultCode)
                                            if(product.inventory?.availableStock > 0){
                                                cantProductsStockAvailable.push(product.inventory?.availableStock)
                                            }
                                        }
                                    } else {
                                        if (listing?.inventory && listing.fulfillmentType !== fulfillmentType.FBM) {
                                            if (listing?.inventory?.availableStock === 0) {
                                                stockDataFBA.push({
                                                    name: listing.title,
                                                    value: listing.inventory.availableStock,
                                                    valueMkp: listing.inventory.availableStock,
                                                    defaultCode: listing.defaultCode,
                                                    daysOutStock: listing.inventory.daysOutStock,
                                                    currency: '',
                                                    type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                });
                                            }
                                        } else {
                                            if (listing?.inventory?.availableStock === 0) {
                                                stockDataFBM.push({
                                                    name: listing.title,
                                                    value: listing.inventory.availableStock,
                                                    valueMkp: listing.inventory.availableStock,
                                                    defaultCode: listing.defaultCode,
                                                    daysOutStock: listing.inventory.daysOutStock,
                                                    currency: '',
                                                    type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                });
                                            }
                                        }                                     
                                        topFiveSalesTable.push({
                                            title: listing.title,
                                            defaultCode: listing.defaultCode,
                                            marketplace: listing.marketplace,
                                            sumQuantitySold: listing.sumTotalSoldUSD
                                        });
                                        topSalesSkuAndStock.push({
                                            value: listing.sumTotalSoldUSD,
                                            valueMkp: listing.sumTotalSold,
                                            currency: listing.marketplaceCurrency,
                                            type: `${listing.title} - ${listing.marketplace}`,
                                        });
                                        cantProductsStock.push(listing.defaultCode)
                                        if(listing.inventory?.availableStock > 0){
                                            cantProductsStockAvailable.push(listing.inventory?.availableStock)
                                        }
                                    }
                                }
                                setDataTopSalesSkuAndStock(topSalesSkuAndStock?.sort((a, b) => { return b.value - a.value })?.slice(0, 5));
                            }
                        }
                    }
                    if (stockDataFBA.length > 0) {
                        valuesMainA.push({
                            key: i,
                            title: rows[0].name,
                            children: stockDataFBA
                        });
                    }
                    if (stockDataFBM.length > 0) {
                        valuesMainB.push({
                            key: i,
                            title: rows[0].name,
                            children: stockDataFBM
                        });
                    }
                    i++                
                }
                setDataStockProductFBA(valuesMainA)
                setDataStockProductFBM(valuesMainB)            
            } else { // Not admin
                let i = 0
                if (rows?.length > 0) {
                    for (const clientFather of rows) {
                        salesClientsAdmin.push(clientFather.sumTotalSoldUSD)
                        if (rows[i]?.children?.length > 0) {
                            dataClients.push(clientFather)
                            salesPerMarketplace.push({
                                purchaseDate: clientFather.name,
                                marketplace: clientFather.name,
                                nameClient: clientFather.name,
                                currency: 'USD',
                                id: 0,
                                totalSaleUSD: clientFather.sumTotalSoldUSD,
                                totalSaleMkp: '',
                            });
                            topFiveSalesTable.push({
                                title: clientFather.name,
                                defaultCode: clientFather.name,
                                marketplace: '',
                                sumQuantitySold: clientFather.sumTotalSoldUSD
                            });
                            topSalesSkuAndStock.push({
                                value: clientFather.sumTotalSoldUSD,
                                valueMkp: clientFather.sumTotalSold,
                                currency: 'USD',
                                type: `${clientFather.name}`,
                            });                         
                            for (const marketplace of rows[i].children) {
                                if (marketplace?.children?.length > 0) {
                                    soldUnits(marketplace)
                                    for (const listing of marketplace?.children) {
                                        if (listing?.children?.length > 0) {
                                            for (const product of listing?.children) {
                                                if (product?.inventory && product.fulfillmentType !== fulfillmentType.FBM) {
                                                    if (product?.inventory?.availableStock === 0) {
                                                        stockDataFBA.push({
                                                            name: product.title,
                                                            value: product.inventory.availableStock,
                                                            valueMkp: product.inventory.availableStock,
                                                            defaultCode: product.defaultCode,
                                                            daysOutStock: product.inventory.daysOutStock,
                                                            currency: '',
                                                            type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                        });                                                  
                                                    }
                                                } else {
                                                    if (product?.inventory?.availableStock === 0) {
                                                        stockDataFBM.push({
                                                            name: product.title,
                                                            value: product.inventory.availableStock,
                                                            valueMkp: product.inventory.availableStock,
                                                            defaultCode: product.defaultCode,
                                                            daysOutStock: product.inventory.daysOutStock,
                                                            currency: '',
                                                            type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                        });
                                                    }
                                                }
                                            }
                                        } else {
                                            if (listing?.inventory && listing.fulfillmentType !== fulfillmentType.FBM) {
                                                if (listing?.inventory?.availableStock === 0) {
                                                    stockDataFBA.push({
                                                        name: listing.title,
                                                        value: listing.inventory.availableStock,
                                                        valueMkp: listing.inventory.availableStock,
                                                        defaultCode: listing.defaultCode,
                                                        daysOutStock: listing.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                    });
                                                }
                                            } else {
                                                if (listing?.inventory?.availableStock === 0) {
                                                    stockDataFBM.push({
                                                        name: listing.title,
                                                        value: listing.inventory.availableStock,
                                                        valueMkp: listing.inventory.availableStock,
                                                        defaultCode: listing.defaultCode,
                                                        daysOutStock: listing.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                    });
                                                }
                                            }                                       
                                        }
                                    }
                                    setDataTopSalesSkuAndStock(topSalesSkuAndStock?.sort((a, b) => { return b.value - a.value })?.slice(0, 5));
                                }
                            }
                        }
                        if (stockDataFBA.length > 0) {
                            valuesMainA.push({
                                key: i,
                                title: clientFather.name,
                                children: stockDataFBA
                            });
                        }
                        if (stockDataFBM.length > 0) {
                            valuesMainB.push({
                                key: i,
                                title: clientFather.name,
                                children: stockDataFBM
                            });
                        }
                        i++
                    }
                }
            }
            if(salesClientsAdmin.length > 1) {
                setTotalVentas(Math.round(salesClientsAdmin?.reduce(reducer) * 100) / 100)
            } else {
                setTotalVentas(salesClientsAdmin[0])
            }
            setDataStockProductFBA(valuesMainA)
            setDataStockProductFBM(valuesMainB)
            if(rowsStock.length < 1){
                searchByPeriodAmount(3,'M')
            }
            quiebreStock()

        } else {
            return 
        }
    }, [rows])

    const quiebreStock = async () => {
        let allProductsSku = []
        allProductsQuiebreStock = []
        if (rowsStock?.length > 0 && Array.isArray(rowsStock[0].children)) {
            for (const clientFatherStock of rowsStock) {
                dataClients.push(clientFatherStock)
                if (clientFatherStock?.children?.length > 0) {
                    for (const marketplace of clientFatherStock.children) {
                        if (marketplace?.children?.length > 0) {

                            for (const listing of marketplace?.children) {
                                if (listing?.children?.length > 0) {
                                    for (const product of listing?.children) {
                                        for (const purchaseDate of Object.keys(product?.quantityByPurchaseDate)) {
                                            let yearAndMonth = purchaseDate.slice(0,7)
                                            let quantity = []
                                            Object.keys(product?.quantityByPurchaseDate).forEach((e) => {
                                                let date = e.slice(0,7)
                                                if(yearAndMonth === date){
                                                    quantity.push(product.quantityByPurchaseDate[e])
                                                }
                                            })
                                            let existeElement = objectQuiebreStock.filter((e) => e.date === yearAndMonth && e.product === product.defaultCode && e.marketplace === product.marketplace)
                                            if(existeElement.length === 0){
                                                objectQuiebreStock.push({
                                                    date: yearAndMonth,
                                                    product: product.defaultCode,
                                                    marketplace: product.marketplace,
                                                    stockTotal: Math.round(quantity.reduce(reducer) * 100) / 100,
                                                })                                               
                                                allProductsSku.push({
                                                    product: product.defaultCode,
                                                    title: product.title,
                                                    mainImages: product.mainImages,
                                                    asin: product.asin,
                                                    marketplace: product.marketplace,
                                                    availableStock: product.inventory?.availableStock
                                                })
                                            }
                                        }
                                    }                       
                                } else {
                                    for (const purchaseDate of Object.keys(listing?.quantityByPurchaseDate)) {
                                        let yearAndMonth = purchaseDate.slice(0,7)
                                        let quantity = []
                                        Object.keys(listing?.quantityByPurchaseDate).forEach((e) => {
                                            let date = e.slice(0,7)
                                            if(yearAndMonth === date){
                                                quantity.push(listing.quantityByPurchaseDate[e])
                                            }
                                        })
                                        let existeElement = objectQuiebreStock.filter((e) => e.date === yearAndMonth && e.product === listing.defaultCode && e.marketplace === listing.marketplace)
                                        if(existeElement.length === 0){
                                            objectQuiebreStock.push({
                                                date: yearAndMonth,
                                                product: listing.defaultCode,
                                                marketplace: listing.marketplace,
                                                stockTotal: Math.round(quantity.reduce(reducer) * 100) / 100,
                                            })                                        
                                            allProductsSku.push({
                                                product: listing.defaultCode,
                                                mainImages: listing.mainImages,
                                                title: listing.title,
                                                asin: listing.asin,
                                                marketplace: listing.marketplace,
                                                availableStock: listing.inventory?.availableStock
                                            }) 
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        allProductsSku.forEach((sku) => {
            let stockByProduct = []
            objectQuiebreStock.forEach((p) => {
                if(p.product === sku.product) {
                    stockByProduct.push(p.stockTotal)
                }
            })
            let exisProduct = allProductsQuiebreStock.filter((e) => e.product === sku.product && e.marketplace === sku.marketplace)
            if(exisProduct.length < 1) {
                let suma = Math.round(stockByProduct.reduce(reducer) * 100) / 100
                let divideSuma = suma / averageSearch.AVERAGE_CONSTANT_SELLER
                allProductsQuiebreStock.push({
                    product: sku.product,
                    title: sku.title,
                    asin: sku.asin,
                    mainImages: sku.mainImages,
                    marketplace: sku.marketplace,
                    availableStock: sku.availableStock,
                    averageStock: divideSuma.toFixed(2),
                    status: sku.availableStock < Math.round(suma / 3) ? 'enviar stock' : 'ok'
                })
            }
        })
        if(orderRestock){
            allProductsQuiebreStock.forEach((product) => {
                let exitRestock;
                orderRestock?.forEach((order) => {
                    let exitProduct = order?.products?.find((e) => product.product === e.product.defaultCode && product.marketplace === e.product.marketplace)
                    if(exitProduct) {
                        exitRestock = {
                            quantity: exitProduct.quantity,
                            shippingType: order.shippingType,
                            state: order.state
                        }
                    }
                })
                if (exitRestock) {
                    quiebreStockAndRestock.push({
                        ...product,
                        status: exitRestock?.shippingType ? 'en transito' : product.status,
                        restock: exitRestock
                    })
                } else {
                    quiebreStockAndRestock.push({
                        ...product,
                        restock: {}
                    })
                }
            })
        }
        setStockAndRestock(quiebreStockAndRestock) // Agrega nueva fila
    }

    const soldUnits = (mkp) => {
        let sumaCant = Math.round(Object.values(mkp?.quantityByPurchaseDate).reduce(reducer) * 100) / 100
        todaslasUnidadesVendidas.push(sumaCant)
    }

    return (
    <>
        {
            rows.length > 0 ?
                <>
                    <Row className='intro-totales-detalle'>
                        <Col xs={24} md={24} lg={6} xl={6} span={6} style={{marginRight:'5px'}}>
                            <Card bordered={false} style={{ width: '100%', marginTop: 5, paddingBottom: !session.userInfo.isAdmin ? 0 : 40}}>
                                <Title level={4}>Ventas Totales (USD)</Title>
                                <Title style={{ color: '#04dfa0', marginTop: !session.userInfo.isAdmin ? null : 70 }} level={1}><center>${totalVentas > 0 ? thousandsSeparator(totalVentas) : 0}</center></Title>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={6} xl={6} span={6} style={{marginRight:'5px',minWidth:'24%'}}>
                            <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
                                <Title level={4}>Top 5</Title>
                                <TopSalesPieChartBySku dataSource={dataTopSalesSkuAndStock} />
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={6} xl={6} span={6} style={{marginRight:'5px'}}>
                            <StockData
                                columns={columnsModalStockFbaAndFbm}
                                columnsMain={columnsMainModalStockFbaAndFbm}
                                fba={true}
                                dataSource={dataStockProductFBA} />
                        </Col>
                        <Col xs={24} md={24} lg={6} xl={6} span={6}>
                            <StockData
                                columns={columnsModalStockFbaAndFbm}
                                columnsMain={columnsMainModalStockFbaAndFbm}
                                fba={false}
                                dataSource={dataStockProductFBM} />
                        </Col>
                    </Row>    
                    <Row>
                        <Col span={24}>
                            <div className="site-card-border-less-wrapper detalle-table" style={{ marginTop: 10 }}>
                                <Card bordered={false} style={{ width: '100%' }}>
                                    <Title level={4}>Ventas</Title>
                                    <DashboardTable></DashboardTable>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="site-card-border-less-wrapper" style={{ marginTop: 10 }}>
                                <Card bordered={false} style={{ width: '100%' }}>
                                    <Title level={4}>Restock inventario <small>(Ultimos 3 meses)</small></Title>
                                    <TableStock dataSource={stockAndRestock}></TableStock>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </>
                :
                <h1 style={{ padding: 80 }}>Sin consultas</h1>
        }
    </>
    )
}

export default Index