import { createSelector } from 'reselect';

const selectMkt = (state) => state.Analytic.marketing;

const selectClientsMkt = createSelector(
    [selectMkt],
    (raw) => raw.clientsMkt,
);

const dashboardSelectors = {
    selectClientsMkt
};

export default dashboardSelectors;