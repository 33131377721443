import api from "./api";

const prefix = "/amazonAds/marketplace/amazon/advertising";
const adminMarketing = 4248

export default {
  getClients: function (partnerId) {
    return api.get(`${prefix}/${partnerId}/profileId/list`);
  },
  generateReport: function (profileId, partnerId) {
    return api.post(`${prefix}/${partnerId}/${profileId}/report`);
  },
  getReport: function (reportId, profileId, filter, partnerId) {
    return api.get(`${prefix}/${partnerId}/${profileId}/report/${reportId}/summary`, filter);
  },
  getReportCampaign: function (reportId, profileId, partnerId) {
    return api.get(`${prefix}/${partnerId}/${profileId}/report/${reportId}/summary/campaign`);
  }
};
