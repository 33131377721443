import React, { useEffect, useState } from 'react';
import { Col, Divider, Row, Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import accessKeysApi from '../../../../api/aws-access-keys';
import { useHistory, useLocation } from 'react-router';
import * as queryString from 'query-string';
import { CheckCircleFilled } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../../redux/session/action';
import { openNotification } from '../../../../components/Toastr';

const AmazonAdsAccount = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const {
        session,
        tab,
        myAdsAccount,
        setMyAdsAccount } = props;

    const [consentUrl, setConsentUrl] = useState();
    const [loadingConsentUrl, setLoadingConsentUrl] = useState(false);
    const [processingConsent, setProcessingConsent] = useState(false);
    const urlQueryString = queryString.parse(location.search);
    const adsCode = urlQueryString.code;
    const fetchConsentUrl = async () => {
        setLoadingConsentUrl(true);
        const response = await accessKeysApi.getAdsConsentUrl(tab);
        setConsentUrl(response.data.consentUrl);
        setLoadingConsentUrl(false);
    };

    const handleConsentCode = async () => {
        const response = await accessKeysApi.saveAdsCredentials(session?.userInfo?.commercial_partner_id[0],tab, {authGrant: adsCode});
        if (!response?.data?.success && !response?.data?.id) {
            openNotification({ status: false, content: 'Error al sincronizar la cuenta de advertising. Intente nuevamente.' });
            history.push("/my-account?step=2");
            return;
        }
        loadMyAdsAccount();
    }

    const loadMyAdsAccount = async () => {
        const adsAccount = await accessKeysApi.findByIdAndMarketplace(session?.userInfo?.commercial_partner_id[0], 'amazon', 'Advertising');
        setMyAdsAccount(adsAccount.data);
    };
    useEffect(() => {
        if (!myAdsAccount) {
            setLoadingConsentUrl(true);
            fetchConsentUrl();
            setLoadingConsentUrl(false);
            }
            }, [myAdsAccount]);
    
    useEffect(() => {
        if (adsCode && !myAdsAccount) {
            setProcessingConsent(true);
            handleConsentCode();
            setProcessingConsent(false);
            dispatch(Actions.updateAdsAccountStatusSession(true));
            history.push("/my-account?step=2");
        }
    }, [adsCode])
        

    return (
        <>

            <Row gutter={[12, 2]} className="text-align-left">
                <Col span={5}>{t('myAccount.sellerAccount.input2')}
                </Col>
                <Col>
                    {processingConsent ? <Spin /> :
                        <>
                            {(!myAdsAccount || !myAdsAccount.credentials) && <Button href={consentUrl} loading={loadingConsentUrl} type="primary" ghost>Asociar con Amazon Ads</Button>}
                            {(myAdsAccount && myAdsAccount.credentials) && <span><CheckCircleFilled className="dot-green" /> {t('myAccount.sellerAccount.input2Description2.1')}</span>}
                        </>
                    }
                </Col>
            </Row>
            <Divider />
        </>
    )
}

export default AmazonAdsAccount;
