import React from 'react';
import { useSelector } from 'react-redux';
import { Table, Typography, Tooltip } from 'antd';
import { thousandsSeparator } from '../../../../utils/functions';

const { Text } = Typography;

const TopSalesTableBySku = ({ dataSource }) => {
    const session = useSelector(store => store.Session.session);

    const reducirText = (text) => {
        return text?.substring(0, 25) +"...";       
    }

    const columns = [
        {
            title: 'Top',
            dataIndex: 'top',
            key: 'top',
            width:'10%',
            render: (value, record, index) => <Tooltip title={`Marketplace: ${record?.marketplace} - Título: ${record?.title}`}>
                <Text>{index + 1}</Text>
            </Tooltip>
        },
        {
            title: !session?.userInfo?.isAdmin ? 'Producto' : 'Cliente',
            dataIndex: 'title',
            key: 'title',
            width:'70%',
            render: (value, record) => <Tooltip title={!session?.userInfo?.isAdmin ? `SKU: ${record?.defaultCode}` : ''}>
                <Text ellipsis={{ tooltip: record?.title }}>{reducirText(record?.title)}</Text>
            </Tooltip>
        },
        {
            title: 'USD',
            dataIndex: 'sumQuantitySold',
            key: 'sumQuantitySold',
            width:'20%',
            render: (value, record) => <Text ellipsis={{ tooltip: record?.sumQuantitySold }}>${thousandsSeparator(record?.sumQuantitySold)}</Text>
        },
    ];
    return <Table dataSource={dataSource.filter((element, index) => index <= 4)} pagination={false} columns={columns} />;
};
export default React.memo(TopSalesTableBySku);
