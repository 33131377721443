import react from 'react'
import IndexGraphics from './components/IndexGraphics'

const Marketing = () => {
	
	const dataMarketing = [
	    {
	        "date": "2022-08-01",
	        "spend": 10.37,
	        "clicks": 17,
	        "impressions": 5696,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.61,
	        "clicksThroughRate": 0.0029845505617977527,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-02",
	        "spend": 12.98,
	        "clicks": 23,
	        "impressions": 5547,
	        "sales": 10.46,
	        "orders": 1,
	        "ACOS": 1.2409177820267685,
	        "costPerClick": 0.5643478260869565,
	        "clicksThroughRate": 0.004146385433567695,
	        "conversion": 23,
	        "returnOnAdSpend": 0.8058551617873653
	    },
	    {
	        "date": "2022-08-03",
	        "spend": 10.73,
	        "clicks": 21,
	        "impressions": 5930,
	        "sales": 14.95,
	        "orders": 1,
	        "ACOS": 0.7177257525083612,
	        "costPerClick": 0.5109523809523809,
	        "clicksThroughRate": 0.0035413153456998313,
	        "conversion": 21,
	        "returnOnAdSpend": 1.3932898415657036
	    },
	    {
	        "date": "2022-08-04",
	        "spend": 10.309999999999999,
	        "clicks": 21,
	        "impressions": 4705,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.4909523809523809,
	        "clicksThroughRate": 0.004463336875664187,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-05",
	        "spend": 8.79,
	        "clicks": 17,
	        "impressions": 4144,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.5170588235294117,
	        "clicksThroughRate": 0.004102316602316602,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-06",
	        "spend": 11.48,
	        "clicks": 23,
	        "impressions": 3531,
	        "sales": 14.95,
	        "orders": 1,
	        "ACOS": 0.7678929765886289,
	        "costPerClick": 0.4991304347826087,
	        "clicksThroughRate": 0.006513735485698103,
	        "conversion": 23,
	        "returnOnAdSpend": 1.3022648083623691
	    },
	    {
	        "date": "2022-08-07",
	        "spend": 4.1899999999999995,
	        "clicks": 10,
	        "impressions": 4998,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.41899999999999993,
	        "clicksThroughRate": 0.0020008003201280513,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-08",
	        "spend": 7.57,
	        "clicks": 17,
	        "impressions": 5536,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.44529411764705884,
	        "clicksThroughRate": 0.0030708092485549135,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-09",
	        "spend": 10.120000000000001,
	        "clicks": 20,
	        "impressions": 4290,
	        "sales": 77.8,
	        "orders": 4,
	        "ACOS": 0.13007712082262213,
	        "costPerClick": 0.506,
	        "clicksThroughRate": 0.004662004662004662,
	        "conversion": 5,
	        "returnOnAdSpend": 7.687747035573121
	    },
	    {
	        "date": "2022-08-10",
	        "spend": 13.08,
	        "clicks": 22,
	        "impressions": 4053,
	        "sales": 14.95,
	        "orders": 1,
	        "ACOS": 0.8749163879598663,
	        "costPerClick": 0.5945454545454546,
	        "clicksThroughRate": 0.00542807796693807,
	        "conversion": 22,
	        "returnOnAdSpend": 1.142966360856269
	    },
	    {
	        "date": "2022-08-11",
	        "spend": 5.61,
	        "clicks": 12,
	        "impressions": 2996,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.4675,
	        "clicksThroughRate": 0.004005340453938585,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-12",
	        "spend": 8.280000000000001,
	        "clicks": 12,
	        "impressions": 2707,
	        "sales": 55.9,
	        "orders": 2,
	        "ACOS": 0.14812164579606443,
	        "costPerClick": 0.6900000000000001,
	        "clicksThroughRate": 0.004432951606944958,
	        "conversion": 6,
	        "returnOnAdSpend": 6.751207729468598
	    },
	    {
	        "date": "2022-08-13",
	        "spend": 10.51,
	        "clicks": 19,
	        "impressions": 3248,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.5531578947368421,
	        "clicksThroughRate": 0.005849753694581281,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-14",
	        "spend": 8.05,
	        "clicks": 16,
	        "impressions": 4487,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.503125,
	        "clicksThroughRate": 0.0035658569199910855,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-15",
	        "spend": 7.6899999999999995,
	        "clicks": 15,
	        "impressions": 4984,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.5126666666666666,
	        "clicksThroughRate": 0.003009630818619583,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-16",
	        "spend": 11.39,
	        "clicks": 22,
	        "impressions": 4676,
	        "sales": 104.65,
	        "orders": 3,
	        "ACOS": 0.10883898709985666,
	        "costPerClick": 0.5177272727272727,
	        "clicksThroughRate": 0.004704875962360992,
	        "conversion": 7.333333333333333,
	        "returnOnAdSpend": 9.187884108867427
	    },
	    {
	        "date": "2022-08-17",
	        "spend": 10.59,
	        "clicks": 20,
	        "impressions": 3674,
	        "sales": 55.9,
	        "orders": 2,
	        "ACOS": 0.1894454382826476,
	        "costPerClick": 0.5295,
	        "clicksThroughRate": 0.005443658138268917,
	        "conversion": 10,
	        "returnOnAdSpend": 5.278564683663833
	    },
	    {
	        "date": "2022-08-18",
	        "spend": 8.67,
	        "clicks": 19,
	        "impressions": 3937,
	        "sales": 27.95,
	        "orders": 1,
	        "ACOS": 0.31019677996422185,
	        "costPerClick": 0.45631578947368423,
	        "clicksThroughRate": 0.004826009652019304,
	        "conversion": 19,
	        "returnOnAdSpend": 3.2237600922722027
	    },
	    {
	        "date": "2022-08-19",
	        "spend": 12.41,
	        "clicks": 17,
	        "impressions": 3092,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.73,
	        "clicksThroughRate": 0.005498059508408797,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-20",
	        "spend": 6.779999999999999,
	        "clicks": 13,
	        "impressions": 4221,
	        "sales": 27.95,
	        "orders": 1,
	        "ACOS": 0.24257602862254024,
	        "costPerClick": 0.5215384615384615,
	        "clicksThroughRate": 0.003079838900734423,
	        "conversion": 13,
	        "returnOnAdSpend": 4.122418879056047
	    },
	    {
	        "date": "2022-08-21",
	        "spend": 12.15,
	        "clicks": 23,
	        "impressions": 3580,
	        "sales": 14.95,
	        "orders": 1,
	        "ACOS": 0.8127090301003345,
	        "costPerClick": 0.5282608695652175,
	        "clicksThroughRate": 0.006424581005586592,
	        "conversion": 23,
	        "returnOnAdSpend": 1.2304526748971192
	    },
	    {
	        "date": "2022-08-22",
	        "spend": 12.93,
	        "clicks": 24,
	        "impressions": 4513,
	        "sales": 82.8,
	        "orders": 4,
	        "ACOS": 0.15615942028985508,
	        "costPerClick": 0.53875,
	        "clicksThroughRate": 0.005317970307999114,
	        "conversion": 6,
	        "returnOnAdSpend": 6.403712296983759
	    },
	    {
	        "date": "2022-08-23",
	        "spend": 10.7,
	        "clicks": 15,
	        "impressions": 4541,
	        "sales": 14.95,
	        "orders": 1,
	        "ACOS": 0.7157190635451505,
	        "costPerClick": 0.7133333333333333,
	        "clicksThroughRate": 0.0033032371724289805,
	        "conversion": 15,
	        "returnOnAdSpend": 1.397196261682243
	    },
	    {
	        "date": "2022-08-24",
	        "spend": 9.54,
	        "clicks": 17,
	        "impressions": 4081,
	        "sales": 55.9,
	        "orders": 2,
	        "ACOS": 0.1706618962432916,
	        "costPerClick": 0.5611764705882353,
	        "clicksThroughRate": 0.004165645675079637,
	        "conversion": 8.5,
	        "returnOnAdSpend": 5.8595387840670865
	    },
	    {
	        "date": "2022-08-25",
	        "spend": 8.629999999999999,
	        "clicks": 15,
	        "impressions": 3461,
	        "sales": 27.95,
	        "orders": 1,
	        "ACOS": 0.30876565295169944,
	        "costPerClick": 0.5753333333333333,
	        "clicksThroughRate": 0.004334007512279688,
	        "conversion": 15,
	        "returnOnAdSpend": 3.238702201622248
	    },
	    {
	        "date": "2022-08-26",
	        "spend": 10.51,
	        "clicks": 19,
	        "impressions": 3858,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.5531578947368421,
	        "clicksThroughRate": 0.004924831518921721,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-27",
	        "spend": 14.7,
	        "clicks": 25,
	        "impressions": 3909,
	        "sales": 141.7,
	        "orders": 6,
	        "ACOS": 0.10374029640084687,
	        "costPerClick": 0.588,
	        "clicksThroughRate": 0.006395497569710923,
	        "conversion": 4.166666666666667,
	        "returnOnAdSpend": 9.639455782312925
	    },
	    {
	        "date": "2022-08-28",
	        "spend": 22.81,
	        "clicks": 34,
	        "impressions": 7076,
	        "sales": 83.85,
	        "orders": 3,
	        "ACOS": 0.2720333929636255,
	        "costPerClick": 0.6708823529411765,
	        "clicksThroughRate": 0.004804974561899378,
	        "conversion": 11.333333333333334,
	        "returnOnAdSpend": 3.6760192897851818
	    },
	    {
	        "date": "2022-08-29",
	        "spend": 8.62,
	        "clicks": 12,
	        "impressions": 3591,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0.7183333333333333,
	        "clicksThroughRate": 0.003341687552213868,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-30",
	        "spend": 0,
	        "clicks": 0,
	        "impressions": 0,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0,
	        "clicksThroughRate": 0,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    },
	    {
	        "date": "2022-08-31",
	        "spend": 0,
	        "clicks": 0,
	        "impressions": 0,
	        "sales": 0,
	        "orders": 0,
	        "ACOS": 0,
	        "costPerClick": 0,
	        "clicksThroughRate": 0,
	        "conversion": 0,
	        "returnOnAdSpend": 0
	    }
	]

	return (
		<div className="content-div" style={{ backgroundColor: '#f9f9f9' }}>
			<IndexGraphics dataMarketing={dataMarketing}/>
		</div>
	)
}

export default Marketing