import React, { useState } from 'react';
import ModalTable from './ModalTable'
import { measureTextWidth } from '@ant-design/plots';
import { Typography, Card, Button } from 'antd';
import { AlignCenterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

const { Title } = Typography;

const StockFBM = ({ fba, dataSource, columns, columnsMain }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const session = useSelector(store => store.Session.session);

	const gridStyle = {
		width: '100%',
		textAlign: 'center',
		height: '100%',
		marginTop: 5
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	function renderStatistic(containerWidth, text, style) {
		const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
		const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

		let scale = 1;

		if (containerWidth < textWidth) {
			scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
		}

		const textStyleStr = `width:${containerWidth}px;`;
		return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
	}

	return (
		<>
		<ModalTable columns={columns} width={900} columnsMain={columnsMain} fba={fba} data={dataSource} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
		<Card style={gridStyle}>
			{
				!session?.userInfo?.isAdmin ?
				<>
					<Title level={4}>{fba === true ? 'Productos sin Stock FBA' : 'Productos sin Stock FBM'}</Title>
				</>
				:
				<>
					<Title level={4}>{fba === true ? 'Clientes sin Stock FBA' : 'Clientes sin Stock FBM'}</Title>
				</>

			}
			<Title style={{ color: 'red' }} level={1}><center>{ session?.userInfo?.isAdmin ? dataSource.length : dataSource[0]?.children?.length > 0 ? dataSource[0]?.children?.length : 0}</center></Title>
			<Button type="" icon={<AlignCenterOutlined />} size={'small'} onClick={showModal} disabled={!dataSource[0] ? true : false}/>
		</Card>
		</>
	)
};
export default StockFBM;
