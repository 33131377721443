import React, { useEffect, useState } from 'react';
import Headers from '../layouts/Header';
import MenuOptions from '../layouts/MenuOptions';
import { useSelector } from "react-redux";
import { Layout, Menu, Breadcrumb, Button, Spin, Avatar, Row, Col, Typography } from 'antd';
import { UserOutlined, LoadingOutlined, LaptopOutlined, CloseOutlined, NotificationOutlined, LogoutOutlined, LeftOutlined, RightOutlined, AlignLeftOutlined } from '@ant-design/icons';
import { Redirect, Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isExpired } from '../helpers/auth-helpers';
import partnerApi from "../api/partner";
const antIcon = <LoadingOutlined style={{ fontSize: 30, marginTop: 2, marginRight: 10 }} spin />;
const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

const Index = ({ component: Component, roles, ...rest }) => {
	
	const { t } = useTranslation();
    const session = useSelector(store => store.Session.session);
    const [token, setToken] = useState(session ? session.token : null);
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);

    const [widthMenu, setWidthMenu] = useState({
    	width: 240,
		marginTop: '30px',
		orientation: 'vertical',
		mobile: false,
		marginLeft: '30%',
		positionShowMenu: true,
		widthLogo: 50,
		options: [
			{
				title: t('menu.dashboard'),
				path: "dashboard",
				url: "/"
			},
			{
				title: t('menu.detalles'),
				path: "detalles",
				url: "/detalles"
			},
			{
				title: t('menu.listings'),
				path: "listings",
				url: "/listings",
				subOptions: [
					{
						title: t('menu.tus_productos'),
						path: "tus_productos",
						url: "/tus_productos"								
					},
					{
						title: t('menu.tus_publicaciones'),
						path: "tus_publicaciones",
						url: "/tus_publicaciones"								
					}
				]
			},
			{
				title: t('menu.compras'),
				path: "compras",
				url: "/compras"
			},
			{
				title: t('menu.soporte'),
				path: "soporte",
				url: "/soporte"
			},
			{
				title: t('menu.estado'),
				path: "estado",
				url: "/estado"
			}
		]   	
    });
    const [widthMenuMobile, setWidthMenuMobile] = useState({
      	width: 0,
		marginTop: '30px',
		orientation: 'vertical',
		mobile: false,
		marginLeft: '18%',
		widthLogo: 25,
		positionShowMenu: false,
		options: false
	});
	const items1 = ['1', '2', '3'].map((key) => ({
	  key,
	  label: `nav ${key}`,
	}));

	useEffect(() => {
		changeMenu(false)
	}, [])

    useEffect(() => {
        if (session && session.userInfo) {
            if (!logo) {
                getLogo();
            }
        }
        return () => {
            setLogo(null);
            setLoading(false);
        }
    }, [session])

    const getLogo = () => {
        setLoading(true);
        partnerApi.findLogoById(session?.userInfo?.commercial_partner_id[0])
            .then(res => {
                setLogo(res.data)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }


	const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
	  const key = String(index + 1);
	  return {
	    key: `sub${key}`,
	    icon: React.createElement(icon),
	    label: `subnav ${key}`,
	    children: new Array(4).fill(null).map((_, j) => {
	      const subKey = index * 4 + j + 1;
	      return {
	        key: subKey,
	        label: `option${subKey}`,
	      };
	    }),
	  };
	});

    const isAuthorized = () => {
        if (token && session && roles) {
            if(session.userInfo.login.split('@')[0].toUpperCase() === 'TECH') {
                return roles.includes('Lap');
            }
            return roles.includes(session.userInfo.role);
        }
        return false;
    }

    const changeMenu = (value) => {
    	setWidthMenu(value ? {
		      	width: 90,
				marginTop: '30px',
				orientation: 'vertical',
				mobile: false,
				marginLeft: '18%',
				widthLogo: 25,
				positionShowMenu: false,
				options: false
    		} : {
		    	width: 250,
				marginTop: '30px',
				orientation: 'inline',
				mobile: false,
				marginLeft: '25%',
				widthLogo: 50,
				positionShowMenu: true,
				options: [
					{
						title: t('menu.dashboard'),
						path: "dashboard",
						url: "/"
					},
					{
						title: t('menu.detalles'),
						path: "detalles",
						url: "/detalles"
					},
					{
						title: t('menu.listings'),
						path: "listings",
						url: "/listings",
						subOptions: [
							{
								title: t('menu.tus_productos'),
								path: "tus_productos",
								url: "/tus_productos"								
							},
							{
								title: t('menu.tus_publicaciones'),
								path: "tus_publicaciones",
								url: "/tus_publicaciones"								
							}
						]
					},
					{
						title: t('menu.compras'),
						path: "compras",
						url: "/compras"
					},
					{
						title: t('menu.soporte'),
						path: "soporte",
						url: "/soporte"
					},
					{
						title: t('menu.estado'),
						path: "estado",
						url: "/estado"
					}
				]
    		})
    }

    const changeMenuMobile = (value) => {
    	setWidthMenuMobile(value ? {
		      	width: 0,
				marginTop: '30px',
				orientation: 'vertical',
				mobile: true,
				marginLeft: '18%',
				widthLogo: 25,
				positionShowMenu: false,
				options: false
    		} : {
		    	width: 250,
				marginTop: '30px',
				orientation: 'inline',
				mobile: true,
				marginLeft: '25%',
				widthLogo: 50,
				positionShowMenu: true,
				options: [
					{
						title: t('menu.dashboard'),
						path: "dashboard",
						url: "/"
					},
					{
						title: t('menu.detalles'),
						path: "detalles",
						url: "/detalles"
					},
					{
						title: t('menu.listings'),
						path: "listings",
						url: "/listings",
						subOptions: [
							{
								title: t('menu.tus_productos'),
								path: "tus_productos",
								url: "/tus_productos"								
							},
							{
								title: t('menu.tus_publicaciones'),
								path: "tus_publicaciones",
								url: "/tus_publicaciones"								
							}
						]
					},
					{
						title: t('menu.compras'),
						path: "compras",
						url: "/compras"
					},
					{
						title: t('menu.soporte'),
						path: "soporte",
						url: "/soporte"
					},
					{
						title: t('menu.estado'),
						path: "estado",
						url: "/estado"
					}
				]
    		})
    }    

	return (
	<>
	  <Layout>
		<Header className="menu-mobil">
			<Row>
				<Col span={6}>
			        {loading ? (
			            <Spin style={{ marginRight: 30, left: -7, top: -3 }} indicator={antIcon}><Avatar style={{ marginRight: 5, width: '50px', height: '50px', marginTop: 25, fontSize: 30 }} icon={<UserOutlined />} /> </Spin>
			        ) : (
			            logo ?
			                <Avatar style={{ marginRight: 5, width: '50px', height: '50px', marginTop: 25, fontSize: 30 }} src={'data:image/png;base64,' + logo
			                } /> :
			                <Avatar style={{ marginRight: 5, width: '50px', height: '50px', marginTop: 25, fontSize: 30 }} icon={<UserOutlined />} />
			        )}
				</Col>
				<Col span={18} style={{ textAlign: 'left', color: 'white' }}>
					<span>Bienvenido</span>
					<Title style={{position: 'relative', top: -20 }} level={4}>{session.userInfo.name}</Title>
				</Col>
            </Row>
		  	{
		  		!widthMenuMobile.options ?
				  	<AlignLeftOutlined className="btnMenuMbole" style={{ zIndex: 1, position: 'fixed', left:'330px', top: '30px'}} onClick={() =>changeMenuMobile(false)}/>
				 :
				  	<CloseOutlined className="btnMenuMbole" style={{ zIndex: 1, position: 'fixed', left:'330px', top: '30px'}} onClick={() =>changeMenuMobile(true)}/>
		  	}
		  	<div className={!widthMenuMobile.options ? 'menuLateralMobileHidden' : 'menuLateralMobile'} >
		      <Headers widthMenu={widthMenuMobile}/>
		      <MenuOptions changeMenu={changeMenu} widthMenu={widthMenuMobile}/> 
		    </div>
		</Header>
	    <Content>
	      <Layout className="site-layout-background">
	        <Sider className="menu-main" style={{ transition: 'all 0.2s', width: widthMenu.width, posicion: 'absolute', height: '100vh' }} width={widthMenu.width}>
			  	{
			  		!widthMenu.options ?
					  	<Button style={{ zIndex: 1, transition: 'all 0.6s', color: 'white', backgroundColor: '#010c33', height: '50px', position: 'fixed', left:'70px', top: '70px'}} onClick={() =>changeMenu(false)}><RightOutlined style={{ marginLeft: '50%' }} /></Button>
					 :
					  	<Button style={{ zIndex: 1, transition: 'all 0.5s', color: 'white', width: '100px', backgroundColor: '#010c33', height: '50px', position: 'fixed', left:'175px', top: '70px'}} onClick={() =>changeMenu(true)}><LeftOutlined style={{ marginLeft: '90%' }} /></Button>
			  	}
	        	<div className="menuLateral" style={{ width: widthMenu.width, transition: 'all 0.5s' }}> 	      
		          <Headers widthMenu={widthMenu}/>
		          <MenuOptions changeMenu={changeMenu} widthMenu={widthMenu}/> 
	          	</div>   
	        </Sider>
	        <Content>
	            {isAuthorized() ?
	                <Route {...rest}>
	                    {!isExpired(token) ?
	                        <Component /> : <Redirect to="/session-expired" />
	                    }
	                </Route> : <Redirect to="/login" />}
	        </Content>
	      </Layout>
	    </Content>
	  </Layout>
	  </>
	)
};

export default Index;