import React from 'react';
import { Table } from 'antd';

const TableListStock = ({data, fba, columns, columnsMain}) => {
	return <Table
			columns={columnsMain}
			rowKey={data.id}
		    expandable={{
		    	childrenColumnName: 'children',
		    	expandRowByClick: true,
		    	onExpand: (record) => record.title === 'Arava' ? true : false,
				expandedRowRender: columns.length > 0 ? (record, index, indent, expanded) => (
					<>
						{
							columns.length > 0 &&
								<Table columns={columns} pagination={false} dataSource={record.children}/>
						}
					</>
				) : false
		    }}
			dataSource={data}
		    pagination={{
		      pageSize: 10,
		    }}
		    scroll={{
		      y: 470,
		    }}/>;
}
export default TableListStock;