import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select, Skeleton, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Title, Text } = Typography;
const { Option } = Select;

export const AttributesHomologation = ({
    loadingProperties, origin, destination, attributesByMarketplace,
}) => {
    const { t } = useTranslation();

    return (
        <>
            {loadingProperties ?
                <Skeleton active /> :
                destination && origin && attributesByMarketplace &&
                <Row style={{ marginTop: 10 }}>
                    <Col span={24}>
                        <Form.List name="attributes" >
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Row key={index} >
                                            <Col span={10}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'origin']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                    >
                                                        {attributesByMarketplace[origin]?.attributes?.properties?.map((attribute) => (
                                                            <Option key={attribute?.name} value={attribute?.name}>
                                                                {attribute?.name}{' '}
                                                                {attributesByMarketplace[origin]?.attributes?.required?.
                                                                    find(req => req == attribute?.name)
                                                                    && <span style={{ color: 'red' }}>* </span>}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1} />
                                            <Col span={10}>
                                                <Form.Item
                                                    {...field}
                                                    name={[field.name, 'destination']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ width: '100%' }}
                                                    >
                                                        {attributesByMarketplace[destination]?.attributes?.properties?.map((attribute) => (
                                                            <Option key={attribute?.name} value={attribute?.name}>
                                                                {attribute?.name}{' '}
                                                                {attributesByMarketplace[destination]?.attributes?.required?.
                                                                    find(req => req == attribute?.name)
                                                                    && <span style={{ color: 'red' }}>* </span>}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <MinusCircleOutlined className="dynamic-delete-button"
                                                style={{ margin: '5px 8px', color: '#999', fontSize: 20 }}
                                                onClick={() => remove(field.name)} />
                                        </Row>
                                    ))}
                                    <Row>
                                        <Col></Col>
                                        <Col span={5}>
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    {t('homologation.attributes.add')}
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col span={5} />
                </Row>
            }
        </>
    )
}
