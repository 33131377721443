import { useEffect, useState } from 'react';
import { Row, Col, Form, Input, AutoComplete, Tooltip, Button, Spin, Typography, Alert } from 'antd';
import { InfoCircleOutlined, RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { canEdit, keyPressSpaceBar } from '../../../../../utils/functions';
import categoryApi from '../../../../../api/aws-category';
import { openNotification } from '../../../../../components/Toastr';
import { onboardingTabs } from '../../../../../utils/const';

const { Text } = Typography;
const antIcon = <RedoOutlined spin />;
export default (props) => {

    const {
        selectedMarketplace,
        form, session, partnerId,
        step, loadingUpdateCategory, pricesRetrieved, partnerSelfService,
        category, currentTab, tab, updateCategoriesByMarketplace, canGetPropertiesByCategory
    } = props;

    const { t } = useTranslation();
    const { Item } = Form;

    const [loadingGetCategories, setLoadingGetCategories] = useState(false);
    const [categoriesByMarketplace, setCategoriesByMarketplace] = useState();
    const [realProductType, setRealProductType] = useState(null);
    const [loadingGetProductType, setLoadingGetProductType] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);

    useEffect(() => {
        if (tab && currentTab && pricesRetrieved && pricesRetrieved[tab]
            && pricesRetrieved[tab].listingPerMarketplace && pricesRetrieved[tab].listingPerMarketplace[currentTab]) {
            const productTypeRetrieved = pricesRetrieved[tab]?.listingPerMarketplace[currentTab]?.productType || null;
            if (productTypeRetrieved != null) {
                handleSwitchProductType(productTypeRetrieved);
            }
        }
    }, [pricesRetrieved])

    const handleSwitchProductType = (productType) => {
        setRealProductType(null);
        setLoadingGetProductType(true);
        const partner = partnerId || 1;
        categoryApi.getProductTypeDefinition(partner, selectedMarketplace, productType)
            .then(res => {
                setRealProductType(res.data);
                setLoadingGetProductType(false);
            })
            .catch(error => {
                setRealProductType(null);
                setLoadingGetProductType(false);
            })
    }

    const getCategoriesByMarketplace = () => {
        setLoadingGetCategories(true);
        const inputCategory = form.getFieldValue([tab, "listingPerMarketplace", selectedMarketplace, "category"]);
        if (selectedMarketplace) {
            categoryApi.getByMarketplaceAndSearch(selectedMarketplace, inputCategory)
                .then(res => {
                    const data = res.data;
                    setCategoriesByMarketplace(data?.map((item, index) => ({
                        ...item,
                        value: `${item.name}-${item.product_type}`,
                        label: renderItem(item)?.label,
                        key: index
                    })
                    ));
                    setLoadingGetCategories(false);
                    setOpenDropdown(true);
                })
                .catch(error => {
                    setLoadingGetCategories(false);
                    openNotification({ status: false, content: error?.response?.data?.message || `Error al obtener el listado de categorías para ${currentTab}` });
                })
            let newVals = { [`${selectedMarketplace}`]: { listingPerMarketplace: { [`${selectedMarketplace}`]: { productType: null } } } }
            form.setFieldsValue(newVals);
        }
    }

    const renderItem = (category) => ({
        value: category,
        label: (
            <Row>
                <Tooltip placement="top" title={`${category?.complete_name?.replace(/,/g, "/")}`}>
                    <Col span={24}>
                        <Text style={{ fontWeight: 600, fontSize: 16, marginRight: 10 }}>{category?.name}</Text>
                    </Col>
                    <Col span={24}>
                        <Text style={{ color: 'grey', fontSize: 12 }}>{category?.complete_name?.replace(/,/g, "/")}</Text>
                    </Col>
                </Tooltip>
            </Row>
        ),
    });

    return (
        <>
            {pricesRetrieved && tab && currentTab &&
                <Row>
                    <Col xs={24} sm={24} md={24}>
                        <Item label={t('onboarding.price.groupTitle')} className="input-form-margin-bottom" name={[tab, "listingPerMarketplace", selectedMarketplace, "name"]}
                            rules={[{ max: 500, required: true }]}
                            tooltip={{
                                title: t('onboarding.price.groupTitleDescription'),
                                icon: <InfoCircleOutlined />,
                            }}>
                            <Input.TextArea rows={2} maxLength={500} placeholder="Muffin de legumbres" disabled={!canEdit(session, tab, step?.state)} />
                        </Item>
                    </Col>
                </Row>
            }
            <Row>
                <Col span={tab == onboardingTabs.LAP ? 21 : 24}>
                    <p></p>
                    <Item hidden name={[tab, "listingPerMarketplace", selectedMarketplace, "productType"]}>
                        <Input type="hidden"></Input>
                    </Item>

                    <Item label={t('onboarding.order.step1Input1')}
                        name={[tab, "listingPerMarketplace", selectedMarketplace, "category"]}
                        tooltip={{
                            title: tab == onboardingTabs.LAP ? t('onboarding.price.inputCategoryForLAP') : t('onboarding.price.inputCategoryForPartner'),
                            icon: <InfoCircleOutlined />,
                        }}
                        rules={[{ required: true }]}>
                        {tab == onboardingTabs.LAP ?
                            <AutoComplete allowClear
                                disabled={step?.state ? !canEdit(session, tab, step?.state) : false}
                                placeholder={category || "Muffin mix"}
                                options={categoriesByMarketplace}
                                open={openDropdown}
                                onChange={() => setOpenDropdown(true)}
                                onKeyPress={(e) => { keyPressSpaceBar(e) }}
                                onSelect={(op, value) => {
                                    if (value?.product_type) {
                                        handleSwitchProductType(value?.product_type?.split(',')[0]);
                                        let newVals = {
                                            [`${tab}`]: {
                                                listingPerMarketplace: {
                                                    [`${selectedMarketplace}`]: {
                                                        category: value?.name,
                                                        productType: value?.product_type?.split(',')[0]
                                                    }
                                                }
                                            }
                                        }
                                        setOpenDropdown(false);
                                        form.setFieldsValue(newVals);
                                        !currentTab && !step && canGetPropertiesByCategory();
                                    }
                                }}
                            >
                                {/* {session.userInfo.isAdmin && tab === onboardingTabs.LAP &&
                                    <Input suffix={session.userInfo.isAdmin &&
                                        <Tooltip placement="topLeft" title={`Actualizar categorías para ${selectedMarketplace} `}>
                                            <Button
                                                type="link"
                                                disabled={!canEdit(session, tab, step?.state)}
                                                icon={loadingUpdateCategory ? <Spin indicator={antIcon} /> : <RedoOutlined />}
                                                onClick={updateCategoriesByMarketplace}
                                            />
                                        </Tooltip>
                                    }></Input>
                                } */}
                            </AutoComplete> :
                            <Input style={{ width: '94%' }}
                                disabled={!canEdit(session, tab, step?.state)}
                                placeholder={category || "Ingrese el nombre"}
                            />
                        }
                    </Item>
                </Col>
                {(session.userInfo.isAdmin || partnerSelfService) && tab === onboardingTabs.LAP &&
                    <Col style={{ marginLeft: 5, marginTop: 15 }}>
                        <Tooltip title={t('onboarding.price.btnSearchCategoriesForLAP')}>
                            <Button type="primary"
                                icon={<SearchOutlined />} loading={loadingGetCategories}
                                onClick={() => getCategoriesByMarketplace()} />
                        </Tooltip>
                    </Col>
                }
            </Row>
            {loadingGetProductType && session.userInfo.isAdmin && tab == onboardingTabs.LAP ?
                <Spin tip={`${t('onboarding.price.loadingProductType')} ${form.getFieldValue([tab, 'listingPerMarketplace', selectedMarketplace, 'productType'])} ...`} />
                : (realProductType?.status && tab == onboardingTabs.LAP ?
                    <Alert style={{ margin: 10 }}
                        description={'Error'}
                        message={`Error al obtener el product type de la categoría seleccionada`}
                        type={'warning'}
                        showIcon
                        closable
                    /> : (typeof realProductType === 'string' && ![null, undefined, ''].includes(realProductType) && tab == onboardingTabs.LAP && currentTab ?
                        <Alert style={{ margin: 10 }}
                            description={realProductType == 'PRODUCT' ? t('onboarding.price.productTypeWarningMessage') : t('onboarding.price.productTypeSuccessMessage')}
                            message={`PRODUCT TYPE: ${form.getFieldValue([tab, 'listingPerMarketplace', selectedMarketplace, 'productType'])}`}
                            type={realProductType == 'PRODUCT' ? 'warning' : 'success'}
                            showIcon
                            closable
                        />
                        : <></>)
                )
            }
        </>
    )
}