import React from 'react';
import { useSelector } from 'react-redux';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { thousandsSeparator } from '../../../../utils/functions';

const SalesPieChartByMarketplace = ({ dataSource }) => {
    const session = useSelector(store => store.Session.session);
    
    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const config = {
        appendPadding: 10,
        data: dataSource,
        angleField: 'value',
        height: 'auto',
        colorField: 'type',
        radius: 0.8,
        innerRadius: 0.7,
        theme: {
            colors10: [
                '#010C33',
                '#00E5A6',
                '#FFC100',
                '#9FB40F',
                '#76523B',
                '#DAD5B5',
                '#0E8E89',
                '#E19348',
                '#F383A2',
                '#247FEA',
            ]
        },
        label: {
            type: 'inner',
            offset: '-30%',
            content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
            style: {
                fontSize: 14,
                textAlign: 'center',
            },
        },        
        meta: {
            value: {
                formatter: (v) => `${thousandsSeparator(v)} ${!session?.userInfo?.isAdmin ? 'USD' : 'Stock'}`,
            },
        },
        statistic: {
            title: {
                offsetY: 0,
                style: {
                    fontSize: '10px',
                },
                customHtml: (container, view, datum, data) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = datum ? datum?.type : data[0]?.type;
                    return renderStatistic(d, text, {
                        fontSize: 10,
                    });
                },
            },
            content: {
                offsetY: 0,
                style: {
                    fontSize: '12px',
                    padding: 20
                },
                customHtml: (container, view, datum, data) => {
                    if(!session?.userInfo?.isAdmin){
                        const { width } = container.getBoundingClientRect();
                        const valueUSD = data[0]?.value;
                        const valueMkp = data[0]?.valueMkp;
                        const currency = data[0]?.currency || 'USD';

                        const text = datum ?
                            `<span>${thousandsSeparator(datum?.value)} USD</span> ${datum?.currency != 'USD' ? `<br/><span> ${thousandsSeparator(datum?.valueMkp)} ${datum?.currency}` : ''}</span>` :
                            `<span>${thousandsSeparator(valueUSD)} USD</span> ${currency != 'USD' ? `<br/><span> ${thousandsSeparator(valueMkp)} ${currency}` : ''}</span>`;
                        return renderStatistic(width, text, {
                            fontSize: 15,
                        });
                    } else {
                        const { width } = container.getBoundingClientRect();
                        const valueUSD = data[0]?.value;
                        const valueMkp = data[0]?.valueMkp;
                        const currency = data[0]?.currency || 'Stock';

                        const text = datum ?
                            `<span>${thousandsSeparator(datum?.value)} Stock</span> ${datum?.currency != 'Stock' ? `<br/><span> ${thousandsSeparator(datum?.valueMkp)} ${datum?.currency}` : ''}</span>` :
                            `<span>${thousandsSeparator(valueUSD)} Stock</span> ${currency != 'Stock' ? `<br/><span> ${thousandsSeparator(valueMkp)} ${currency}` : ''}</span>`;

                        return renderStatistic(width, text, {
                            fontSize: 15,
                        });                        
                    }
                },
            },
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
            {
                type: 'tooltip',
                cfg: { start: [{ trigger: 'element:click', action: 'tooltip:show' }] }
            }
        ],
    };

    return <Pie {...config} />;
};
export default React.memo(SalesPieChartByMarketplace);
