import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Alert, Spin, TreeSelect, Input, Typography, Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next'
import { openNotification } from '../../../../../components/Toastr';
import categoryApi from '../../../../../api/aws-category';
import { canEdit } from '../../../../../utils/functions';
import { onboardingTabs } from '../../../../../utils/const';

const { Item } = Form;
const { Text } = Typography;

export default (props) => {
    const {
        step, loadingUpdateCategory, pricesRetrieved, partnerSelfService,
        form, partnerId, selectedMarketplace, updateCategoriesByMarketplace,
        currentTab, tab, session, setCategoryAdjustments, canGetPropertiesByCategory
    } = props;

    const { t } = useTranslation();
    const [categories, setCategories] = useState([]);
    const [loadingGetCategories, setLoadingGetCategories] = useState(false);
    const [categoryData, setCategoryData] = useState(null);
    const [loadingGetProductType, setLoadingGetProductType] = useState(false);


    useEffect(() => {
        if (!loadingUpdateCategory) {
            getCategories();
        }
    }, [loadingUpdateCategory])

    useEffect(() => {
        if (tab && currentTab && pricesRetrieved && pricesRetrieved[tab]
            && pricesRetrieved[tab].listingPerMarketplace && pricesRetrieved[tab].listingPerMarketplace[currentTab]) {
            const productTypeRetrieved = pricesRetrieved[tab]?.listingPerMarketplace[currentTab]?.productType || null;
            if (productTypeRetrieved != null) {
                handleSwitchProductType(productTypeRetrieved);
            }
        }
    }, [pricesRetrieved])

    const getCategories = () => {
        setLoadingGetCategories(true);
        categoryApi.getByMarketplaceAndSearch(selectedMarketplace, 'all')
            .then(res => {
                const data = res.data;
                setCategories(data);
                setLoadingGetCategories(false);
            })
            .catch(error => {
                setLoadingGetCategories(false);
                openNotification({ status: false, content: error?.response?.data?.message || `Error al obtener el listado de categorías para ${currentTab}` });
            })
    }

    const handleSwitchProductType = (productType) => {
        setCategoryData(null);
        setLoadingGetProductType(true);
        const partner = partnerId || 1;
        categoryApi.getProductTypeDefinition(partner, selectedMarketplace, productType)
            .then(res => {
                setCategoryData(res.data);
                setCategoryAdjustments({ [currentTab]: res.data });
                setLoadingGetProductType(false);
            })
            .catch(error => {
                setCategoryData(null);
                setLoadingGetProductType(false);
            })
    }

    return (
        <>
            {pricesRetrieved && tab && currentTab &&
                <Row>
                    <Col xs={24} sm={24} md={24}>
                        <Item label={t('onboarding.price.groupTitle')} className="input-form-margin-bottom" name={[tab, "listingPerMarketplace", selectedMarketplace, "name"]}
                            rules={[{ max: 500, required: true }]}
                            tooltip={{
                                title: t('onboarding.price.groupTitleDescription'),
                                icon: <InfoCircleOutlined />,
                            }}>
                            <Input.TextArea rows={2} maxLength={500} disabled={!canEdit(session, tab, step?.state)} />
                        </Item>
                    </Col>
                </Row>
            }
            <Row>
                <Col span={24}>
                    <p></p>
                    <Item hidden name={[tab, "listingPerMarketplace", selectedMarketplace, "productType"]}>
                        <Input type="hidden"></Input>
                    </Item>
                    <Item label={t('onboarding.order.step1Input1')}>
                        <Item
                            name={[tab, "listingPerMarketplace", selectedMarketplace, "category"]}
                            tooltip={{
                                title: t('onboarding.order.step1Input4Description'),
                                icon: <InfoCircleOutlined />,
                            }}
                            style={{ display: 'inline-block', width: '94%' }}
                            rules={[{ required: true }]}>
                            {tab == onboardingTabs.LAP ?
                                <TreeSelect
                                    treeData={categories}
                                    showSearch size={'medium'}
                                    allowClear treeDataSimpleMode
                                    loading={loadingGetCategories}
                                    placeholder="Tipea el nombre o seleccione"
                                    disabled={step?.state ? !canEdit(session, tab, step?.state) : false}
                                    onChange={(op, value) => {
                                        if (op?.categoryId) {
                                            handleSwitchProductType(op?.categoryId);
                                            let newVals = {
                                                [`${tab}`]: {
                                                    listingPerMarketplace: {
                                                        [`${selectedMarketplace}`]: {
                                                            category: op.categoryName,
                                                            productType: op.categoryId
                                                        }
                                                    }
                                                }
                                            }
                                            form.setFieldsValue(newVals);
                                            !currentTab && !step && canGetPropertiesByCategory();
                                        }
                                    }}
                                    filterOption={(input, option) =>
                                        option?.value?.categoryName?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                >
                                </TreeSelect> :
                                <Input style={{ width: '94%' }}
                                    disabled={!canEdit(session, tab, step?.state)}
                                    placeholder={"Ingrese el nombre"}
                                />
                            }
                        </Item>
                        {/* <Item style={{ display: 'inline-block', width: '6%' }}>
                            {session.userInfo.isAdmin && tab === onboardingTabs.LAP &&
                                <Tooltip title={`Actualizar categorías para ${selectedMarketplace} `} >
                                    <Button
                                        disabled={!canEdit(session, tab, step?.state)}
                                        icon={loadingUpdateCategory ? <Spin indicator={antIcon} /> : <RedoOutlined />}
                                        onClick={updateCategoriesByMarketplace}
                                    />
                                </Tooltip>
                            }
                        </Item> */}
                    </Item>
                </Col>
            </Row>
            {loadingGetProductType && (session.userInfo.isAdmin || partnerSelfService) && tab == onboardingTabs.LAP && currentTab ?
                <Spin tip={`${t('onboarding.price.loadingProductType')} ${form.getFieldValue([tab, 'listingPerMarketplace', selectedMarketplace, 'productType'])} ...`} />
                : (categoryData && tab == onboardingTabs.LAP ? <Alert style={{ margin: 10 }}
                    message={`Esta categoría ${!categoryData?.variations ? 'no ' : ''}soporta variantes`}
                    description={<Row>
                        <Col span={24}>
                            <Text>Información de la categoría </Text>
                        </Col>
                        {categoryData?.minimumPrice &&
                            <Col span={24}>
                                <Text>Precio mínimo: ${categoryData?.minimumPrice}</Text>
                            </Col>
                        }
                        {categoryData?.maximumPrice &&
                            <Col span={24}>
                                <Text>Precio máximo: ${categoryData?.maximumPrice}</Text>
                            </Col>
                        }
                        Recuerde que en Mercadolibre, el precio de las variantes deben ser idénticos
                    </Row>}
                    type={categoryData?.variations ? 'success' : 'warning'}
                    showIcon
                    closable
                /> : <></>)
            }
        </>
    )
}
