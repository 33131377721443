import api from "./api";

const prefix = '/accessKeys';

const accessKeysApi = {
    findByIdAndMarketplace: function (id, marketplace, type) {
        return api.get(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/${type}`);
    },
    create: function (sellerAccount) {
        return api.post(`${prefix}`, sellerAccount);
    },
    update: function (id, sellerAccount) {
        return api.put(`${prefix}/user/${id}`, sellerAccount);
    },
    getConsentUrl: function (id, marketplace) {
        return api.get(`${prefix}/sellerMarketplace/${marketplace.toLowerCase()}/consentUrl`);
    },
    forgetCredentials: function (id, marketplace) {
        return api.delete(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}`);
    },
    saveCredentials: function (id, marketplace, payload) {
        return api.post(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}`, payload);
    },
    verifyCredentials: function (id, marketplace,payload) {
        return api.get(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/verifycrendentials`, payload);
    },
    getAdsConsentUrl: function (marketplace) {
        return api.get(`${prefix}/sellerMarketplace/${marketplace.toLowerCase()}/ads/consentUrl`);
    },
    saveAdsCredentials: function (id, marketplace, payload) {
        return api.post(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/ads/credentials`, payload);
    },
    forgetAdsCredentials: function (id, marketplace) {
        return api.delete(`${prefix}/user/${id}/sellerMarketplace/${marketplace.toLowerCase()}/ads`);
    },
};
export default accessKeysApi;
