import React, { useEffect, useState } from 'react'
import { Col, Divider, Row, Card, Button, Spin, Switch, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import accessKeysApi from '../../../../api/aws-access-keys'
import { useHistory, useLocation, useParams } from 'react-router'
import * as queryString from 'query-string';
import { CheckCircleFilled, LinkOutlined, FilePptOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { URL_SP_AWS } from '../../../../utils/const'
import { useDispatch } from 'react-redux'
import * as Actions from '../../../../redux/session/action';
import { openNotification } from '../../../../components/Toastr';

export default (props) => {
    const { t } = useTranslation();
    const { consent } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const {
        session,
        mySellerAccount,
        reloadSellerAccount,
        toggleMeliCredentials,
        deletingMeliCredentials,
        tab } = props;

    const [consentUrl, setConsentUrl] = useState();
    const [loadingConsentUrl, setLoadingConsentUrl] = useState(false);
    const [processingConsent, setProcessingConsent] = useState(false);

    useEffect(() => {
        if (consent) {
            let qs = queryString.parse(location.search);
            setProcessingConsent(true);
            accessKeysApi.saveCredentials(session?.userInfo?.commercial_partner_id[0], tab, qs).then((resp) => {
                reloadSellerAccount();
                setProcessingConsent(false);
                if (!resp?.data?.success && !resp?.data?.id) {
                    openNotification({ status: false, content: 'Error al sincronizar el seller. Intente nuevamente.' });
                    history.push("/my-account?step=2&tomarketplace=Mercadolibre");
                    return;
                }
                dispatch(Actions.updateSellerAccountStatusSession(true));
                history.push("/my-account?step=2&tomarketplace=Mercadolibre");
            }).catch(() => {
                setProcessingConsent(false);
                history.push("/my-account?step=2&tomarketplace=Mercadolibre");
                openNotification({ status: false, content: 'Error al sincronizar el seller. Intente nuevamente.' });
            });
        }
    }, [consent])

    useEffect(() => {
        setLoadingConsentUrl(true);
        accessKeysApi.getConsentUrl(session?.userInfo?.commercial_partner_id[0], tab).then((resp) => {
            setConsentUrl(resp.data.consentUrl);
            setLoadingConsentUrl(false);
        }).catch(() => {
            setLoadingConsentUrl(false);
        });
    }, [mySellerAccount]);

    return (
        <>
            <Row gutter={[12, 2]} className="text-align-left">
                <Col>
                    {processingConsent ? <Spin /> :
                        <Button href={consentUrl} loading={loadingConsentUrl} type="primary" ghost>
                            {mySellerAccount?.length == 0 ? 'Asociar con Mercado Libre' : 'Agregar otra cuenta/región'}
                        </Button>
                    }
                </Col>
            </Row>
            <br />
            {mySellerAccount?.length > 0 && mySellerAccount?.map(account => (
                <Row className="text-align-left">
                    <Col span={4}> {account?.marketplace}</Col>
                    <Col span={1}>
                        <Tooltip title='Desvincular cuenta' placement='right'>
                            <Spin spinning={deletingMeliCredentials?.region == account.marketplace && deletingMeliCredentials?.status}>
                                <Switch
                                    size="small"
                                    checked={account?.credentials}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={(checked) => { toggleMeliCredentials(checked, account?.marketplace) }} />
                            </Spin>
                        </Tooltip>
                    </Col>
                </Row>
            ))}
        </>
    )
}
