import React from 'react';
import { Row, Col, Divider, Typography } from 'antd';
import MeliListingPostArticle from './byMarketplace/MeliListingPostArticle';
import newLogo from '../../../assets/LAP_Marketplace.jpg';
import { useParams } from 'react-router';

const { Title, Text } = Typography;
const formByMarketplace = {
    "Mercadolibre": MeliListingPostArticle,
    // "Amazon": AmazonListingPostArticle,
    // "Walmart": WalmartListingPostArticle,
    // "Ebay": EbayListingPostArticle,
    // "Shopify": ShopifyListingPostArticle,
}

const ListingPostArticleByMarketplace = (props) => {
    let Comp = formByMarketplace[props.marketplace] || undefined;
    return <Comp {...props}></Comp>
}

const ListingPostArticle = () => {
    const { marketplace } = useParams();
    return (
        <>
            <Row style={{ backgroundColor: '#ECECEC', height: '100vh' }}>
                <Col span={18} offset={3} style={{ backgroundColor: '#FFFFFF', textAlign: 'left', padding: 15 }}>
                    <Row justify="center" align="middle">
                        <Col span={6}>
                            <img src={newLogo} className="login-logo" />
                        </Col>
                        <Col span={18}>
                            <Title level={3} >Ayuda para corregir errores en la publicación</Title>
                        </Col>
                    </Row>
                    <Divider />
                    <Text type="secondary">¿Tuviste un error al publicar un listing?</Text><br />
                    <Text type="secondary">Soluciones a los mensajes de errores más comunes que pueden surgir al momento de generar una nueva publicación, y algunos consejos para solucionar estos errores.</Text>
                    <ListingPostArticleByMarketplace marketplace={marketplace} />
                </Col>
            </Row>
        </>
    )
}

export default ListingPostArticle;