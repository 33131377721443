import api from "./api";

const prefix = '/couriers';
const endpoints = {
    getCourierShipmentByOrderId: (courier, orderId) => {
        return api.get(`${prefix}/${courier}/shipment/sale-order/${orderId}`)
    },
    createCourierShipment: (courier, saleOrderId) => {
        const body = {
            saleOrderId,
        }
        return api.post(`${prefix}/${courier}/shipment/create`, body);
    },
    cancelCourierShipment: (courier, shipmentId) => {
        return api.put(`${prefix}/${courier}/shipment/${shipmentId}/cancel`)
    },
    getShipmentDocument: (courier, shipmentId) => {
        return api.get(`${prefix}/${courier}/shipment/${shipmentId}/labels`)
    },

    
}
export default endpoints;