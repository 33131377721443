import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Modal, Row, Typography, Divider } from 'antd';
import 'antd/dist/antd.css';

const { Text, Title, Link } = Typography;

const ModalShipmentByFedex = ({ modalShipmentByFedex, setModalShipmentByFedex }) => {

    const { t } = useTranslation();
    const [currentInboundShipmentPlan, setCurrentInboundShipmentPlan] = useState(null);
    useEffect(() => {
        if (modalShipmentByFedex?.data) {
            if (modalShipmentByFedex?.data?.order?.shipmentPlan?.InboundShipmentPlans?.length > 0) {
                const amazonShipmentPlan = modalShipmentByFedex?.data?.order?.shipmentPlan?.InboundShipmentPlans
                    ?.find(shipmentPlan => shipmentPlan.ShipmentId = modalShipmentByFedex?.data?.amazonShipmentPlanId);
                setCurrentInboundShipmentPlan(amazonShipmentPlan);
            }
        }
    }, [modalShipmentByFedex?.data])

    return (
        <Modal
            centered
            destroyOnClose
            onCancel={() => { setModalShipmentByFedex({ state: false, data: null }) }}
            visible={modalShipmentByFedex?.status}
            cancelButtonProps={{ className: 'btn-primary' }}
            okButtonProps={{ style: { display: 'none' } }}
            cancelText="Cerrar"
            title={`${t('orders.newOrder.state.shipmentByFedEXModalTitle')} Amazon Shipment Plan: #${modalShipmentByFedex?.data?.amazonShipmentPlanId}`}
            width={800}>
            <Row>
                <Col xs={24} sm={24} md={12} lg={8}>
                    <Row>
                        <Col span={24}> <Title level={4}> Shipment Plan</Title> </Col>
                        <Col> <Text> Destination Fulfillment CenterId: {currentInboundShipmentPlan?.DestinationFulfillmentCenterId}</Text> </Col>
                        <Col span={24}><Text> Shipping Type: {modalShipmentByFedex?.data?.order?.shippingType}</Text></Col>
                        <Col span={24}><Text>Items</Text></Col>
                        <Col span={24}>
                            <Text>{currentInboundShipmentPlan?.Items?.map(item => (
                                <Row key={item?.SellerSKU}>
                                    <Col><Text> {item.SellerSKU} = {item.Quantity} un.</Text> </Col>
                                </Row>
                            ))}</Text>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8}>
                    <Row>
                        <Col span={24}>
                            <Title level={4}> Ship To Address</Title>
                        </Col>
                        <Col span={24}><Text> Address: {currentInboundShipmentPlan?.ShipToAddress?.AddressLine1} </Text></Col>
                        <Col span={24}><Text> City: {currentInboundShipmentPlan?.ShipToAddress?.City} </Text></Col>
                        <Col span={24}><Text> Country Code: {currentInboundShipmentPlan?.ShipToAddress?.CountryCode} </Text></Col>
                        <Col span={24}><Text> Postal Code: {currentInboundShipmentPlan?.ShipToAddress?.PostalCode} </Text></Col>
                        <Col span={24}><Text> State Or Province Code: {currentInboundShipmentPlan?.ShipToAddress?.StateOrProvinceCode} </Text></Col>
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={12} lg={8}>
                    <Row>
                        <Col span={24}>
                            <Title level={4}> Pieces</Title>
                            {modalShipmentByFedex?.data?.pieces?.map(piece => (
                                <Row>
                                    <Col span={24}>
                                        <Text> Package Documents Url:</Text>
                                        <Link href={piece?.packageDocumentsUrl} target="_blank"> Ver etiquetas</Link>
                                    </Col>
                                    <Col span={24}>
                                        <Text> Tracking Number: <Link href={`https://www.fedex.com/fedextrack/?trknbr=${piece?.trackingNumber}`} target="_blank"> {piece?.trackingNumber}</Link>  </Text>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
}

export default React.memo(ModalShipmentByFedex);