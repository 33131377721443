import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Progress } from 'antd';
import DashboardFilter from './components/DashboardFilter';
import { PicLeftOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/partner/action';
import { getErrorMessage } from '../../api/api';
import IndexChart from './components/charts/IndexDetalles';
import selectors from '../../redux/analytic/dashboard/selectors';
import partnerApi from '../../api/partner';
import orderApi from '../../api/order';
import 'antd/dist/antd.css';
import './home.css';

export const Detalles = () => {
    const dispatch = useDispatch();
    const session = useSelector(store => store.Session.session);
    const loading = useSelector(selectors.selectLoading);
    const loadingProgress = useSelector(selectors.selectLoadingProgress);

    const rows = useSelector(selectors.selectRows);
    const rowsStock = useSelector(selectors.selectRowsStock);

    const [loadingProgressForClient, setLoadingProgressForClient] = useState(0);
    const [orderRestock, setOrdersRestock] = useState([]);
    const [meta, setMeta] = useState({});

    let partnerId = 1; // admin
    const isAdmin = session.userInfo.isAdmin;
    
    if (!isAdmin) {
        partnerId = session.userInfo.commercial_partner_id[0];
    }

    let progress = Math.floor((loadingProgress.progress * 100) / loadingProgress.totalToProcess);
    useEffect(() => {
        let timer;
        if (loadingProgress?.totalToProcess == 1) {
            if (loadingProgressForClient < 90) {
                timer = setTimeout(() => {
                    if (loadingProgressForClient < 90) {
                        setLoadingProgressForClient(loadingProgressForClient + 10);
                    }
                }, 2000);
            }
        }
        return () => clearTimeout(timer);
    }, [loadingProgress, loadingProgressForClient]);

    useEffect(() => {
        if (loadingProgress?.progress == 0 && loadingProgressForClient > 0) {
            setLoadingProgressForClient(0);
        }
    }, [loadingProgress.progress])

    useEffect(() => {
        getOrders()
        if (session) {
            dispatch(Actions.getPartner());
            partnerApi.findById(partnerId)
                .then(res => {
                    if (res.status === 200) {
                        delete res.data.x_product_template_ids;
                        dispatch(Actions.getPartnerSuccess(res.data));
                    }
                    else { dispatch(Actions.getPartnerFailed(res.data.message)); }
                })
                .catch(error => {
                    dispatch(Actions.getPartnerFailed(getErrorMessage(error)));
                })
        }
    }, [])

    useEffect(() => {
        if (session?.userInfo?.commercial_partner_id) {
            getListingsPerPageInit();
        }
    }, [session])

    const getListingsPerPageInit = async () => {
        const values = {
            partnerId: session?.userInfo?.commercial_partner_id[0]
        }
        partnerApi.getListingsPerPage(values)
            .then(res => {
                if (res.status === 200) {
                    let arrList = res?.data?.data.map(obj => obj.state)
                    let count = {}
                    arrList.forEach(function (x) { count[x] = (count[x] || 0) + 1 })
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    const getOrders = async () => {
        const values = {
            partnerId: session.userInfo.isAdmin ? null : session.userInfo.commercial_partner_id[0],
            page: meta?.page ? meta.page : 1,
            take: 50
        }
        await orderApi.getOrdersPerPage(values)
            .then((response) => {
                setOrdersRestock(response.data.data);
                setMeta(response.data.meta)
                return response.data.data
            })
            .catch((error) => {
                setOrdersRestock([]);
            });
    };

    return (
        <Row className="home-listing-main-row listings-main">
            <Col span={24} xs={24} sm={24} md={24}>
                <Card className="detalles-home-listing-card">
                    <Row >
                        <Col span={8} md={24} >
                            <div className="home-listing-card-content">
                                <Col>
                                    <div className="home-listing-card-content">
                                        <div style={{ textAlign: 'left' }}>
                                            <span className="home-listing-title" style={{ fontSize: '22px', marginTop: '10px' }} >
                                                <PicLeftOutlined className="btn-primary home-listing-title-icon" />
                                                <span style={{ marginLeft: 10 }}>Detalle</span>
                                            </span>
                                        </div>
                                        <span className="home-listing-title-description">
                                            Reporte de Ventas
                                        </span>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </Row>
                    <Row className='filtros-detalles'>
                        <Col xs={24} md={24} lg={24} xl={24} span={12}>
                            <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                                <Row>
                                    <Col xs={24} span={24} className="intro-filters">
                                        <DashboardFilter isAdmin={isAdmin} partnerId={partnerId} />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    {!loading ?
                        <IndexChart orderRestock={orderRestock} />
                        :
                        <div style={{ marginTop: '5%' }}>
                        {
                            rowsStock.length > 0 ?
                                rows.length > 0 ?
                                    <strong>2 de 2</strong>
                                :
                                    <strong>2 de 2</strong>
                            :
                                rows.length > 0 ?
                                    <strong>1 de 2</strong>
                                :
                                    <strong>0 de 2</strong>                            

                        }
                        <br/>
                        <Progress style={{ paddingBottom: '15%' }} type="circle" percent={loadingProgress.totalToProcess > 1 ? progress : loadingProgressForClient} width={80} />
                        </div>
                    }
                </Card>
            </Col>
        </Row>
    )
}