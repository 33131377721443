import React, { useState, useEffect } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router'
import { Menu, Avatar, Spin, Typography } from 'antd';
import { nameToSlug } from '../utils/functions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import authApi from "../api/auth"
import * as Actions from '../redux/session/action'
import partnerApi from "../api/partner";
import { useMixpanel } from 'react-mixpanel-browser';
import {
    AppstoreOutlined,
    ProfileOutlined,
    MessageOutlined,
    DollarCircleOutlined,
    HomeOutlined,
    LogoutOutlined,
    IdcardOutlined,
    FolderOutlined,
    TeamOutlined,
    LoadingOutlined,
    UserOutlined,
} from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Text } = Typography;

const MenuOptions = ({ changeMenu, widthMenu }) => {

    const { t } = useTranslation();
    const mixpanel = useMixpanel();
    const dispatch = useDispatch();
    const [dashboardAnalitycs, setDashboardAnalitycs] = useState([])
    const session = useSelector(store => store.Session.session);
    const [collapsed, setCollapsed] = useState(false);
    const [logo, setLogo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [urlActual, setUrlActual] = useState('')
    const [urlAfter, setUrlAfter] = useState(false)
    let location = useLocation();

    const linkAdapte = (value) => {
        let link = new String(value)
        let arrayBase = link.split('https://datastudio.google.com/embed/reporting/')
        let arrayFinal = arrayBase.splice(1)
        return arrayFinal[0]
    }

    const mixPanelPageView = (url, status) => {
        mixpanel.track("Page View",{
                url,
            }
        )
    }

    useEffect(() => {
        mixPanelPageView(location.pathname)       
    },[location.pathname]);

    async function handleLogout() {
        authApi.logout();
        await dispatch(Actions.logout());
        window.location = "/login";
    }

    useEffect(() => {
        if (session.userInfo.website) {
            let validData = session.userInfo.website.split('https://datastudio.google.com/embed/reporting/')
            if (validData[0] === "") {
                let valueArrayViejo = validData.splice(1)
                let newData = valueArrayViejo.map((data, index) => {
                    return {
                        id: index,
                        name: index === 0 ? 'Ventas' : index === 1 ? 'Marketing' : index === 2 ? 'Reportes en vivo' : 'NameDefault',
                        url: `https://datastudio.google.com/embed/reporting/${data}`,
                    }
                })
                setDashboardAnalitycs(newData)
            } else {
                let link = new String(session.userInfo.website);
                let arrayBase = link.split('[');
                if (Array.isArray(arrayBase) && arrayBase?.length > 1) {
                    try {
                        let newJson = JSON.parse(`[${arrayBase[1]}`);
                        setDashboardAnalitycs(newJson);
                    } catch (error) {
                        console.log('Error on setDashboardAnalitycs Headers, ', error);
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        if (session && session.userInfo) {
            if (!logo) {
                getLogo();
            }
        }
        return () => {
            setLogo(null);
            setLoading(false);
        }
    }, [session])

    const getLogo = () => {
        setLoading(true);
        partnerApi.findLogoById(session?.userInfo?.commercial_partner_id[0])
            .then(res => {
                setLogo(res.data)
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
            })
    }

    return (
        <div className="contentMenuOptions">
            <p>{urlActual}</p>
            {
                !widthMenu.mobile ?
                    !widthMenu.options ? (
                        <div style={{ marginTop: 30 }}>
                            <Menu mode={widthMenu.orientation} theme={'dark'} defaultSelectedKeys={['/']} selectedKeys={[window.location.pathname]} style={{ marginLeft: 'auto', backgroundColor: '#010c33' }} inlineCollapsed={collapsed}>
                                <Menu.SubMenu key="/" title={<Link to="/" ><AppstoreOutlined /></Link>}>
                                    <Menu.Item key="/">
                                        <Link to="/" >Resumen</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/detalles">
                                        <Link to="/detalles">Detalles</Link>
                                    </Menu.Item>
                                    {
                                        /*
                                        !session.userInfo.isAdmin &&                                
                                        <Menu.Item key="/marketing">
                                            <Link to="/marketing">Marketing</Link>
                                        </Menu.Item>  
                                        */
                                    }
                                    {
                                        dashboardAnalitycs.map((item) => (
                                            <Menu.Item key={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`}>
                                                <Link to={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`} >{item.name}</Link>
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu.SubMenu>
                                <Menu.SubMenu key="#" title={<Link to="#" ><ProfileOutlined /></Link>}>
                                    <Menu.Item key="/onboarding">
                                        <Link to="/onboarding" >Tus publicaciones</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/productos">
                                        <Link to="/productos" >Tus productos</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                <Menu.SubMenu key="/restock" title={<Link to="/restock" ><DollarCircleOutlined /></Link>}>
                                    <Menu.Item key="/restock">
                                        <Link to="/restock" >Restock</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/pedidos">
                                        <Link to="/pedidos" >Pedidos</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                {
                                    /* <Menu.Item key="/lap-academy">
                                         <Link to="/lap-academy" ><BankOutlined /></Link>
                                     </Menu.Item>*/
                                }

                                {
                                    session.userInfo.isAdmin ?
                                        <>
                                            <Menu.Item key="/clientes">
                                                <Link to="/clientes" ><TeamOutlined /></Link>
                                            </Menu.Item>
                                            <Menu.Item key="/status">
                                                <Link to="/status" ><IdcardOutlined /></Link>
                                            </Menu.Item>
                                        </>
                                        :
                                        <></>
                                }

                                <Menu.SubMenu className="menuAvatar" style={{ marginTop: 200, marginBottom: 10 }} key="perfil" title={<Link to="#" >
                                    {loading ? (
                                        <Spin indicator={antIcon}><Avatar icon={<UserOutlined />} /> </Spin>
                                    ) : (
                                        logo ?
                                            <Avatar style={{ marginBottom: 20, marginLeft: -15 }} src={'data:image/png;base64,' + logo
                                            } /> :
                                            <Avatar style={{ marginBottom: 20, marginLeft: -15 }} icon={<UserOutlined />} />
                                    )}</Link>}>
                                    {
                                        !session.userInfo.isAdmin ?
                                            <>
                                                <Menu.Item key="/my-account">
                                                    <Link to="/my-account" ><HomeOutlined /> {t('menu.myAccount')}</Link>
                                                </Menu.Item>
                                                <Menu.Item key="/my-documents">
                                                    <Link to="/my-documents" ><FolderOutlined /> Mis Documentos</Link>
                                                </Menu.Item>
                                                <Menu.Item key="/soporte">
                                                    <Link to="/soporte" ><MessageOutlined /> Soporte</Link>
                                                </Menu.Item>
                                                <Menu.Item key="" style={{ marginTop: 30 }} onClick={handleLogout}>
                                                    <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                                </Menu.Item>
                                            </>
                                            :
                                            <>
                                                <Menu.Item key="/soporte">
                                                    <Link to="/soporte" ><MessageOutlined /> Soporte</Link>
                                                </Menu.Item>
                                                <Menu.Item key="logout" onClick={handleLogout}>
                                                    <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                                </Menu.Item>
                                            </>
                                    }
                                </Menu.SubMenu>
                            </Menu>
                        </div>
                    ) :
                    (
                        <div style={{ marginTop: 9 }}>
                            <Menu mode={widthMenu.orientation} theme={'dark'} defaultSelectedKeys={['/']} selectedKeys={[window.location.pathname]} style={{ marginLeft: 'auto', backgroundColor: '#010c33' }} inlineCollapsed={collapsed}>
                                <Menu.SubMenu key="/" title={<Link to="/" ><AppstoreOutlined /> Dashboard</Link>}>
                                    <Menu.Item key="/">
                                        <Link to="/" >Resumen</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/detalles">
                                        <Link to="/detalles" >Detalles</Link>
                                    </Menu.Item>
                                    {
                                        /*
                                        !session.userInfo.isAdmin &&                                
                                        <Menu.Item key="/marketing">
                                            <Link to="/marketing" >Marketing</Link>
                                        </Menu.Item>
                                        */
                                    }                                                                        
                                    {
                                        dashboardAnalitycs.map((item) => (
                                            <Menu.Item key={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`}>
                                                <Link to={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`} >{item.name}</Link>
                                            </Menu.Item>
                                        ))
                                    }
                                </Menu.SubMenu>
                                {
                                    !session.userInfo.isAdmin ?
                                        <Menu.SubMenu key="/onboarding" title={<Link to="/onboarding" ><ProfileOutlined /> Listings</Link>}>
                                            <Menu.Item key="/onboarding">
                                                <Link to="/onboarding" >Tus publicaciones</Link>
                                            </Menu.Item>
                                        </Menu.SubMenu> : <></>
                                }
                                <Menu.SubMenu key="/restock" title={<Link to="/restock" ><DollarCircleOutlined /> Orders</Link>}>
                                    <Menu.Item key="/restock">
                                        <Link to="/restock" >Restock</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/pedidos">
                                        <Link to="/pedidos" >Pedidos</Link>
                                    </Menu.Item>
                                </Menu.SubMenu>
                                {/* <Menu.Item key="/lap-academy">
                            <Link to="/lap-academy" ><BankOutlined /> Lap Academy</Link>
                        </Menu.Item>                       */}
                                {session.userInfo.isAdmin ?
                                    <>
                                        <Menu.Item key="/clientes">
                                            <Link to="/clientes" ><TeamOutlined /> Clientes</Link>
                                        </Menu.Item>
                                        <Menu.Item key="/status">
                                            <Link to="/status" ><IdcardOutlined /> Estado</Link>
                                        </Menu.Item>
                                    </>
                                    :
                                    <></>
                                }
                                <Menu.SubMenu style={{ marginTop: 150 }} key="#1" title={<Link to="#" >
                                    {loading ? (
                                        <Spin indicator={antIcon}><Avatar icon={<UserOutlined />} /> </Spin>
                                    ) : (
                                        logo ?
                                            <Avatar style={{ marginRight: 5 }} src={'data:image/png;base64,' + logo
                                            } /> :
                                            <Avatar style={{ marginRight: 5 }} icon={<UserOutlined />} />
                                    )}  Mi Perfil</Link>}>
                                    {
                                        !session?.userInfo?.isAdmin ?
                                            <>
                                                <Menu.Item key="/my-account">
                                                    <Link to="/my-account" ><HomeOutlined /> {t('menu.myAccount')}</Link>
                                                </Menu.Item>
                                                <Menu.Item key="/my-documents">
                                                    <Link to="/my-documents" ><FolderOutlined /> Mis Documentos</Link>
                                                </Menu.Item>
                                                <Menu.Item key="/soporte">
                                                    <Link to="/soporte" ><MessageOutlined /> Soporte</Link>
                                                </Menu.Item>
                                                <Menu.Item key="" style={{ marginTop: 30 }} onClick={handleLogout}>
                                                    <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                                </Menu.Item>
                                            </>
                                            :
                                            <>
                                                <Menu.Item key="/soporte">
                                                    <Link to="/soporte" ><MessageOutlined /> Soporte </Link>
                                                </Menu.Item>
                                                <Menu.Item key="" style={{ marginTop: 30 }} onClick={handleLogout}>
                                                    <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                                </Menu.Item>                                                
                                            </>
                                    }
                                </Menu.SubMenu>
                            </Menu>
                        </div>
                    )
                :

                    <div style={{ marginTop: 9 }}>
                        <Menu mode={widthMenu.orientation} theme={'dark'} defaultSelectedKeys={['/']} selectedKeys={[window.location.pathname]} style={{ marginLeft: 'auto', backgroundColor: '#010c33' }} inlineCollapsed={collapsed}>
                            <Menu.SubMenu key="/" title={<Link to="/" ><AppstoreOutlined /> Dashboard</Link>}>
                                <Menu.Item key="/">
                                    <Link to="/" >Resumen</Link>
                                </Menu.Item>
                                <Menu.Item key="/detalles">
                                    <Link to="/detalles" >Detalles</Link>
                                </Menu.Item>
                                {
                                    /*
                                    !session.userInfo.isAdmin &&                                
                                    <Menu.Item key="/marketing">
                                        <Link to="/marketing" >Marketing</Link>
                                    </Menu.Item>
                                    */
                                }                               
                                {
                                    dashboardAnalitycs.map((item) => (
                                        <Menu.Item key={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`}>
                                            <Link to={`/data-studio/${nameToSlug(item.name)}/${linkAdapte(item.url)}`} >{item.name}</Link>
                                        </Menu.Item>
                                    ))
                                }
                            </Menu.SubMenu>
                            {
                                !session.userInfo.isAdmin ?
                                    <Menu.SubMenu key="/onboarding" title={<Link to="/onboarding" ><ProfileOutlined /> Listings</Link>}>
                                        <Menu.Item key="/onboarding">
                                            <Link to="/onboarding" >Tus publicaciones</Link>
                                        </Menu.Item>
                                    </Menu.SubMenu> : <></>
                            }
                            <Menu.SubMenu key="/restock" title={<Link to="/restock" ><DollarCircleOutlined /> Orders</Link>}>
                                <Menu.Item key="/restock">
                                    <Link to="/restock" >Restock</Link>
                                </Menu.Item>
                                <Menu.Item key="/pedidos">
                                    <Link to="/pedidos" >Pedidos</Link>
                                </Menu.Item>
                            </Menu.SubMenu>
                            {/* <Menu.Item key="/lap-academy">
                            <Link to="/lap-academy" ><BankOutlined /> Lap Academy</Link>
                            </Menu.Item>                       */}
                            {session.userInfo.isAdmin ?
                                <>
                                    <Menu.Item key="/clientes">
                                        <Link to="/clientes" ><TeamOutlined /> Clientes</Link>
                                    </Menu.Item>
                                    <Menu.Item key="/status">
                                        <Link to="/status" ><IdcardOutlined /> Estado</Link>
                                    </Menu.Item>
                                </>
                                :
                                <></>
                            }
                            <Menu.SubMenu style={{ marginTop: 80 }} key="#1" title={<Link to="#" >
                                {loading ? (
                                    <Spin indicator={antIcon}><Avatar icon={<UserOutlined />} /> </Spin>
                                ) : (
                                    logo ?
                                        <Avatar style={{ marginRight: 5 }} src={'data:image/png;base64,' + logo
                                        } /> :
                                        <Avatar style={{ marginRight: 5 }} icon={<UserOutlined />} />
                                )}  Mi Perfil</Link>}>
                                {
                                    !session?.userInfo?.isAdmin ?
                                        <>
                                            <Menu.Item key="/my-account">
                                                <Link to="/my-account" ><HomeOutlined /> {t('menu.myAccount')}</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/my-documents">
                                                <Link to="/my-documents" ><FolderOutlined /> Mis Documentos</Link>
                                            </Menu.Item>
                                            <Menu.Item key="/soporte">
                                                <Link to="/soporte" ><MessageOutlined /> Soporte</Link>
                                            </Menu.Item>
                                            <Menu.Item key="" style={{ marginTop: 30 }} onClick={handleLogout}>
                                                <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                            </Menu.Item>
                                        </>
                                        :
                                        <>
                                            <Menu.Item key="/soporte">
                                                <Link to="/soporte" ><MessageOutlined /> Soporte </Link>
                                            </Menu.Item>
                                            <Menu.Item key="" style={{ marginTop: 30 }} onClick={handleLogout}>
                                                <Link><LogoutOutlined /> Cerrar Sesión</Link>
                                            </Menu.Item>                                                
                                        </>
                                }
                            </Menu.SubMenu>
                        </Menu>
                    </div>
            }
        </div>
    )
}

export default MenuOptions