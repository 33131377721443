import React, { useEffect, useState } from 'react';
import { Divider, Typography, Row, Col, Image, Button, Rate, Card } from 'antd';
import { RollbackOutlined } from "@ant-design/icons";
import { marketplaceCurrency } from '../../../../../utils/const';

const { Text, Title } = Typography;

const MeliPreviewModal = ({ draft, marketplace }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [selectedItem, setItem] = useState(draft?.variants?.length > 0 ? draft?.variants[0] : draft?.product);
    const [attributes, setAttributes] = useState([]);

    const setSelectedItem = (itm) => {
        setItem(itm);
        setSelectedImageIndex(0);
    }

    useEffect(() => {
        if (selectedItem?.attributes) {
            let attbs = [];
            for (const key in selectedItem.attributes) {
                if (Object.hasOwnProperty.call(selectedItem.attributes, key)) {
                    const element = selectedItem.attributes[key];
                    let value;
                    if (!element || Object.keys(element)?.length == 0) {
                        continue;
                    }

                    if (element?.unit && element?.value) {
                        value = `${Number(element?.value)} ${element?.unit}`;
                    } else if (element?.id) {
                        value = element.id;
                    } else {
                        value = element;
                    }
                    attbs.push({ name: key, value: value });
                }
            }
            setAttributes(attbs);
        }
    }, [selectedItem])


    return (
        <>
            <Row>
                <Col span={24} style={{ backgroundColor: '#EDEDED', padding: 10 }}>
                    <Row>
                        <Col span={12}>
                            <Text style={{ fontSize: 14, color: 'black' }}> Volver al listado |{' '}</Text>
                            <Text style={{ fontSize: 14, marginLeft: "5px", color: "#3483FA" }}>{draft.category}</Text>
                        </Col>
                        <Col span={12}>
                            <Text style={{ fontSize: 14, marginLeft: "5px", color: "#3483FA", float: 'right' }}>Compartir | Vender uno igual</Text>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row style={{ overflow: 'auto', height: 600 }}>
                <Col span={11} >
                    {draft.variants.length > 0 &&
                        <Row>
                            <Text style={{ fontSize: "18px", marginTop: "10px" }}>Variantes </Text>
                        </Row>
                    }
                    <div className="draft-preview-text-variants-container">
                        {draft.variants?.length > 0 ?
                            draft.variants?.map((variant, index) => (
                                <div style={{ width: 170 }} key={index} className={selectedItem?.id === variant?.id ? "draft-preview-text-variant-card draft-preview-text-variant-card-selected" : "draft-preview-text-variant-card"} onClick={() => setSelectedItem(variant)} >
                                    <Text style={{ width: 100 }} ellipsis={{ tooltip: variant.title }}>{variant.title}</Text>
                                    <Text className="draft-preview-text-variant-card-price" style={{ color: '#3483FA' }}>{`${marketplaceCurrency[marketplace]} ${variant.price}`}</Text>
                                </div>)) :
                            <div style={{ width: 170 }} className={selectedItem?.id === selectedItem?.id ? "draft-preview-text-variant-card draft-preview-text-variant-card-selected" : "draft-preview-text-variant-card"} onClick={() => setSelectedItem(selectedItem)} >
                                <Text style={{ width: 100 }} ellipsis={{ tooltip: selectedItem.title }}>{selectedItem.title}</Text>
                                <Text className="draft-preview-text-variant-card-price" style={{ color: '#3483FA' }}>{`${marketplaceCurrency[marketplace]} ${selectedItem.price}`}</Text>
                            </div>
                        }
                    </div>
                    <Row style={{ width: '100%' }}>
                        <Col style={{ width: "20%", maxHeight: "445px", overflowY: "auto", direction: "rtl", overflowX: "hidden" }}>
                            {[...selectedItem.mainImages, ...selectedItem.productImages].map((image, index) => (
                                <Image preview={false} style={{ width: "100%", border: "1px solid grey", padding: "3px" }} onClick={() => { setSelectedImageIndex(index) }} key={index} src={image.url} />
                            ))}
                        </Col>
                        <Col style={{ alignSelf: "center", marginLeft: "15px", width: '70%' }}>
                            <Image preview={false} style={{ height: "100%", border: "1px solid lightgrey", padding: "3px" }} key={"ImagenSeleccionada"} src={[...selectedItem.mainImages, ...selectedItem.productImages][selectedImageIndex]?.url} />
                        </Col>
                    </Row>
                </Col>
                <Col span={12} style={{ marginLeft: "15px" }}>
                    <Card>
                        <Row>
                            <Col span={24}>
                                <Text style={{ marginLeft: "10px", color: 'gray' }}>Nuevo  |  1365 vendidos</Text>
                            </Col>
                            <Col span={24}>
                                <Title level={3} style={{ marginTop: "10px", textAlign: "left" }}>{selectedItem.attributes.title}</Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Rate disabled defaultValue={4.5} allowHalf style={{ color: '#3483FA', fontSize: 14 }} />
                            </Col>
                            <Col style={{ marginTop: "5px", marginLeft: "15px" }}>
                                <Text style={{ color: '#3483FA' }}> (46)</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: "3px 4px 3px 4px", backgroundColor: "#FF7733", borderRadius: 3 }}>
                                <Text style={{ color: 'white', fontSize: 11 }}> MÁS VENDIDO</Text>
                            </Col>
                            <Col style={{ padding: "3px 4px 3px 4px", backgroundColor: "#E5E5E5", borderRadius: 3, marginLeft: 5 }}>
                                <Text style={{ color: '#3483FA' }}> 6º en {draft.category}</Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                            <Text style={{ color: '#A1A1A1', textDecoration: 'line-through', fontSize: 18 }}> ${Number(selectedItem.price * 1.5).toFixed(2)}</Text>
                        </Row>
                        <Row>
                            <Col style={{ marginLeft: 5 }} span={24}>
                                <Text style={{ fontSize: 34 }}> ${selectedItem.price}</Text>
                            </Col>
                            <Col style={{ marginLeft: 5 }} span={24}>
                                <Text style={{ fontSize: 16 }}> en  <Text style={{ color: '#00A650' }}>6x {Number(selectedItem.price / 6).toFixed(2)} sin interés</Text></Text>
                            </Col>
                            <Col span={24}>
                                <Text style={{ color: '#3483FA' }}>Ver los medios de pago </Text>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 15 }}>
                            <Col span={24}>
                                <Text style={{ color: '#00A650' }}><RollbackOutlined /> Devolución gratis</Text>
                            </Col>
                            <Col span={24} style={{ marginLeft: 15 }}>
                                <Text style={{ color: 'gray' }}>Tienes 30 días desde que lo recibes.</Text>
                            </Col>
                            <Text style={{ color: '#3483FA' }}>Conocer más </Text>
                        </Row>
                        <Divider style={{ margin: "15px 0 10px 0", backgroundColor: "lightgrey" }} />
                        {attributes?.map((attb, index) => (
                            <Row key={index}>
                                <Col>
                                    <Text className="text-capitalize">{attb.name?.toLowerCase()?.replace(/_/g, " ")}:</Text>
                                    <Text strong={true} style={{ marginLeft: 5 }}>{attb?.value}</Text>
                                </Col>
                            </Row>
                        ))}
                    </Card>
                </Col >
            </Row >
        </>
    );
}

export default React.memo(MeliPreviewModal);