import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Typography, Layout, Divider, Button, Spin, Progress } from 'antd';
import DashboardFilter from './components/DashboardFilter';
import DashboardTable from './components/DashboardTable';
import TableStock from './components/TableStock';
import { dateTypes, sellerMarketplaces } from '../../utils/const';
import { BellOutlined, WarningOutlined,CheckCircleOutlined,CloseCircleOutlined,SyncOutlined,CalendarOutlined,ArrowRightOutlined } from '@ant-design/icons';
import { checkProfile, mixPanelCreateEvent } from '../../utils/functions';
import partnerApi from '../../api/partner';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/partner/action';
import actionsAnalitycs from '../../redux/analytic/dashboard/actions';
import { getErrorMessage } from '../../api/api';
import { useTranslation } from 'react-i18next';
import IndexChart from './components/charts/Index';
import selectors from '../../redux/analytic/dashboard/selectors';
import 'antd/dist/antd.css';
import moment from 'moment';
import './home.css';
import { Link, useHistory } from 'react-router-dom';

const { Title } = Typography;

export const Home = () => {
    const dispatch = useDispatch();
    const session = useSelector(store => store.Session.session);
    const loading = useSelector(selectors.selectLoading);
    const loadingProgress = useSelector(selectors.selectLoadingProgress);

    const history = useHistory();
    const { t } = useTranslation();

    const [profileCompleted, setProfileCompleted] = useState(false);
    const [listingCounter, setListingCounter] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [countListings, setCountListings] = useState([]);
    const [loadingProgressForClient, setLoadingProgressForClient] = useState(0);
    const [rangeDateActuality, setRangeDateActuality] = useState([]);

    let partnerId = 1; // admin
    const isAdmin = session.userInfo.isAdmin;
    const canPublish = session.userInfo.x_can_publish;
    if (!isAdmin) {
        partnerId = session.userInfo.commercial_partner_id[0];
    }

    let progress = Math.floor((loadingProgress.progress * 100) / loadingProgress.totalToProcess);

    useEffect(() => {
        let timer;
        if (loadingProgress?.totalToProcess == 1) {
            if (loadingProgressForClient < 90) {
                timer = setTimeout(() => {
                    if (loadingProgressForClient < 90) {
                        setLoadingProgressForClient(loadingProgressForClient + 10);
                    }
                }, 2000);
            }
        }
        return () => clearTimeout(timer);
    }, [loadingProgress, loadingProgressForClient]);

    const searchByPeriodAmount = (m, p) => {
        mixPanelCreateEvent('Action Button', {action: `Filter Summary Dashboard Data ${m} ${p === 'M' ? 'Mes' : p === 'W' ? 'Semana' : ''}`})
        setLoadingProgressForClient(0)
        let ptrs = []
        if (!isAdmin) {
            ptrs = [partnerId];
        }
        const defaultFilters = {
            partner: ptrs,
            sku: [],
            marketplace: Object.values(sellerMarketplaces),
            category: ['sales', 'stock'],
            compare: false,
            dateType: dateTypes.PURCHASE_DATE,
            dateRangeOne: [moment().add(-m, p), moment()],
            dateRangeTwo: [],
            dateType: dateTypes.PURCHASE_DATE,
        };
        let date1 = moment().add(-m, p)
        let date2 = moment()
        setRangeDateActuality([moment(date1).format('L'), moment(date2).format('L')])
        dispatch(actionsAnalitycs.doFetch(defaultFilters));
    }

    const handleAddMarketplace = () => {
        history.push('/my-account?step=2')
    }

    useEffect(() => {
        if (session) {
            setLoadingProfile(true);
            dispatch(Actions.getPartner());
            partnerApi.findById(partnerId)
                .then(res => {
                    if (res.status === 200) {
                        delete res.data.x_product_template_ids;
                        dispatch(Actions.getPartnerSuccess(res.data));
                        try{
                            setProfileCompleted(checkProfile(res.data, session.userInfo))
                        }catch(error) {
                            console.log(error)
                        }
                        setLoadingProfile(false);
                    }
                    else { dispatch(Actions.getPartnerFailed(res.data.message)); }
                })
                .catch(error => {
                    setLoadingProfile(false);
                    dispatch(Actions.getPartnerFailed(getErrorMessage(error)));
                })
            searchByPeriodAmount(1, 'M')
        }
    }, [])

    useEffect(() => {
        if (session?.userInfo?.commercial_partner_id){
            getListingsPerPageInit();
        }
    }, [session])

    const getListingsPerPageInit = async () => {
        const values = {
            partnerId: session?.userInfo?.commercial_partner_id[0]
        }
        partnerApi.getListingsPerPage(values)
            .then(res => {
                if (res.status === 200) {
                    let arrList = res?.data?.data.map(obj => obj.state)
                    let count = {}
                    arrList.forEach(function (x) { count[x] = (count[x] || 0) + 1 })
                    setListingCounter(res.data);
                    setCountListings(count)
                }
                else { console.log(res.data.message) }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="content-div" style={{ backgroundColor: '#f9f9f9' }}>
            <Row>
                <Col xs={24} md={24}  lg={24} xl={24} span={24} style={{ textAlign: 'left' }}>
                    <Card bordered={false} className="intro-card">
                        <Row>
                            <Col xs={24} md={24}  lg={24}>
                                <Title level={3} className="titlePages">Bienvenido {session?.userInfo?.commercial_partner_id[1]}</Title>
                                {!session?.userInfo?.isAdmin &&
                                    <Row>
                                        <Col xs={24}>
                                            <div className="home-info">
                                                <div>
                                                    {(Object.keys(countListings).length > 0) &&
                                                        Object.keys(countListings).map((key,value) => <span className="home-info-text" key='{key}'><BellOutlined /> Tienes <b>{countListings[key]}</b> listings en estado <b>{key}</b></span>)
                                                    }
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                        </Row>
                        <Row className="user-actions">
                        {!session?.userInfo?.isAdmin &&
                            <Col xs={24} md={24}  lg={24}>
                                <Row className='user-messges'>
                                    {profileCompleted.msj1 &&
                                        <span>
                                            {t(profileCompleted.msj1)} {profileCompleted.res === true ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                                        </span>
                                    }
                                    {profileCompleted.msj2 &&
                                        <span>{t(profileCompleted.msj2)} <SyncOutlined /></span>
                                    }
                                </Row>
                            </Col>
                        }
                            <Col xs={24} md={24}  lg={24}>
                                {canPublish && 
                                <Row>
                                    <Link to='/my-account?step=2'>
                                        <Button type='primary' className="add-marketplace-button" onClick={handleAddMarketplace}> Agregar Marketplace</Button>
                                    </Link>
                                </Row>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} md={24}  lg={12} xl={12} span={12} className="hide">
                    <Card bordered={false} style={{ width: '100%', height: '100%' }}>
                        <Row justify="end">
                            <Col >
                                { /*<UpdateDate/>*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} span={24} className="intro-filters">
                            {
                                isAdmin &&
                                <DashboardFilter isAdmin={isAdmin} partnerId={partnerId} />
                            }
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {
                !session.userInfo.isAdmin ?
                    <>
                        <Card className='filtros-meses'>
                            <Row>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(1, 'W')}>1 Semana</Button>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(2, 'W')}>2 Semanas</Button>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(3, 'W')}>3 Semanas</Button>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(1, 'M')}>1 Mes</Button>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(2, 'M')}>2 Meses</Button>
                                <Button style={{ marginLeft: 5 }} onClick={() => searchByPeriodAmount(3, 'M')}>3 Meses</Button>
                                <span style={{ marginLeft: 5 }}><CalendarOutlined /> {`${rangeDateActuality[0]}`} <ArrowRightOutlined /> <CalendarOutlined /> {`${rangeDateActuality[1]}`}</span>
                            </Row>
                        </Card> 
                    </>
                    :
                    <>
                    </>
            }
            {!loading ?
                <IndexChart/>
                :
                <Progress style={{ marginTop: '10%', paddingBottom: '15%' }} type="circle" percent={loadingProgress.totalToProcess > 1 ? progress : loadingProgressForClient} width={80} />
            }
        </div>
    )
}