import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Collapse, Divider, Typography } from 'antd';

const { Panel } = Collapse;
const { Title, Text } = Typography;

const MeliListingPostArticle = () => {
    return (
        <div className="site-card-wrapper">
            <Row style={{ backgroundColor: '#ECECEC' }}>
                <Col span={24} style={{ backgroundColor: '#FFFFFF', textAlign: 'left', padding: 15 }}>
                    <Collapse ghost>
                        <Panel header={"SELLER"} key="seller">
                            <Title level={5} >MENSAJE DE ERROR: seller.unable_to_list</Title>
                            <Text type="secondary">
                                Este error ocurre cuando intentas publicar en una cuenta que no tiene publicaciones creadas.
                                Para solucionarlo debemos realizar la primer publicación desde MercadoLibre. Luego podrás seguir publicando sin problemas.
                            </Text>
                        </Panel>
                        <Panel header={"CATEGORÍA"} key="category">
                            <Title level={5} >MENSAJE DE ERROR: Category ...... only support listing modes: [classified]</Title>
                            <Text type="secondary">
                                Este error aparece cuando el bien o servicio que estas intentando publicar se encuentra entre las siguientes categorías:
                                Autos, motos y otros, inmuebles, servicios.
                                Estas categorías están sujetas a una modalidad de MercadoLibre que el sistema no gestiona.
                                Cómo solucionarlo: En estos casos, si deseas publicar, deberás hacerlo manualmente desde MercadoLibre.
                            </Text>

                        </Panel>
                        <Panel header={"PRECIO"} key="price">
                            <Title level={5} >MENSAJE DE ERROR: No está permitido incrementar el precio de una publicación con ofertas activas</Title>
                            <Text type="secondary">
                                Cuando la publicación que intentas actualizar tiene una campaña de ofertas, no es posible modificar el precio de la publicación por api, debe hacerse a través de MercadoLibre.
                            </Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: Max. decimal precision allowed for currency XXX is 2</Title>
                            <Text type="secondary">Revisar el valor que tenemos en precio, estos no deben tener más de 2 decimales.</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: Free shipping costs exceeds sale</Title>
                            <Text type="secondary">Cuando el precio es muy bajo. Debe incrementar el precio del producto o variante.</Text>
                        </Panel>
                        <Panel header={"TÍTULO"} key="title">
                            <Title level={5} >MENSAJE DE ERROR: La categoría XXX no soporta títulos mayores a 60 caracteres de largo</Title>
                            <Text type="secondary">Este error aparece cuando "título" tiene más de 60 caracteres, ya que excede el límite permitido.</Text>
                        </Panel>
                        <Panel header={"DESCRIPCIÓN"} key="description">
                            <Title level={5} >MENSAJE DE ERROR: item.description.max</Title>
                            <Text type="secondary">
                                El campo descripción es demasiado largo. Generalmente, la cantidad de caracteres en la descripción debe ser menor que 50.000 caracteres.
                            </Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: The text property is deprecated. And will soon be rejected. Please use plain_text</Title>
                            <Text type="secondary">El campo descripción no permite HTML incrustado, sólo TEXTO PLANO</Text>
                        </Panel>
                        <Panel header={"ATRIBUTOS"} key="attributes">
                            <Title level={5} >MENSAJE DE ERROR: item.attributes.missing_required</Title>
                            <Text type="secondary">Este error aparece cuando enviamos atributos obligatorios, para la categoría, sin dato</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: item.listing_type_id.invalid</Title>
                            <Text type="secondary">Este error aparece cuando el tipo de publicación no es válido</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: Mandatory free shipping added</Title>
                            <Text type="secondary">Este error aparece cuando antes de confirmar la publicación modificamos el método de envío de Mercado Libre. Sucede que hay muchas publicaciones que al crearlas por API por la categoría o el importe Meli no nos permite modificar el método de envío.
                                Se recomienda no modificar el método de envío al subir el excel al publicar. Recuerde que una vez publicado el artículo puede modificar el método de envío siguiendo los pasos del tutorial.
                            </Text>
                        </Panel>
                        <Panel header={"VARIANTES"} key="variations">
                            <Title level={5} >MENSAJE DE ERROR: Peylod contains more than one variontins with the same attributes in attribute combinations</Title>
                            <Text type="secondary">
                                Este error aparece porque en el mismo artículo hay atributos que se repiten. Puede ser que haya dos variantes con el mismo color a con el mismo talle.
                                MercadoLibre no permite publicar con variantes repetidas en una misma publicación.
                            </Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: No es posible publicar variantes con diferente combinación de atributos.</Title>
                            <Text type="secondary">
                                Este error aparece cuando estableces atributos distintos entre las variantes. Siempre deben ser los mismos atributos con distintos valores cuando la publicación tiene variantes.
                            </Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: You cannot create a variation without attribute_combinations.</Title>
                            <Text type="secondary">
                                Este error aparece cuando queremos subir una variante/producto con ain atributos.</Text>

                        </Panel>
                        <Panel header={"IMÁGENES"} key="images">
                            <Title level={5} >MENSAJE DE ERROR: item.listing_type_id.requiresPictures</Title>
                            <Text type="secondary">Las imágenes son obligatorias.</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: item.pictures.max</Title>
                            <Text type="secondary">Cantidad de imágenes superada.</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: Item pictures are mandatory for listing type gold_pro</Title>
                            <Text type="secondary">
                                Este error ocurre cuando se está intentando actualizar las imágenes de las publicaciones que tienen variantes, como ser: color, talle, etc.
                                El publicador no permite realizar esta actualización ya que cada imagen está asociada a una variante.
                                En estos casos para poder modificar las imágenes de las publicaciones deberás hacerlo de forma manual desde MercadoLibre.
                            </Text>
                        </Panel>
                        <Panel header={"STOCK"} key="stock">
                            <Title level={5} >MENSAJE DE ERROR: La cantidad de stock debe ser mayor a 0</Title>
                            <Text type="secondary">Algunas categorías exigen tener stock mayor a 0 unidades.</Text>
                            <Divider />
                        </Panel>
                        <Panel header={"ORDERS"} key="orders">
                            <Title level={5} >MENSAJE DE ERROR: order_not_found</Title>
                            <Text type="secondary">No se puede encontrar una orden específica.</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: empty_order_id</Title>
                            <Text type="secondary">	El ID de la orden no puede estar vacío.</Text>
                            <Divider />
                            <Title level={5} >MENSAJE DE ERROR: not_owned_order</Title>
                            <Text type="secondary">	Para ver un orden, debes utilizar un ID del vendedor o del comprador.</Text>
                            <Divider />
                        </Panel>
                        <Panel header={"OTROS"} key="others">
                            <Title level={5} >MENSAJE DE ERROR: Mandatory free shipping added</Title>
                            <Text type="secondary">
                                Este error aparece cuando antes de confirmar la publicación modificamos el método de envío en Mercado Libre.
                                Sucede que hay muchas publicaciones que al crearlas por API por la categoría o el importe Meli no nos permite modificar el método de envío.
                                Se recomienda no modificar el método de envío al subir el excel al publicar.
                                Recuerde que una vez publicado el artículo puede modificar el método de envío
                            </Text>
                            <Divider />
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
        </div>
    )
}

export default MeliListingPostArticle;