import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import actionsAnalitycs from '../../../../redux/analytic/dashboard/actions';
import { Row, Col, Card, Typography, Button, Layout } from 'antd';
import TopFiveSalesTable from './TopFiveSalesTable';
import { useTranslation } from 'react-i18next';
import TopSalesPieChartBySku from './TopSalesPieChartBySku';
import SalesPieChartByMarketplace from './SalesPieChartByMarketplace';
import SalesByMarketplaceAndPurchaseDate from './SalesPerMarketplace';
import DashboardTable from '../DashboardTable';
import TableStock from '../TableStock';
import StockData from './ProductStockData';
import orderApi from '../../../../api/order';
import dashboardApi from '../../../../api/dashboard';
import { openNotification } from '../../../../components/Toastr';
import { getErrorMessage } from '../../../../api/api';
import 'antd/dist/antd.css';
import { thousandsSeparator } from '../../../../utils/functions';
import { dateTypes } from '../../../../utils/const';
const { Title } = Typography;

const Index = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const session = useSelector(store => store.Session.session);
    const rows = useSelector(selectors.selectRows);
    const rawfilter = useSelector(selectors.selectRawFilter)
    const rowsStock = useSelector(selectors.selectRowsStock);
    const [totalVentas, setTotalVentas] = useState(0)
    const [unidadesVendidas, setUnidadesVendidas] = useState(0)
    const [porcentajeStockDisponible, setPorcentajeStockDisponible] = useState(0)
    const [dataStockProductFBA, setDataStockProductFBA] = useState([])
    const [dataStockProductFBM, setDataStockProductFBM] = useState([])
    const [titleSalesGraphic, setTitleSalesGraphic] = useState('')
    const [toBack, setToBack] = useState(false);
    const [stockAndRestock, setStockAndRestock] = useState([]);
    const [dataClients, setDataClients] = useState([]);
    const [orderRestock, setOrdersRestock] = useState([]);
    const [meta, setMeta] = useState({});
    const [dataMarketplace, setDataMarketplace] = useState([]);
    const [dataSalesPerMarketplace, setDataSalesPerMarketplace] = useState([]);
    const [dataTopFiveSalesTable, setDataTopFiveSalesTable] = useState([]);
    const [dataTopSalesSkuAndStock, setDataTopSalesSkuAndStock] = useState([]);
    const [dataTopSalesMarketplaceAndStock, setDataTopSalesMarketplaceAndStock] = useState([]);
    const objectQuiebreStock = []
    const allProductsQuiebreStock = []
    const quiebreStockAndRestock = []
    const dClients = []
    const valuesMainA = []
    const valuesMainB = []
    const salesPerMarketplace = []
    const topFiveSalesTable = []
    const topSalesSkuAndStock = []
    const topSalesMarketplaceAndStock = []
    const todaslasUnidadesVendidas = []
    const cantProductsStock = []
    const cantProductsStockAvailable = []
    let dataTemp = []
    let stockDataFBA = []
    let stockDataFBM = []
    let contI = 0
    const reducer = (accumulator, curr) => accumulator + curr;

    const columnsModalStockFbaAndFbm = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'defaultCode',
            key: 'defaultCode',
        },
        {
            title: 'Dias sin Stock',
            dataIndex: 'daysOutStock',
            key: 'daysOutStock'
        }
    ];

    const columnsMainModalStockFbaAndFbm = [
        {
            title: 'Client',
            dataIndex: 'title',
            key: 'title',
        }
    ];

    const searchDataForM = (m, p) => {
        let ptrs = []
        ptrs = rawfilter.partner
        const defaultFilters = {
            partner: ptrs,
            sku: [],
            marketplace: rawfilter.marketplace,
            category: ['sales', 'stock'],
            compare: false,
            dateRangeOne: [moment().add(-m, p), moment()],
            dateRangeTwo: [],
            dateType: rawfilter.dateType || dateTypes.PURCHASE_DATE,
        };
        dispatch(actionsAnalitycs.doFetchStock(defaultFilters));
        quiebreStock()
    }

    useEffect(() => {
        if(!session.userInfo.isAdmin){
            getAvailableStockPerPercent()
            getUnidadesDeVentas()
        }
    }, [unidadesVendidas])

    useEffect(() => {
        onChangeRestockPage()
        quiebreStock()
        const ventasClientesAdmin = []
        if (!session?.userInfo?.isAdmin) {
            if (rows?.length > 0 && Array.isArray(rows[0].children)) {
                dClients.push(rows[0])
                ventasClientesAdmin.push(rows[0].sumTotalSoldUSD)
                if (rows[0]?.children?.length > 0) {
                    for (const marketplace of rows[0].children) {
                        if (marketplace?.children?.length > 0) {
                            topSalesMarketplaceAndStock.push({
                                value: marketplace?.sumTotalSoldUSD,
                                valueMkp: marketplace?.sumTotalSold,
                                currency: marketplace?.currency,
                                type: marketplace?.name,
                            });
                            for (const purchaseDate of Object.keys(marketplace?.salesByPurchaseDateUSD)) {
                                let yearAndMonth = purchaseDate.slice(0,7)
                                let montos = []
                                let data = Object.keys(marketplace?.salesByPurchaseDateUSD).filter((e) => {
                                    let date = e.slice(0,7)
                                    if(yearAndMonth === date){
                                        montos.push(marketplace.salesByPurchaseDateUSD[e])
                                    }
                                })
                                salesPerMarketplace.push({
                                    purchaseDate: yearAndMonth,
                                    daysInMonths: purchaseDate,
                                    nameClient: dClients[0].name,
                                    marketplace: marketplace.name,
                                    currency: marketplace.currency,
                                    totalSaleUSD: Math.round(montos.reduce(reducer) * 100) / 100,
                                    totalSaleMkp: marketplace.salesByPurchaseDate[purchaseDate],
                                    id: 1,
                                    status: false
                                });
                            }
                            for (const listing of marketplace?.children) {
                                if (listing?.children?.length > 0) {
                                    for (const product of listing?.children) {
                                        topFiveSalesTable.push({
                                            title: product.title,
                                            defaultCode: product.defaultCode,
                                            marketplace: product.marketplace,
                                            sumQuantitySold: product.sumTotalSoldUSD
                                        })
                                        topSalesSkuAndStock.push({
                                            value: product.sumTotalSoldUSD,
                                            valueMkp: product.sumTotalSold,
                                            currency: product.marketplaceCurrency,
                                            type: `${product.defaultCode} - ${product.marketplace}`,
                                        });
                                    }
                                } else {
                                    topFiveSalesTable.push({
                                        title: listing.title,
                                        defaultCode: listing.defaultCode,
                                        marketplace: listing.marketplace,
                                        sumQuantitySold: listing.sumTotalSoldUSD
                                    });
                                    topSalesSkuAndStock.push({
                                        value: listing.sumTotalSoldUSD,
                                        valueMkp: listing.sumTotalSold,
                                        currency: listing.marketplaceCurrency,
                                        type: `${listing.defaultCode} - ${listing.marketplace}`,
                                    });
                                }
                            }
                            setDataMarketplace(salesPerMarketplace)
                            setDataTopSalesSkuAndStock(topSalesSkuAndStock?.sort((a, b) => { return b.value - a.value })?.slice(0, 9));
                            setDataTopFiveSalesTable(topFiveSalesTable?.sort((a, b) => { return b.sumQuantitySold - a.sumQuantitySold })?.slice(0, 10));
                        }
                        setDataTopSalesMarketplaceAndStock(topSalesMarketplaceAndStock)
                    }
                    //setDataSalesPerMarketplace(salesPerMarketplace.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) }))
                }
            }
        } else {
            let i = 0
            if (rows?.length > 0) {
                for (const clientFather of rows) {
                    ventasClientesAdmin.push(clientFather.sumTotalSoldUSD)
                    if (rows[i]?.children?.length > 0) {
                        dClients.push(clientFather)
                        salesPerMarketplace.push({
                            purchaseDate: clientFather.name,
                            marketplace: clientFather.name,
                            nameClient: clientFather.name,
                            currency: 'USD',
                            id: 0,
                            totalSaleUSD: clientFather.sumTotalSoldUSD,
                            totalSaleMkp: '',
                        });
                        topFiveSalesTable.push({
                            title: clientFather.name,
                            defaultCode: clientFather.name,
                            marketplace: '',
                            sumQuantitySold: clientFather.sumTotalSoldUSD
                        });                        
                        for (const marketplace of rows[i].children) {
                            if (marketplace?.children?.length > 0) {
                                unidVendidas(marketplace)
                                topSalesMarketplaceAndStock.push({
                                    value: marketplace?.sumTotalSoldUSD,
                                    valueMkp: marketplace?.sumTotalSold,
                                    currency: marketplace?.currency,
                                    type: `${marketplace?.name} - ${clientFather.name}`,
                                });                                
                                for (const listing of marketplace?.children) {
                                    if (listing?.children?.length > 0) {
                                        for (const product of listing?.children) {
                                            cantProductsStock.push(product.defaultCode)
                                            if(product.inventory?.availableStock > 0){
                                                cantProductsStockAvailable.push(product.inventory?.availableStock)
                                            }                                            
                                            if (product?.inventory && product.fulfillmentType != 'FBM') {
                                                topSalesSkuAndStock.push({
                                                    value: product.sumTotalSoldUSD,
                                                    valueMkp: product.sumTotalSold,
                                                    currency: product.marketplaceCurrency,
                                                    type: `${product.defaultCode} - ${product.marketplace} - ${clientFather.name}`,
                                                });                                                
                                                if (product?.inventory?.availableStock === 0) {
                                                    stockDataFBA.push({
                                                        name: product.title,
                                                        value: product.inventory.availableStock,
                                                        valueMkp: product.inventory.availableStock,
                                                        defaultCode: product.defaultCode,
                                                        daysOutStock: product.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                    });                                                  
                                                }
                                            } else {
                                                if (product?.inventory?.availableStock === 0) {
                                                    stockDataFBM.push({
                                                        name: product.title,
                                                        value: product.inventory.availableStock,
                                                        valueMkp: product.inventory.availableStock,
                                                        defaultCode: product.defaultCode,
                                                        daysOutStock: product.inventory.daysOutStock,
                                                        currency: '',
                                                        type: `${product.inventory.availableStock} - ${product.marketplace}`,
                                                    });
                                                }
                                            }
                                        }
                                    } else {
                                        cantProductsStock.push(listing.defaultCode)
                                        if(listing.inventory?.availableStock > 0){
                                            cantProductsStockAvailable.push(listing.inventory?.availableStock)
                                        }                                        
                                        topSalesSkuAndStock.push({
                                            value: listing.sumTotalSoldUSD,
                                            valueMkp: listing.sumTotalSold,
                                            currency: listing.marketplaceCurrency,
                                            type: `${listing.defaultCode} - ${listing.marketplace} - ${clientFather.name}`,
                                        });                                        
                                        if (listing?.inventory && listing.fulfillmentType != 'FBM') {
                                            if (listing?.inventory?.availableStock === 0) {
                                                stockDataFBA.push({
                                                    name: listing.title,
                                                    value: listing.inventory.availableStock,
                                                    valueMkp: listing.inventory.availableStock,
                                                    defaultCode: listing.defaultCode,
                                                    daysOutStock: listing.inventory.daysOutStock,
                                                    currency: '',
                                                    type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                });
                                            }
                                        } else {
                                            if (listing?.inventory?.availableStock === 0) {
                                                stockDataFBM.push({
                                                    name: listing.title,
                                                    value: listing.inventory.availableStock,
                                                    valueMkp: listing.inventory.availableStock,
                                                    defaultCode: listing.defaultCode,
                                                    daysOutStock: listing.inventory.daysOutStock,
                                                    currency: '',
                                                    type: `${listing.inventory.availableStock} - ${listing.marketplace}`,
                                                });
                                            }
                                        }                                       
                                    }
                                }
                                setDataTopSalesMarketplaceAndStock(topSalesMarketplaceAndStock)
                                setDataTopSalesSkuAndStock(topSalesSkuAndStock?.sort((a, b) => { return b.value - a.value })?.slice(0, 5));
                            }
                        }
                    }
                    setDataClients(salesPerMarketplace)
                    setDataTopFiveSalesTable(topFiveSalesTable?.sort((a, b) => { return b.sumQuantitySold - a.sumQuantitySold })?.slice(0, 10));
                    setDataSalesPerMarketplace(salesPerMarketplace.sort((a, b) => a.totalSaleUSD - b.totalSaleUSD));
                    if (stockDataFBA.length > 0) {
                        valuesMainA.push({
                            key: i,
                            title: clientFather.name,
                            children: stockDataFBA
                        });
                    }
                    if (stockDataFBM.length > 0) {
                        valuesMainB.push({
                            key: i,
                            title: clientFather.name,
                            children: stockDataFBM
                        });
                    }
                    stockDataFBA = []
                    stockDataFBM = []
                    i++
                }
            }
        }
        if(ventasClientesAdmin.length > 1) {
            setTotalVentas(Math.round(ventasClientesAdmin?.reduce(reducer) * 100) / 100)
        } else {
            setTotalVentas(ventasClientesAdmin[0])
        }
        setDataStockProductFBA(valuesMainA)
        setDataStockProductFBM(valuesMainB)
        setPorcentajeStockDisponible(cantProductsStockAvailable.length*100/cantProductsStock.length)
        if(!session.userInfo.isAdmin) {
            let arrayDates = []
            salesPerMarketplace?.map((date) => {
                let dateAvailableInArray = arrayDates?.find((e) => e.purchaseDate === date.purchaseDate)
                if(!dateAvailableInArray){
                    arrayDates.push(date)
                }
            })
            let arrayOrder = arrayDates?.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) })
            arrayOrder?.map((dateAvailable) => {
                if(dateAvailable){
                    return buildObjectSelesPerDayInMonth(dateAvailable)
                }
            })
        }
    }, [rows])

    const buildObjectProductsBySKU = (saleInMonth) => {
        setToBack(!saleInMonth.status)
        setTitleSalesGraphic(`${saleInMonth.nameClient} [${saleInMonth.purchaseDate}]`)
        let marketplaceData = []
        const captureDates = (data) => {
            for (const purchaseDate of Object.keys(data?.salesByPurchaseDateUSD)) { 
                if(saleInMonth.purchaseDate === purchaseDate){
                    if(data?.salesByPurchaseDateUSD[purchaseDate] > 0){
                        marketplaceData.push({
                            ...saleInMonth,
                            marketplace: data.marketplace,
                            daysInMonths: purchaseDate,
                            purchaseDate: data.defaultCode,
                            totalSaleUSD: data?.salesByPurchaseDateUSD[purchaseDate],
                            id: 5,
                            status: true
                        })
                    }
                }
            }
        }

        let data = dClients.map((e) => {
            if (e.name === saleInMonth.nameClient) { 
                for (const marketplace of e.children) {
                    if (marketplace?.children?.length > 0) {
                        for (const listing of marketplace?.children) {
                            if (listing?.children?.length > 0) {
                                for (const product of listing?.children) {
                                    captureDates(product)
                                }
                            } else {
                                captureDates(listing)
                            }
                        }
                    }
                }
            }
        })
        setDataSalesPerMarketplace(marketplaceData.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) }))
        marketplaceData = []
    }

    const buildObjectSelesPerDayInMonth = (saleInMonth) => {
        let marketplace = []
        setTitleSalesGraphic(`${saleInMonth?.nameClient} [${saleInMonth?.purchaseDate}]`)
        const salesOfTheDay = (data) => {
            for (const purchaseDate of Object.keys(data?.salesByPurchaseDateUSD)) {                   
                let mesData = purchaseDate.split('-')
                let mesSelected = saleInMonth.daysInMonths.split('-')
                if(mesData[1] === mesSelected[1]) {
                    if(data.salesByPurchaseDate[purchaseDate] > 0) {
                        marketplace.push({
                            ...saleInMonth,
                            purchaseDate,
                            marketplace: data.name,
                            totalSaleUSD: data.salesByPurchaseDateUSD[purchaseDate],
                            totalSaleMkp: data.salesByPurchaseDate[purchaseDate],
                            id: 2,
                            status: false
                        });
                    }
                }
            }            
        } 

        let data = dClients.map((e) => {
            if (e.name === saleInMonth?.nameClient) { 
                for (const marketplace of e.children) {
                    if (marketplace?.children?.length > 0) {
                        salesOfTheDay(marketplace)
                    }              
                }
            }
        })
        setDataSalesPerMarketplace(marketplace.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) }))
        marketplace = []
    }


    const buildObjectSelesPerMonth = (clientElement) => {
        let marketplaceElement = []
        setTitleSalesGraphic(clientElement?.nameClient)
        let data = dClients.find((client) => client.name === clientElement?.nameClient)
        if(data){
            for (const market of data?.children) {
                for (const purchaseDate of Object.keys(market?.salesByPurchaseDateUSD)) {
                    let yearAndMonth = purchaseDate.slice(0,7)
                    let montos2 = []
                    let dataDates = Object.keys(market?.salesByPurchaseDateUSD).map((element) => {
                        let date = element.slice(0,7)
                        if(yearAndMonth === date){
                            montos2.push(market.salesByPurchaseDateUSD[element])

                        }
                    })
                    marketplaceElement.push({
                        purchaseDate: yearAndMonth,
                        daysInMonths: purchaseDate,
                        nameClient: clientElement.nameClient,
                        marketplace: market.name,
                        currency: market.currency,
                        totalSaleUSD: Math.round(montos2.reduce(reducer) * 100) / 100,
                        totalSaleMkp: market.salesByPurchaseDate[purchaseDate],
                        id: 1
                    }); 
                }
            }
        }
        setDataSalesPerMarketplace(marketplaceElement.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) }))
    }

    const buildObjectClients = (data) => {
        if(data.id === 0) {
            buildObjectSelesPerMonth(data)
        } else if (data.id === 1) {
            buildObjectSelesPerDayInMonth(data)
        } else if (data.id === 2) {
            buildObjectProductsBySKU(data)
        } else {
            changeDataChart()
        }
    }

    const quiebreStock = async () => {
        let allProductsSku = []
        if (rowsStock?.length > 0 && Array.isArray(rowsStock[0].children)) {
            dClients.push(rowsStock[0])
            if (rowsStock[0]?.children?.length > 0) {
                for (const marketplace of rowsStock[0].children) {
                    if (marketplace?.children?.length > 0) {

                        for (const listing of marketplace?.children) {
                            if (listing?.children?.length > 0) {
                                for (const product of listing?.children) {
                                    for (const purchaseDate of Object.keys(product?.quantityByPurchaseDate)) {
                                        let yearAndMonth = purchaseDate.slice(0,7)
                                        let quantity = []
                                        let obj = {}
                                        let data = Object.keys(product?.quantityByPurchaseDate).filter((e) => {
                                            let date = e.slice(0,7)
                                            if(yearAndMonth === date){
                                                obj = {
                                                    date: date
                                                }
                                                quantity.push(product.quantityByPurchaseDate[e])
                                            }
                                        })
                                        let existeElement = objectQuiebreStock.filter((e) => e.date === yearAndMonth && e.product === product.defaultCode && e.marketplace === listing.marketplace)
                                        if(existeElement.length === 0){
                                            objectQuiebreStock.push({
                                                date: yearAndMonth,
                                                product: product.defaultCode,
                                                marketplace: product.marketplace,
                                                stockTotal: Math.round(quantity.reduce(reducer) * 100) / 100,
                                            })
                                            allProductsSku.push({
                                                product: product.defaultCode,
                                                marketplace: product.marketplace,
                                                availableStock: product.inventory?.availableStock
                                            })
                                        }
                                    }
                                }                       
                            } else {
                                for (const purchaseDate of Object.keys(listing?.quantityByPurchaseDate)) {
                                    let yearAndMonth = purchaseDate.slice(0,7)
                                    let quantity = []
                                    let obj = {}
                                    let data = Object.keys(listing?.quantityByPurchaseDate).filter((e) => {
                                        let date = e.slice(0,7)
                                        if(yearAndMonth === date){
                                            obj = {
                                                date: date
                                            }
                                            quantity.push(listing.quantityByPurchaseDate[e])
                                        }
                                    })
                                    let existeElement = objectQuiebreStock.filter((e) => e.date === yearAndMonth && e.product === listing.defaultCode && e.marketplace === listing.marketplace)
                                    if(existeElement.length === 0){
                                        objectQuiebreStock.push({
                                            date: yearAndMonth,
                                            product: listing.defaultCode,
                                            marketplace: listing.marketplace,
                                            stockTotal: Math.round(quantity.reduce(reducer) * 100) / 100,
                                        })
                                        allProductsSku.push({
                                            product: listing.defaultCode,
                                            marketplace: listing.marketplace,
                                            availableStock: listing.inventory?.availableStock
                                        }) 
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        let arrayStockTotal = allProductsSku.map((sku) => {
            let stockByProduct = []
            let productActual = objectQuiebreStock.map((p) => {
                if(p.product === sku.product) {
                    stockByProduct.push(p.stockTotal)
                }
            })
            let exisProduct = allProductsQuiebreStock.filter((e) => e.product === sku.product && e.marketplace === sku.marketplace)
            if(exisProduct.length < 1) {
                let suma = Math.round(stockByProduct.reduce(reducer) * 100) / 100
                allProductsQuiebreStock.push({
                    product: sku.product,
                    marketplace: sku.marketplace,
                    availableStock: sku.availableStock,
                    averageStock: Math.round(suma / 3)
                })
            }
        })
        if(orderRestock){
            allProductsQuiebreStock.map((product) => {
                let exitRestock = {}
                orderRestock?.map((order) => {
                    let exitProduct = order?.products?.find((e) => product.product === e.product.defaultCode && product.marketplace === e.product.marketplace)
                    if(exitProduct) {
                        exitRestock = {
                            quantity: exitProduct.quantity,
                            shippingType: order.shippingType,
                            state: order.state
                        }
                    }
                })
                if(exitRestock){
                    quiebreStockAndRestock.push({
                        ...product,
                        restock: exitRestock
                    })
                } else {
                    quiebreStockAndRestock.push({
                        ...product,
                        restock: {}
                    })
                }

            })

        }
        setStockAndRestock(quiebreStockAndRestock)
    }

    const getUnidadesDeVentas = async () => {
        await dashboardApi.soldUnits(rawfilter).then((response) => {
            setUnidadesVendidas(response?.data)
        }).catch((error) => {
            openNotification({ status: false, content: getErrorMessage(error) });
        });
    }

    const getAvailableStockPerPercent = async () => {
        await dashboardApi.availableStock(rawfilter).then((response) => {
            setPorcentajeStockDisponible(response?.data)
        }).catch((error) => {
            openNotification({ status: false, content: getErrorMessage(error) });
        });
    }    

    const onChangeRestockPage = async (page) => {

        if (session) {
            const values = {
                partnerId: session.userInfo.isAdmin ? null : session.userInfo.commercial_partner_id[0],
                page: page,
                take: 50
            }
            await orderApi.getOrdersPerPage(values)
                .then((response) => {
                    setOrdersRestock(response.data.data);
                    setMeta(response.data.meta)
                    return response.data.data
                })
                .catch((error) => {
                    //openNotification({ status: false, content: getErrorMessage(error) });
                });
        }
    }    

    const unidVendidas = (mkp) => {
        let sumaCant = Math.round(Object.values(mkp?.quantityByPurchaseDate).reduce(reducer) * 100) / 100
        todaslasUnidadesVendidas.push(sumaCant)
        if(todaslasUnidadesVendidas.length > 1){
            setUnidadesVendidas(Math.round(todaslasUnidadesVendidas.reduce(reducer) * 100) / 100)
        } else {
            setUnidadesVendidas(todaslasUnidadesVendidas[0])
        }
    }

    const changeDataChart = () => {
        setTitleSalesGraphic('')
        if (!session.userInfo.isAdmin) {
            setToBack(dataMarketplace[0]?.status)
            setDataSalesPerMarketplace(dataMarketplace.sort(function (a, b) { return new Date(a.purchaseDate) - new Date(b.purchaseDate) }))
        } else {
            setToBack(false)
            setDataSalesPerMarketplace(dataClients)
        }
    }

    return (
        <>
            <Card>
                <Row className='intro-totales'>
                    <Col xs={24} md={24} lg={8} xl={8} span={8}>
                        <Card bordered={false} style={{ marginRight: 5}}>
                            <Title level={4}>Ventas Totales (USD)</Title>
                            <Title style={{ color: '#04dfa0' }} level={1}><center>${totalVentas > 0 ? thousandsSeparator(totalVentas) : 0}</center></Title>
                        </Card>
                    </Col>           
                    <Col xs={24} md={24} lg={8} xl={8} span={8}>
                        <Card bordered={false} style={{ marginRight: 5}}>
                            <Title level={4}>Unidades Vendidas</Title>
                            <Title style={{ color: '#010c33' }} level={1}><center>{unidadesVendidas ? thousandsSeparator(unidadesVendidas) : 0}</center></Title>
                        </Card>
                    </Col>
                    <Col xs={24} md={24} lg={8} xl={8} span={8}>
                        <Card bordered={false}>
                            <Title level={4}>Stock Disponible</Title>
                            <Title style={{ color: '#04dfa0' }} level={1}><center>{porcentajeStockDisponible ? porcentajeStockDisponible : 0}%</center></Title>
                        </Card>                 
                    </Col>
                </Row>                      
                <Row className='titulo' style={{ textAlign: 'left' }}>
                    <Col span={24}>
                        <Card bordered={false} style={{ width: '100%', marginTop: 5 }}>
                            <Title level={4}>Resumen de ventas {titleSalesGraphic && dataMarketplace.length > 0 ? `- ${titleSalesGraphic}` : dataMarketplace.length > 0 ? '' : `(${t('home.charts.msjNoSales')})`}</Title>
                            {
                                dataMarketplace.length > 0 ?
                                    dataSalesPerMarketplace[0]?.id === 2 ?
                                        <Button onClick={changeDataChart}>{!session.userInfo.isAdmin ? t('home.charts.salePerMonth') : t('home.charts.salePerCustomer')}</Button>
                                    :
                                        dataSalesPerMarketplace[0]?.id === 5 ?
                                            <Button onClick={changeDataChart}>{!session.userInfo.isAdmin ? t('home.charts.salePerMonth') : t('home.charts.salePerCustomer')}</Button>
                                        :
                                            dataSalesPerMarketplace.length < 1 ?
                                                <Button onClick={changeDataChart}>{!session.userInfo.isAdmin ? t('home.charts.salePerMonth') : t('home.charts.salePerCustomer')}</Button>
                                            :
                                                <></>
                                :
                                    <></>

                            }
                            <SalesByMarketplaceAndPurchaseDate
                                dataSource={dataSalesPerMarketplace}
                                clients={buildObjectClients}
                                changeData={changeDataChart} />
                        </Card>
                    </Col>
                </Row>
                <Row className='bot-3-cards' style={{ textAlign: 'left' }}>
                    <Col xs={24} md={24} lg={12} xl={12} span={12} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Card className='cardTableDashboard' bordered={false} style={{marginRight: 5}}>
                            <Title level={4}>Top 5 ventas</Title>
                            <TopFiveSalesTable dataSource={dataTopFiveSalesTable} />
                        </Card>
                    </Col>
                    <Col xs={24} md={24} lg={12} xl={12} span={12}>
                        <Card bordered={false}>
                            <Title level={4}>Ventas por marketplace</Title>
                            <SalesPieChartByMarketplace dataSource={dataTopSalesMarketplaceAndStock} />
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default Index