import React from 'react';
import { Column } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { Collapse, Row, Col, Typography, Space } from 'antd';
import selectors from '../../../../redux/analytic/dashboard/selectors';
import { thousandsSeparator } from '../../../../utils/functions';

const { Panel } = Collapse;
const { Text } = Typography;

const SalesByMarketplaceAndPurchaseDate = ({ dataSource, clients }) => {

    const rows = useSelector(selectors.selectRows);
    const currentDate = new Date();
    const monthsLastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const config = {
        data: dataSource,
        height: 200,
        theme: {
            colors10: [
                '#010C33',
                '#00E5A6',
                '#FFC100',
                '#9FB40F',
                '#76523B',
                '#DAD5B5',
                '#0E8E89',
                '#E19348',
                '#F383A2',
                '#247FEA',
            ]
        },
        xField: 'purchaseDate',
        yField: 'totalSaleUSD',
        seriesField: 'marketplace',
        yAxis: {
            label: {
                formatter: (v) => `${v} USD`,
            }
        },
        tooltip: {
          formatter: (datum) => {
            return { name: datum.marketplace, value: '$' + thousandsSeparator(datum.totalSaleUSD) };
          }
        },
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        animation: {
            appear: {
                animation: 'path-in',
                duration: 10000,
            },
        },
    };

    const locale = {
        'en': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        'es': ['Enero', 'Febrero', 'Marzo', 'Abrl', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    };

    const getMonthName = (lang, date) => {
        lang = lang && (lang in locale) ? lang : 'en';
        return locale[lang][date.getMonth()];
    };

    const getConversionDate = (date) => {
        const conversionDate = date && (date != monthsLastDay.toISOString().split('T')[0]) ? date : 'hoy';
        return conversionDate;
    };

    return <Row>
        <Col span={24}>
            <Collapse ghost>
                <Panel header={<Text>Ver valores de conversión</Text>} key="1" ghost>
                    <Row>
                        <Space size={'small'}>
                            {rows && rows[0]?.currencyValues
                                && Object.keys(rows[0]?.currencyValues)?.map((currencyByDate, index) => (
                                    <Col key={index}>
                                        <Row>
                                            <Col span={24}>
                                                Mes: {getMonthName('es', new Date(currencyByDate))}
                                            </Col>
                                            <Col span={24}>
                                                Valor del día: {getConversionDate(currencyByDate)}
                                            </Col>
                                            <Col span={24} >
                                                {Object.values(rows[0]?.currencyValues[currencyByDate]?.data)?.map((currency, index) => (
                                                    <Col key={index}><Text>1 USD = {currency?.value} {currency?.code}</Text><br /></Col>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                ))}
                        </Space>
                    </Row>
                </Panel>
            </Collapse>
        </Col>
        <Col span={24}>
            <Column {...config} onReady={ (plot) => {
                  plot.on('element:click', (evt) => {
                    if(evt.data) {
                        if(!evt.data?.data?.status) {
                            clients(evt.data?.data)
                        }
                    }
                  })
                }} />
        </Col>
    </Row>
};
export default React.memo(SalesByMarketplaceAndPurchaseDate);
