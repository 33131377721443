import { ApiOutlined, CloseCircleOutlined, PlusOutlined, RedoOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Popconfirm, Row, Select, Space, Spin, Tooltip, Typography } from "antd";
import ip from 'ip';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import xlsx from "xlsx";
import { getErrorMessage } from "../../../api/api";
import listingApi from "../../../api/listing";
import partnerApi from "../../../api/partner";
import { openNotification } from "../../../components/Toastr";
import dashboardSelectors from "../../../redux/analytic/dashboard/selectors";
import { mixPanelCreateEvent } from '../../../utils/functions';
import { TableListing } from "./TableListing";

const antIcon = <RedoOutlined spin />;
const { Text, Link } = Typography;

const Listing = ({ profileCompleted }) => {
  const [loadingCreateListing, setLoadingCreateListing] = useState(false);
  const [getListings, setGetListings] = useState(false);
  const [loadingGetListingsFromMarketplaces, setLoadingGetListingsFromMarketplaces] = useState();
  const [loadingSyncStock, setLoadingSyncStock] = useState(false);
  const [loadingUnlinkMatchesFBA, setLoadingUnlinkMatchesFBA] = useState(false);
  const [syncStatus, setSyncStatus] = useState();
  const [categoriesNotHomologated, setCategoriesNotHomologated] = useState(0);
  const history = useHistory();
  const { t } = useTranslation()

  const session = useSelector((store) => store.Session.session);
  const marketplacesByClient = useSelector(dashboardSelectors.selectListMarketplace);

  useEffect(() => {
    getSyncStatus();
    getCategoriesHomologated();
  }, []);

  const getCategoriesHomologated = async () => {
    try {
      const { data } = await partnerApi.getCategoriesHomologated(session.userInfo.commercial_partner_id[0]);
      setCategoriesNotHomologated(data?.filter(category => !category.categoryDestination || !category.productTypeDestination)?.length);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) })
    }
  }

  const getSyncStatus = async () => {
    setLoadingGetListingsFromMarketplaces(true);
    try {
      const { data } = await listingApi.getSyncStatus(session.userInfo.commercial_partner_id[0]);
      setLoadingGetListingsFromMarketplaces(!data.finished);
      setSyncStatus(data);
      if (!data.finished) {
        setTimeout(() => {
          getSyncStatus();
        }, 60000); //every 30 seconds
      }
    } catch (error) {
      setLoadingGetListingsFromMarketplaces(false);
    }
  }

  const getListingsFromMarkteplaces = async () => {
    mixPanelCreateEvent('Action Button', { action: 'Sync Listings' })
    setLoadingGetListingsFromMarketplaces(true);
    try {
      const { data } = await listingApi.getAllFromMkts(session.userInfo.commercial_partner_id[0]);
      setGetListings(true);
      setLoadingGetListingsFromMarketplaces(false);
      mixPanelCreateEvent('API Rest', {
        status: true,
        message: 'Sync Listings Success Response',
        method: 'GET',
        url: data?.config?.url,
        ipClient: ip.address()
      })
      getSyncStatus();
      openNotification({ status: true, content: "Los listings estan siendo sincronizados desde los marketplaces, en unos minutos estarán disponibles" });
    } catch (error) {
      mixPanelCreateEvent('API Rest', {
        status: error,
        message: 'Sync Listings Failed Response'
      })
      openNotification({ status: false, content: error?.response?.data?.message || t('home.listing.error') });
      setLoadingGetListingsFromMarketplaces(false);

    }
  }

  const cancelSyncListingsFromMarkteplaces = async () => {
    setLoadingGetListingsFromMarketplaces(true);
    try {
      const { data } = await listingApi.cancelSyncListings(session.userInfo.commercial_partner_id[0]);
      setGetListings(true);
      setLoadingGetListingsFromMarketplaces(false);
      getSyncStatus();
      openNotification({ status: true, content: "Se canceló el proceso de sincronización" });
    } catch (error) {
      openNotification({ status: false, content: error?.response?.data?.message || t('home.listing.error') });
      setLoadingGetListingsFromMarketplaces(false);

    }
  }

  const SyncTooltip = () => {
    return (
      <Space direction="vertical" size="small">
        <span>{t('home.listing.tooltipSyncListings')}</span>
        {syncStatus && syncStatus.createdAt ? <span>
          {syncStatus.finished ? 'Última sincronizacion: ' + moment(syncStatus.finishedAt).fromNow() : 'Última sincronizacion iniciada: ' + moment(syncStatus.createdAt).fromNow()}
        </span> : null}
      </Space>);
  }

  const handleNewListing = async () => {
    mixPanelCreateEvent('Action Button', { action: 'New Listing' })
    setLoadingCreateListing(true);
    await partnerApi
      .insertListing(session.userInfo?.commercial_partner_id[0])
      .then((res) => {
        mixPanelCreateEvent('API Rest', {
          status: true,
          message: 'New Listing Success Response',
          method: 'POST',
          url: res.config.url,
          ipClient: ip.address()
        })
        const listingId = res.data;
        setLoadingCreateListing(false);
        history.push(`onboarding/${listingId}`);
      })
      .catch((error) => {
        mixPanelCreateEvent('API Rest', {
          status: error,
          message: 'New Listing Failed Response'
        })
        setLoadingCreateListing(false);
        openNotification({
          status: false,
          content: "Error al crear un nuevo listing",
        });
      });
  };

  const syncFbaStockByPartner = async () => {
    mixPanelCreateEvent('Action Button', { action: 'Update listing stock' })
    const values = {
      partnerId: session?.userInfo?.commercial_partner_id[0],
    }
    setLoadingSyncStock(true);
    await listingApi.syncFbaStock(values)
      .then((result) => {
        mixPanelCreateEvent('API Rest', {
          status: true,
          message: 'Update Stock Quote Success Response',
          method: 'PUT',
          url: result.config.url,
          ipClient: ip.address()
        })
        openNotification({ status: true, content: 'Se sincronizó el stock con FBA y Mercado Libre' });
        setGetListings(true);
      })
      .catch((error) => {
        mixPanelCreateEvent('API Rest', {
          status: error,
          message: 'Update Stock Quote Failed Response'
        })
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setLoadingSyncStock(false);
  }

  const unlinkMatchesFBA = async () => {
    mixPanelCreateEvent('Action Button', { action: 'Unlink Matches for FBA' })
    const values = { partnerId: session?.userInfo?.commercial_partner_id[0] };
    setLoadingUnlinkMatchesFBA(true);
    await listingApi.unlinkMatchesFBA(values)
      .then((result) => {
        openNotification({ status: true, content: 'Se desvincularon los productos matcheados para FBA' });
        setGetListings(true);
        mixPanelCreateEvent('API Rest', {
          status: true,
          message: 'Unlink Matches for FBA Successful response',
          method: 'PUT',
          url: result.config.url,
          ipClient: ip.address()
        })
      })
      .catch((error) => {
        mixPanelCreateEvent('API Rest', {
          status: error,
          message: 'Unlink Matches for FBA Failed Response'
        })
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setLoadingUnlinkMatchesFBA(false);
  }

  const handleExport = async () => {
    const res = await listingApi.getDocument(session.userInfo.commercial_partner_id[0]);
    const wb = xlsx.read(res.data.data, { type: "buffer" });
    xlsx.writeFile(wb, "listings.xlsx");
  };

  const initialForm = { listingIds: [], partnerId: session?.userInfo?.commercial_partner_id[0], marketplaces: [] }
  const [loadingMarketplaces, setLoadingMarketplaces] = useState(false);
  const [loadingHomologateListing, setLoadingHomologateListing] = useState(false);
  const [showHomologate, setShowHomologate] = useState(false);
  const [marketplaces, setMarketplaces] = useState([]);
  const [formHomologateListing] = Form.useForm();
  const marketplaceSelect = useRef();

  useEffect(() => {
    formHomologateListing.setFieldsValue(initialForm);
  }, [formHomologateListing]);

  useEffect(() => {
    if (session && showHomologate) {
      if (marketplaces?.length == 0) {
        getMarketplacesAllowedByClient();
      }
      marketplaceSelect?.current?.focus();
    }
  }, [showHomologate]);

  const getMarketplacesAllowedByClient = async () => {
    setLoadingMarketplaces(true);
    try {
      let values = formHomologateListing.getFieldValue();
      const { data } = await listingApi.getMarketplacesAllowedByListingId(values?.listingIds[0]);
      setMarketplaces(data);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) })
    }
    setLoadingMarketplaces(false);
  }

  const rowSelectionActions = {
    onSelect: (record, selected) => {
      marketplaceSelect?.current?.focus();
      let values = formHomologateListing.getFieldValue();
      selected ?
        values['listingIds'] = [...values?.listingIds, record?.id] :
        values['listingIds'] = [...values?.listingIds?.filter(listingId => listingId != record?.id)];
      setShowHomologate(values?.listingIds?.length > 0);
      formHomologateListing.setFieldsValue(values);
    },
  };

  const onFinishHomologateListings = async (values) => {
    mixPanelCreateEvent('Action Button', { action: 'Homologate Listings' })
    setLoadingHomologateListing(true);
    await partnerApi.homologateListings(session.userInfo?.commercial_partner_id[0], values)
      .then((res) => {
        mixPanelCreateEvent('API Rest', {
          status: true,
          message: 'Homologate Listings Success Response',
          method: 'PUT',
          url: res?.config?.url,
          ipClient: ip?.address()
        });
        setGetListings(true);
      })
      .catch((error) => {
        mixPanelCreateEvent('API Rest', {
          status: error,
          message: 'Homologate Listings Failed Response'
        })
        openNotification({ status: false, content: getErrorMessage(error) });
      });
    setShowHomologate(false);
    setLoadingHomologateListing(false);
  }

  return (
    <Spin spinning={loadingCreateListing}>
      <Row className="home-listing-main-row listings-main">
        <Col span={24} xs={24} sm={24} md={24}>
          <Card className="home-listing-card">
            <Row >
              <Col span={syncStatus && !syncStatus.finished ? 5 : 8} md={24} >
                <div className="home-listing-card-content">
                  <Col>
                    <div className="home-listing-card-content">
                      <div style={{ textAlign: 'left' }}>
                        <span className="home-listing-title" style={{ fontSize: '22px', marginTop: '10px' }} >
                          <UnorderedListOutlined className="btn-primary home-listing-title-icon" />
                          <span style={{ marginLeft: 10 }}>Listing</span>
                        </span>
                      </div>
                      <span className="home-listing-title-description">
                        {t('home.listing.subTitle')}
                      </span>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={4} >
                <Link href={'/homologation/categories'}>
                  <Text type="danger">Categorías por homologar: {categoriesNotHomologated} </Text>
                </Link>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={20}
                className=" text-align-right botones-listings"
              >
                {syncStatus && syncStatus.createdAt && !syncStatus.finished &&
                  <Popconfirm placement="top" title={'¿Está seguro de cancelar el proceso de sincronización?'} onConfirm={cancelSyncListingsFromMarkteplaces} okText="Sí" cancelText="No">
                    <Button
                      icon={<CloseCircleOutlined />}
                      danger
                      className="margin-left-10"
                      disabled={!session.userInfo.x_can_publish}
                    >
                      Cancelar sincronización
                    </Button>

                  </Popconfirm>
                }
                <Button style={{ marginRight: 10 }}
                  icon={<PlusOutlined />}
                  className={profileCompleted ? "btn-link-filled margin-left-10" : "margin-left-10"}
                  disabled={!profileCompleted}
                  onClick={handleNewListing}
                >
                  {t('home.listing.add')}
                </Button>
                <Form
                  name="formHomologateListing"
                  form={formHomologateListing}
                  onFinish={onFinishHomologateListings}>
                  <Form.Item hidden name="listingIds" />
                  <Form.Item hidden name="partnerId" />
                  <Row style={{ width: showHomologate && 220, marginBottom: showHomologate && -24 }}>
                    {showHomologate &&
                      <Col span={11}>
                        <Form.Item name="marketplaces"
                          rules={[{ required: true }]}>
                          <Select
                            mode="multiple"
                            open={showHomologate}
                            ref={marketplaceSelect}
                            maxTagCount="responsive"
                            loading={loadingMarketplaces}
                            placeholder={t('selectPlaceholder')}
                          >
                            {marketplaces?.map((marketplace, index) => (
                              <Select.Option
                                key={index}
                                value={marketplace?.name}
                                disabled={!marketplace?.permissions}>
                                {marketplace?.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>}
                    <Col span={11}>
                      <Tooltip placement="bottom" title={t('home.listing.tooltipHomologateListing')}>
                        <Popconfirm
                          placement="top"
                          title={t('home.listing.homologateListingConfirm')}
                          onConfirm={() => formHomologateListing.submit()}
                          okText={t('yes')}
                          cancelText={t('no')}
                        >
                          <Button
                            loading={loadingHomologateListing}
                            className="btn-primary margin-left-10"
                            disabled={!profileCompleted || !showHomologate}>
                            {t('home.listing.homologateListing')}
                          </Button>
                        </Popconfirm>
                      </Tooltip>
                    </Col>
                  </Row>
                </Form>
                <Tooltip placement="topLeft" title={<SyncTooltip />}>
                  <Button
                    loading={loadingGetListingsFromMarketplaces}
                    icon={loadingGetListingsFromMarketplaces ? null : <RedoOutlined />}
                    className="btn-primary margin-left-10"
                    disabled={(session.userInfo.x_can_publish && session.userInfo.sellerAccountStatus) ? null : true}
                    onClick={getListingsFromMarkteplaces}
                  >
                    {' '}{t('home.listing.syncListings')}
                  </Button>
                </Tooltip>
                <Tooltip placement="topLeft" title={t('home.listing.tooltipSyncStockFBA')}>
                  <Button
                    icon={loadingSyncStock ? <Spin indicator={antIcon} /> : <RedoOutlined />}
                    className="btn-primary margin-left-10"
                    disabled={(session.userInfo.x_can_publish && session.userInfo.sellerAccountStatus) ? null : true}
                    onClick={syncFbaStockByPartner}
                  >
                    {' '}{t('home.listing.syncStockFBA')}
                  </Button>
                </Tooltip>
                <Tooltip placement="topLeft" title={t('home.listing.tooltipUnlinkMatchesFBA')}>
                  <Button
                    icon={loadingUnlinkMatchesFBA ? <Spin indicator={antIcon} /> : <ApiOutlined />}
                    className="btn-primary margin-left-10"
                    disabled={(session.userInfo.x_can_publish && session.userInfo.sellerAccountStatus) ? null : true}
                    onClick={unlinkMatchesFBA}
                  >
                    {' '}{t('home.listing.unlinkMatchesFBA')}
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row className="home-listing-table-parent">
              <Col xs={24} sm={24} md={24}>
                <TableListing
                  selectable={true}
                  rowSelectionActions={rowSelectionActions}
                  profileCompleted={profileCompleted}
                  partner={session && session.userInfo}
                  getListings={getListings} setGetListings={setGetListings}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Spin>
  )
};

export default React.memo(Listing);
