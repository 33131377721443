import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, InputNumber, Tooltip, Typography, Alert, DatePicker, Collapse, Space, Radio, Divider, Modal } from 'antd';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { openNotification } from '../../../../../components/Toastr';
import { onboardingTabs, sellerMarketplaces } from '../../../../../utils/const';
import { getErrorMessage } from '../../../../../api/api';
import partnerApi from '../../../../../api/partner';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import '../../../onboarding.css';
import 'antd/dist/antd.css';

const { Option } = Select;
const { Text, Title } = Typography;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

export default (props) => {
    const {
        propertiesInfo, orderRetrieved, defaultCode, listingId, getOrder, toggleModalEditVariant,
        canEditItem, form, path, currentTab, tab, isMain, productTypeByMkts, externalId, partnerSelfService
    } = props;

    const { t } = useTranslation();
    const { Item } = Form;
    const [isVisible, setIsVisible] = useState(true);
    const [attributesForVariants, setAttributesForVariants] = useState([]);
    const [attributesForCombinations, setAttributesForCombinations] = useState([]);
    const [selectedProperty, setSelectedProperty] = useState();
    const [showEditModal, setShowEditModal] = useState(false);
    const [saving, setSaving] = useState(false);
    const [modalForm] = useForm();
    const session = useSelector(store => store.Session.session);

    useEffect(() => {
        if (currentTab && productTypeByMkts && productTypeByMkts[currentTab]) {
            setAttributesForVariants(productTypeByMkts[currentTab]
                ?.attributes?.properties
                ?.filter(attr => attr.variationAttribute)
                ?.map(attr => attr.id));
            setAttributesForCombinations(productTypeByMkts[currentTab]
                ?.attributes?.properties
                ?.filter(attr => attr.attributeCombinations)
                ?.map(attr => attr.id));
        }
    }, [productTypeByMkts])


    const setDataForListingMarketplace = (orderRetrieved, marketplace) => {
        let data = !orderRetrieved ? form.getFieldValue() : orderRetrieved;
        let product = data[tab]?.listingPerMarketplace[marketplace]?.product;
        let variants = data[tab]?.listingPerMarketplace[marketplace]?.variants;
        if (product) {
            !product?.attributes['MPN'] && (product.attributes['MPN'] = product.defaultCode);
            !product?.attributes['fulfillmentType'] && (product.attributes['fulfillmentType'] = 'FBM');
            !product?.attributes['SELLER_SKU'] && (product.attributes['SELLER_SKU'] = product.defaultCode);
            data[tab].listingPerMarketplace[marketplace].product = product;
        }
        if (variants?.length > 0) {
            for (const [idx, variant] of variants?.entries()) {
                !variant.attributes['MPN'] && (variants[idx].attributes['MPN'] = variant.defaultCode);
                !variant?.attributes['fulfillmentType'] && (variant.attributes['fulfillmentType'] = 'FBM');
                !variant.attributes['SELLER_SKU'] && (variants[idx].attributes['SELLER_SKU'] = variant.defaultCode);
            }
            data[tab].listingPerMarketplace[marketplace].variants = variants;
        }
        return data;
    }

    const handleAmazonAttributesForCopy = (currentAttributes, attributesFromAmazon) => {
        const hasBrand = productTypeByMkts[currentTab]?.attributes?.properties?.find(property => property.id == 'BRAND');
        attributesFromAmazon = Array.isArray(attributesFromAmazon) ? attributesFromAmazon[0] : attributesFromAmazon;
        if (attributesFromAmazon?.product_description?.value) {
            [null, undefined, ''].includes(currentAttributes.description) &&
                (currentAttributes.description = attributesFromAmazon?.product_description?.value);
        }
        if (hasBrand && attributesFromAmazon?.brand?.value) {
            [null, undefined, ''].includes(currentAttributes['BRAND']) &&
                (currentAttributes['BRAND'] = attributesFromAmazon?.brand?.value);
        }
        if (attributesFromAmazon?.item_name?.value) {
            [null, undefined, ''].includes(currentAttributes.title) &&
                (currentAttributes.title = attributesFromAmazon?.item_name?.value);
        }
        return currentAttributes;
    }

    const handleAttributeCopyFromAmazon = (tab, marketplace, orderRetrieved) => {
        const values = orderRetrieved ? orderRetrieved : form.getFieldValue();
        if (values && values[tab] && values[tab]?.listingPerMarketplace
            && values[tab]?.listingPerMarketplace[sellerMarketplaces.AMAZON]) {
            const amazonProduct = form.getFieldValue([tab, 'listingPerMarketplace', sellerMarketplaces.AMAZON, 'product', 'attributes']);
            const meliAttributes = form.getFieldValue([tab, 'listingPerMarketplace', marketplace, 'product', 'attributes']);
            values[tab].listingPerMarketplace[marketplace].product.attributes = handleAmazonAttributesForCopy(meliAttributes, amazonProduct);

            const amazonVariants = form.getFieldValue([tab, 'listingPerMarketplace', sellerMarketplaces.AMAZON, 'variants']);
            if (amazonVariants) {
                for (const idx in values[tab].listingPerMarketplace[sellerMarketplaces.AMAZON].variants) {
                    const amazonVariant = form.getFieldValue([tab, 'listingPerMarketplace', sellerMarketplaces.AMAZON, 'variants', idx, 'attributes']);
                    const currentTabVariant = form.getFieldValue([tab, 'listingPerMarketplace', marketplace, 'variants', idx, 'attributes']);
                    if (currentTabVariant) {
                        values[tab].listingPerMarketplace[marketplace].variants[idx].attributes = handleAmazonAttributesForCopy(currentTabVariant, amazonVariant);
                    }
                }
            }
        }
        const formData = setDataForListingMarketplace(values, marketplace);
        return formData[tab].listingPerMarketplace[marketplace];
    }

    useEffect(() => {
        initComponent();
    }, [])

    const initComponent = () => {
        if (tab && currentTab
            && form
            && orderRetrieved
            && Object.entries(orderRetrieved).length > 0
            && currentTab.includes(sellerMarketplaces.MERCADOLIBRE)) {
            if (orderRetrieved[tab]) {
                if (orderRetrieved[tab]?.listingPerMarketplace) {
                    for (const marketplace of Object.keys(orderRetrieved[tab]?.listingPerMarketplace)) {
                        if (marketplace.includes(sellerMarketplaces.MERCADOLIBRE)) {
                            if (orderRetrieved[tab]?.listingPerMarketplace[sellerMarketplaces.AMAZON]) {
                                orderRetrieved[tab].listingPerMarketplace[marketplace] = handleAttributeCopyFromAmazon(tab, marketplace, orderRetrieved);
                                form.setFieldsValue(orderRetrieved);
                            } else {
                                form.setFieldsValue(setDataForListingMarketplace(orderRetrieved, marketplace));
                            }
                        }
                    }
                }
            }
            handleIsVisible(orderRetrieved);
        } else {
            handleIsVisible(null);
        }
    }

    const handleIsVisible = (values) => {
        const formData = values || form.getFieldValue();
        if ((session.userInfo.isAdmin && tab === onboardingTabs.LAP) || partnerSelfService) {
            setIsVisible(true);
        } else {
            if ([false, null, undefined, ''].includes(isMain)
                && formData[tab]?.listingPerMarketplace[currentTab]?.variants?.length > 0) {
                setIsVisible(true);
            } else if ((formData[tab]?.listingPerMarketplace[currentTab]?.variants?.length == 0 && isMain)) {
                setIsVisible(true);
            } else if ((formData[tab]?.listingPerMarketplace[currentTab]?.variants?.length > 0 && isMain)) {
                setIsVisible(false);
            } else {
                setIsVisible(true);
            }
        }
    }

    const inputTypeText = (property, inModal) => {
        return (
            <Item name={[...path, property.id]} style={{ width: '100%', marginBottom: property?.description ? 0 : 15 }}
                rules={[{
                    required: property.required,
                    message: `${property?.name?.replace(/_/g, " ")} ${t('isRequired')}`
                }]}>
                {property?.description ?
                    <Input
                        disabled={inModal ? false : (!canEditItem || property.disabled)}
                        maxLength={property?.maxLength || 50}
                        suffix={
                            property?.description && <Tooltip title={property.description} style={{ whiteSpace: 'pre-wrap' }}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>
                        }
                    /> :
                    <Input.TextArea showCount rows={1}
                        disabled={inModal ? false : (!canEditItem || property.disabled)}
                        maxLength={property?.maxLength || 50}
                        suffix={
                            property?.description && <Tooltip title={property.description} style={{ whiteSpace: 'pre-wrap' }}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>
                        }
                    />}
            </Item>
        )
    }

    const inputTypeNumber = (property, inModal) => {
        return (
            <Item style={{ marginBottom: 0 }} name={[...path, property.id]}
                rules={[{
                    required: property.required,
                    message: `${property?.name?.replace(/_/g, " ")} ${t('isRequired')}`
                }]}>
                <InputNumber style={{ width: '100%' }} min={0}
                    disabled={inModal ? false : (!canEditItem || property.disabled)} />
            </Item>
        )
    }

    const inputTypeNumberUnit = (property, inModal) => {
        return (<>
            <Item name={[...path, property.id, 'value']} style={{ width: '50%' }}>
                <Input
                    rules={[{
                        pattern: new RegExp("([0-9]{1,30}\\s*)+"),
                        message: "Format is wrong. Only numbers."
                    },]}
                    disabled={inModal ? false : (!canEditItem || property.disabled)}
                    addonAfter={<Form.Item
                        name={[...path, property.id, 'unit']}
                        noStyle>
                        <Select
                            placeholder="Seleccione"
                            showSearch autoClearSearchValue
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                                optionA?.children?.toString().toLowerCase().localeCompare(optionB.children.toString().toLowerCase())
                            }>
                            {property.options?.map((option, index) => (
                                <Option value={option?.name} key={index}>{option?.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>}
                />
            </Item>
        </>
        )
    }

    const inputTypeSelection = (property, inModal) => {
        return (
            <Item style={{ width: '100%', marginBottom: 0 }} name={[...path, property.id, 'id']}
                rules={[{
                    required: property.required,
                    message: `${property?.name?.replace(/_/g, " ")} ${t('isRequired')}`
                }]}>
                <Select allowClear style={{ width: '100%' }}
                    mode={property.multiValues ? "multiple" : null}
                    suffixIcon={property?.description && <Tooltip title={property.description}><InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } showSearch={true}
                    disabled={inModal ? false : (!canEditItem || property.disabled)}
                >
                    {property.options?.map((option, index) => (
                        <Option value={option?.id} key={index}>{option?.name}</Option>
                    ))}
                    {!property.attributeCombinations && <Option value={"-1"} key={"-1"}>N/A</Option>}
                </Select>
            </Item>
        )
    }

    const fulfillmentType = (inModal) => {
        return <Row>
            <Col span={8}>
                <Space direction="vertical" size="small">
                    <Title level={5} className="title-primary">{t('onboarding.fulfillment.type')}</Title>
                    <Text type="secondary">{t('onboarding.fulfillment.typeDescription')}</Text>
                </Space>
            </Col>
            <Col span={16}>
                <Item className="input-form-margin-bottom" name={[...path, "fulfillmentType"]}
                    rules={[{ required: true, message: 'Fulfillment es requerido' }]}
                    tooltip={{
                        title: t('onboarding.order.step1Input3Description'),
                        icon: <InfoCircleOutlined />,
                    }}>
                    <Radio.Group className="card-radio-group">
                        <Space direction="vertical" size="large" style={{ width: '100%' }}>
                            <>
                                <Radio style={{ marginTop: 12 }} value={'FBA'} disabled={inModal ? false : !canEditItem}>
                                    <Space direction="vertical">
                                        <p className="text-card-radio">{t('onboarding.fulfillment.FBA')}</p>
                                        <Text type="secondary">{t('onboarding.fulfillment.FBADescription')}</Text>
                                    </Space>
                                </Radio>
                                <Divider style={{ margin: 0 }} />
                            </>
                            <>
                                <Radio value={'FBM'} disabled={inModal ? false : !canEditItem}>
                                    <Space direction="vertical">
                                        <p className="text-card-radio">{t('onboarding.fulfillment.FBM')}</p>
                                        <Text type="secondary">{t('onboarding.fulfillment.FBMDescription')}</Text>
                                    </Space>
                                </Radio>
                            </>
                        </Space>
                    </Radio.Group>
                </Item>
            </Col>
        </Row>
    }

    const editProperty = (property, index) => {
        setSelectedProperty(property);
        setShowEditModal(true);
    }

    const saveModal = async (values) => {
        let newAttribute = values;
        for (const part of path) {
            newAttribute = newAttribute[part];
        }
        let attributeName = Array.isArray(newAttribute) ? Object.keys(newAttribute[0])[0] : Object.keys(newAttribute)[0];
        let data = { defaultCode: defaultCode, attributeName: attributeName, attributeValue: Array.isArray(newAttribute) ? newAttribute[0][attributeName] : newAttribute[attributeName] }
        setSaving(true);
        await partnerApi.updateProperty(session.userInfo.commercial_partner_id[0], listingId, currentTab, data)
            .then((response) => {
                openNotification({ status: true, content: t('onboarding.sentSuccessfully') });
                getOrder();
                if (!isMain) {
                    setShowEditModal(false);
                    toggleModalEditVariant(false);
                }
                setSelectedProperty(null);
            })
            .catch((error) => {
                openNotification({ status: false, content: getErrorMessage(error) });
            });
        setSaving(false);
    }

    return (
        <>
            {isVisible && (
                <>
                    <Collapse style={{ marginBottom: 24, marginRight: 45 }} bordered={false} defaultActiveKey={['1']}>
                        <Panel styles={{ textAlign: 'left' }} header={'Consideraciones'} key={1}>
                            <Row>
                                <Col span={24}>
                                    {session.userInfo.isAdmin &&
                                        <> <Text>Recuerde que el producto principal NO es un item, sólo complete los inputs requeridos y marcados con *.</Text><br /></>
                                    }
                                    <br /><Text>Atributos diferenciadores para variantes:</Text>
                                    <Text className="text-capitalize">{' '}
                                        {attributesForCombinations?.map(attr => attr.replace(/_/g, " ").toLowerCase())?.join(', ')}.</Text>
                                    <br /><Text>Atributos propios de cada variante:</Text>
                                    <Text className="text-capitalize">{' '}
                                        {attributesForVariants?.map(attr => attr.replace(/_/g, " ").toLowerCase()).join(', ')}.</Text>
                                    <br /><Text >
                                        Puedes enviar más de un código identificador para un mismo producto. En ese caso, debes enviar en el atributo GTIN todos los códigos de producto separados por coma.
                                    </Text>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    {propertiesInfo?.properties?.map((property, index) => (
                        <Row style={{ marginTop: 4, marginBottom: 4 }} key={index}>
                            <Col span={6}>
                                <Text className="text-capitalize">
                                    {property.required && <span style={{ color: 'red' }}>* </span>}
                                    {property.id?.replace(/_/g, " ").toLowerCase()}
                                    {/* {!canEditItem && externalId && session.userInfo.isAdmin && */}
                                    {!isMain && (attributesForCombinations.includes(property?.id) || attributesForVariants.includes(property?.id)) &&
                                        <Tooltip title={"Editar"}><EditOutlined onClick={() => {
                                            editProperty(property, index);
                                        }} style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    }
                                    {isMain &&
                                        <Tooltip title={"Editar"}><EditOutlined onClick={() => {
                                            editProperty(property, index);
                                        }} style={{ color: 'rgba(0,0,0,.45)' }} />
                                        </Tooltip>
                                    }
                                    {/* } */}
                                </Text>
                            </Col>
                            <Col xs={18} sm={18} md={18} key={index}>
                                {property.type === 'string' && inputTypeText(property)}
                                {property.type === 'list' && inputTypeSelection(property)}
                                {property.type === 'number' && inputTypeNumber(property)}
                                {property.type === 'number_unit' && inputTypeNumberUnit(property)}
                            </Col>
                        </Row>
                    ))}
                    <Divider />
                    <Col span={24}>
                        {path && fulfillmentType(false)}
                    </Col>
                    <Modal okButtonProps={{ loading: saving }} onOk={() => { modalForm.submit() }} title="Editar propiedad" style={{ width: 600 }} onCancel={() => { setShowEditModal(false) }} visible={showEditModal}>
                        <Form form={modalForm} initialValues={orderRetrieved} onFinish={saveModal}>
                            <Row style={{ marginTop: 0 }}>
                                {selectedProperty?.id == 'title' &&
                                    <Alert showIcon closable type={'warning'} style={{ margin: 5 }}
                                        description={'Podrás hacer todos los cambios que necesites en el campo tittle siempre que el producto no tenga ventas realizadas.'}
                                    />
                                }
                                <Col span={24}>
                                    {selectedProperty &&
                                        <>
                                            <Row style={{ marginTop: 4, marginBottom: 4 }}>
                                                <Col span={6}>
                                                    <Text className="text-capitalize">
                                                        {selectedProperty.required && <span style={{ color: 'red' }}>* </span>}
                                                        {selectedProperty.id?.replace(/_/g, " ").toLowerCase()}
                                                    </Text>
                                                </Col>
                                                <Col xs={18} sm={18} md={18}>
                                                    {selectedProperty.type === 'string' && inputTypeText(selectedProperty, true)}
                                                    {selectedProperty.type === 'list' && inputTypeSelection(selectedProperty, true)}
                                                    {selectedProperty.type === 'number' && inputTypeNumber(selectedProperty, true)}
                                                    {selectedProperty.type === 'number_unit' && inputTypeNumberUnit(selectedProperty, true)}
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </>)
            }
        </>
    )
}