import { Button, Col, Divider, Form, Row, Select, Skeleton, Switch, Typography } from 'antd';
import ip from 'ip';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getErrorMessage } from '../../../api/api';
import categoryApi from "../../../api/aws-category";
import listingApi from "../../../api/listing";
import partnerApi from "../../../api/partner";
import { openNotification } from '../../../components/Toastr';
import { onboardingTabs } from '../../../utils/const';
import { mixPanelCreateEvent } from '../../../utils/functions';
import AmazonPrices from '../../onboarding/components/steps/pricesByMarketplace/AmazonPrices';
import EbayPrices from '../../onboarding/components/steps/pricesByMarketplace/EbayPrices';
import MeliPrices from '../../onboarding/components/steps/pricesByMarketplace/MeliPrices';
import ShopifyPrices from '../../onboarding/components/steps/pricesByMarketplace/ShopifyPrices';
import WalmartPrices from '../../onboarding/components/steps/pricesByMarketplace/WalmartPrices';
import { AttributesHomologation } from '../attributes';

const { Title, Text } = Typography;

const initialForm = {
    partnerId: '',
    marketplaceOrigin: '',
    categoryOrigin: '',
    productTypeOrigin: '',
    marketplaceDestination: '',
    categoryDestination: '',
    productTypeDestination: '',
}

const formByMarketplace = {
    "Amazon": AmazonPrices,
    "Amazon Mexico": AmazonPrices,
    "Amazon Canada": AmazonPrices,
    "Amazon Brazil": AmazonPrices,
    "Walmart": WalmartPrices,
    "Ebay": EbayPrices,
    "Ebay Canada": EbayPrices,
    "Ebay Spain": EbayPrices,
    "Ebay Germany": EbayPrices,
    "Shopify": ShopifyPrices,
    "Mercadolibre Chile": MeliPrices,
    "Mercadolibre Mexico": MeliPrices,
}

const PricesByMarketplace = (props) => {
    let Comp = formByMarketplace[props.selectedMarketplace] || <></>;
    return <Comp {...props}></Comp>
}

export const CategoryHomologation = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const session = useSelector((store) => store.Session.session);
    const partnerId = session?.userInfo?.commercial_partner_id[0];

    const [homologated, setHomologated] = useState(true);
    const [origin, setOrigin] = useState(null);
    const [destination, setDestination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [marketplaces, setMarketplaces] = useState([]);
    const [loadingProperties, setLoadingProperties] = useState(false);
    const [loadingMarketplaces, setLoadingMarketplaces] = useState(false);
    const [categoriesHomologated, setCategoriesHomologated] = useState([]);
    const [attributesByMarketplace, setAttributesByMarketplace] = useState({});
    const [listing, setListing] = useState(null);

    useEffect(() => {
        if (partnerId) {
            cleanForm();
            getListingsPerCompany();
        }
    }, [partnerId]);

    useEffect(() => {
        if (listing) {
            getMarketplacesAllowed();
        }
    }, [listing]);

    const getListingsPerCompany = async () => {
        const values = {
            partnerId: partnerId,
            query: null,
            page: 1,
            take: 1
        }
        await partnerApi.getListingsPerPage(values)
            .then((result) => {
                const response = result?.data?.data;
                response?.length == 1 && setListing(response[0]);
            })
            .catch((error) => {
                openNotification({ status: false, content: getErrorMessage(error) });
            });
    }

    const getCategoriesHomologated = async () => {
        setLoading(true);
        try {
            const { data } = await partnerApi.getCategoriesHomologated(partnerId);
            setCategoriesHomologated(data);
        } catch (error) {
            openNotification({ status: false, content: getErrorMessage(error) })
        }
        setLoading(false);
    }

    const getMarketplacesAllowed = async () => {
        setLoadingMarketplaces(true);
        try {
            const { data } = await listingApi.getMarketplacesAllowedByListingId(listing?.id);
            setMarketplaces(data);
        } catch (error) {
            openNotification({ status: false, content: getErrorMessage(error) })
        }
        setLoadingMarketplaces(false);
    }

    const onFinish = async (values) => {
        const categoryOrigin = values[onboardingTabs.LAP]['listingPerMarketplace'][origin];
        const categoryDestination = values[onboardingTabs.LAP]['listingPerMarketplace'][destination];
        const payload = {
            partnerId: values.partnerId,
            origin: values.marketplaceOrigin,
            destination: values.marketplaceDestination,
            categoryOrigin: categoryOrigin.category,
            productTypeOrigin: categoryOrigin.productType,
            categoryDestination: categoryDestination.category,
            productTypeDestination: categoryDestination.productType,
            attributes: values.attributes
        }
        mixPanelCreateEvent('Action Button', { action: 'Homologate Categories' })
        setLoading(true);
        await partnerApi.homologateCategories(partnerId, payload)
            .then((res) => {
                mixPanelCreateEvent('API Rest', {
                    status: true,
                    message: 'Homologate Categories Success Response',
                    method: 'POST',
                    url: res?.config?.url,
                    ipClient: ip?.address()
                });
                cleanForm();
                openNotification({ status: true, content: t('homologation.categories.submitSuccess') });
            })
            .catch((error) => {
                mixPanelCreateEvent('API Rest', {
                    status: error,
                    message: 'Homologate Categories Failed Response'
                })
                openNotification({ status: false, content: getErrorMessage(error) });
            });
        setLoading(false);
    }

    const cleanForm = () => {
        setOrigin(null);
        setDestination(null);
        form.resetFields();
        form.setFieldsValue({ ...initialForm, partnerId: partnerId });
        getCategoriesHomologated();
    }

    const handleChangeMarketplace = async () => {
        let values = form.getFieldValue();
        const newOrigin = values.marketplaceOrigin;
        const newDestination = values.marketplaceDestination;
        const categoryOrigin = values[onboardingTabs.LAP]?.listingPerMarketplace[newOrigin];
        const categoryDestination = values[onboardingTabs.LAP]?.listingPerMarketplace[newDestination];

        newOrigin && setOrigin(newOrigin)
        newDestination && setDestination(newDestination)

        if (newOrigin && categoryOrigin?.category && categoryOrigin?.productType) {
            if (newDestination) {
                if (!categoryDestination?.category && !categoryDestination?.productType) {
                    const categoryMatched = setCategoryData(newOrigin, categoryOrigin?.productType, newDestination, categoryOrigin?.category);
                    values[onboardingTabs.LAP]['listingPerMarketplace'][newDestination] = categoryMatched
                }else{
                    canGetPropertiesByCategory()
                }
                form.setFieldsValue(values);
            }
        }
    }

    const setCategoryData = (mkpOrigin, productTypeOrigin, mkpDestination, categoryOrigin) => {
        return categoriesHomologated?.
            find(element => element.marketplaceOrigin == mkpOrigin
                && element.marketplaceDestination == mkpDestination
                && element.categoryOrigin == categoryOrigin
                && element.productTypeOrigin == productTypeOrigin);
    }

    useEffect(() => {
        handleChangeCategory();
    }, [origin, destination])

    const handleChangeCategory = async () => {
        const values = form.getFieldsValue();
        const originProductType = values[onboardingTabs.LAP]?.listingPerMarketplace[origin]?.productType;
        const destinationProductType = values[onboardingTabs.LAP]?.listingPerMarketplace[destination]?.productType;
        if (origin && originProductType && destination && destinationProductType) {
            await getAttributesByMarketplace(originProductType, destinationProductType);
        }
    }

    const canGetPropertiesByCategory = () => {
        handleChangeCategory();
    }

    const getAttributesByMarketplace = async (originProductType, destinationProductType) => {
        setLoadingProperties(true);
        let productTypesPromises = [
            {
                [origin]: {
                    productType: originProductType,
                    attributes: await getAttributesByProductType(origin, originProductType)
                }
            },
            {
                [destination]: {
                    productType: destinationProductType,
                    attributes: await getAttributesByProductType(destination, destinationProductType)
                }
            }]

        let productTypesData = {};
        const promisesResponse = await Promise.all(productTypesPromises);
        setLoadingProperties(false);
        promisesResponse?.map(prodTypeByMkt => productTypesData[Object.keys(prodTypeByMkt)] = prodTypeByMkt[Object.keys(prodTypeByMkt)]);
        setAttributesByMarketplace(productTypesData);
    }

    const getAttributesByProductType = async (marketplace, productType) => {
        let values = {};
        await categoryApi.getAttributesByMarketplace(partnerId, marketplace, productType)
            .then((response) => {
                let attributesRetrieved = response.data;
                values = orderAttributes(attributesRetrieved);
            })
            .catch((error) => {
                openNotification({ status: false, content: getErrorMessage(error) });
            });
        return values;
    }

    const orderAttributes = (attributesRetrieved) => {
        let attributesRequired = [];
        let attributesNotRequired = [];
        let orderedAttributes = [];
        attributesRetrieved?.properties?.forEach(property => {
            attributesRetrieved?.required?.includes(property?.name) ?
                attributesRequired.push(property) :
                attributesNotRequired.push(property);
        })
        orderedAttributes.push(...attributesNotRequired);
        orderedAttributes.unshift(...attributesRequired);
        return { ...attributesRetrieved, properties: orderedAttributes };
    }

    return (
        <Row style={{ marginTop: 50, padding: 35 }}>
            <Col>
                <Title level={2}>{t('homologation.categories.title')}</Title>
                <Divider />
            </Col>
            {(loading || loadingMarketplaces) ?
                <Col span={24} style={{ marginTop: 50 }}>
                    <Skeleton active={true} />
                </Col> :
                <Col span={24} style={{ textAlign: 'left' }}>
                    <Form
                        name="form"
                        form={form}
                        onFinish={onFinish}>
                        <Form.Item hidden name="partnerId" />
                        <Row>
                            <Col span={5}>
                                <Text>Vinculadas <Switch size="small" defaultChecked={homologated}
                                    onChange={() => setHomologated(!homologated)} /> </Text>
                                {categoriesHomologated?.length > 0 &&
                                    <Form.Item name="categoryNotHomologated">
                                        <Select
                                            maxTagCount="responsive"
                                            onChange={(option) => {
                                                const value = JSON.parse(option)
                                                let newValue = {
                                                    marketplaceOrigin: value?.origin,
                                                    marketplaceDestination: value?.destination,
                                                    [onboardingTabs.LAP]: {
                                                        ['listingPerMarketplace']: {
                                                            [value?.origin]: {
                                                                category: value?.categoryOrigin,
                                                                productType: value?.productTypeOrigin,
                                                            }
                                                        }
                                                    }
                                                }
                                                if (homologated) {
                                                    newValue['attributes'] = value?.attributes;
                                                    newValue[onboardingTabs.LAP]['listingPerMarketplace'][value?.destination] = {
                                                        category: value?.categoryDestination,
                                                        productType: value?.productTypeDestination,
                                                    }
                                                }
                                                form.setFieldsValue(newValue);
                                                handleChangeMarketplace()
                                            }}
                                            open={true}
                                        >
                                            {categoriesHomologated?.
                                                filter(category => homologated ? category.categoryDestination : !category.categoryDestination)?.
                                                map((category, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={JSON.stringify(category)}
                                                    >
                                                        {category?.origin} - {category?.categoryOrigin}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                }
                            </Col>
                            <Col span={1} />
                            <Col span={8}>
                                <Text>{t('homologation.categories.origin')}</Text>
                                <Form.Item name="marketplaceOrigin"
                                    rules={[{ required: true }]}>
                                    <Select
                                        maxTagCount="responsive"
                                        loading={loadingMarketplaces}
                                        placeholder={t('selectPlaceholder')}
                                        onChange={() => handleChangeMarketplace()}
                                    >
                                        {marketplaces?.map((marketplace, index) => (
                                            <Select.Option
                                                key={index}
                                                value={marketplace?.name}
                                                disabled={!marketplace?.permissions || destination == marketplace?.name}>
                                                {marketplace?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {origin &&
                                    <PricesByMarketplace
                                        canGetPropertiesByCategory={canGetPropertiesByCategory}
                                        partnerSelfService={true} pricesRetrieved={{}}
                                        selectedMarketplace={origin} step={null}
                                        category={null} currentTab={null} tab={onboardingTabs.LAP}
                                        form={form} session={session} partnerId={partnerId} />
                                }
                            </Col>
                            <Col span={1} />
                            <Col span={8}>
                                <Text >{t('homologation.categories.destination')}</Text>
                                <Form.Item name="marketplaceDestination"
                                    rules={[{ required: true }]}>
                                    <Select
                                        maxTagCount="responsive"
                                        loading={loadingMarketplaces}
                                        placeholder={t('selectPlaceholder')}
                                        onChange={() => handleChangeMarketplace()}
                                    >
                                        {marketplaces?.map((marketplace, index) => (
                                            <Select.Option
                                                key={index}
                                                value={marketplace?.name}
                                                disabled={!marketplace?.permissions || origin == marketplace?.name}>
                                                {marketplace?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {destination &&
                                    <PricesByMarketplace
                                        canGetPropertiesByCategory={canGetPropertiesByCategory}
                                        partnerSelfService={true} pricesRetrieved={{}}
                                        selectedMarketplace={destination} step={null}
                                        category={null} currentTab={null} tab={onboardingTabs.LAP}
                                        form={form} session={session} partnerId={partnerId} />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={19} offset={6}>
                                <AttributesHomologation
                                    origin={origin} destination={destination}
                                    form={form} loadingProperties={loadingProperties}
                                    attributesByMarketplace={attributesByMarketplace}
                                />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: 'right' }}>
                            <Col span={23}>
                                <Button onClick={cleanForm}>
                                    {t('homologation.categories.reset')}
                                </Button>{' '}
                                <Button type="primary" htmlType="submit">
                                    {t('homologation.categories.submit')}
                                </Button>
                            </Col>
                        </Row>

                    </Form>
                </Col>
            }
        </Row>
    )
}
