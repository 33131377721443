import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PictureOutlined, InfoCircleOutlined, SyncOutlined, FullscreenOutlined } from "@ant-design/icons";
import { Avatar, Col, Image, Row, Table, Tooltip, Typography, Form, Switch, Button, Space, Progress } from "antd";
import selectors from '../../../redux/analytic/dashboard/selectors';
import { Resizable } from 'react-resizable';
import { saveAs } from 'file-saver';
import dashboard from '../../../api/dashboard';
import saleOrderApi from '../../../api/order-sales';
import { openNotification } from '../../../components/Toastr';
import ModalTable from './charts/ModalTable'
import { sumaDataArray } from '../../../utils/functions';
import { mixPanelCreateEvent } from '../../../utils/functions';
import { marketplaceCurrency } from '../../../utils/const';

const { Text, Link } = Typography;

const ResizableTitle = props => {
    const { onResize, width, title, children, ...restProps } = props;

    if (!width) {
        return <th {...restProps}>{title}</th>;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={e => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps}>{title}</th>
        </Resizable>
    );
};

const DashBoardTable = () => {
    const { t } = useTranslation();

    const rows = useSelector(selectors.selectRows);
    const loading = useSelector(selectors.selectLoading);
    const filter = useSelector(selectors.selectRawFilter);
    const loadingProgress = useSelector(selectors.selectLoadingProgress);
    const session = useSelector(store => store.Session.session);

    const [expandable, setExpandable] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingOrderItems, setLoadingOrderItems] = useState(false);
    const [loadingProgressForClient, setLoadingProgressForClient] = useState(0);
    const [ordersWithoutOrderItems, setOrdersWithoutOrderItems] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [dataRow, setDataRow] = useState([]);
    const [dataProductModal, setDataProductModal] = useState([]);
    const [columnTable, setColumnTable] = useState([])
    const [titleModal, setTitleModal] = useState('');
    const [columnWidths, setColumnWidths] = useState({
        'name': 350, 'price': 80, 'sumTotalSold': 100, 'sumTotalSoldUSD': 150, 'sumTotalSoldVariance': 100, 'sumQuantitySold': 100, 'sumQuantitySoldVariance': 100,
        'ordersQuantity': 100, 'numberUnsoldDays': 100, 'salesShare': 100, 'availableStock': 100, 'totalStock': 100, 'daysOutStock': 100, 'refunds': 80
    })

    const columnsModalListProducts = [
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'SKU',
            dataIndex: 'defaultCode',
            key: 'defaultCode',
        },
        {
            title: 'Dias sin Stock',
            dataIndex: 'daysOutStock',
            key: 'daysOutStock'
        }
    ];

    const columnsMainModalListProducts = [
        {
            title: <Tooltip title="Agregar Tooltip">Ventas</Tooltip>,
            onHeaderCell: column => {
                return {
                    title: column.title,
                }
            },
            children: [
                {
                    ellipsis: true,
                    title: 'Products',
                    dataIndex: 'title',
                    key: 'title',
                    fixed: 'center',
                    width: 300,         
                    render: (value, record) => {
                        let component;
                        if (record.isListing) {
                            component = renderListing(record);
                        }
                        if (record.isProduct) {
                            component = renderProduct(record);
                        }
                        return component;
                    }
                },
                {
                    title: <Tooltip title="Precio de venta actual publicado">Precio <InfoCircleOutlined /></Tooltip>,
                    ellipsis: true,
                    dataIndex: 'price',
                    key: 'price',
                    width: columnWidths['price'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('price'),
                        }
                    },
                    // render: renderDolar,
                    render: (value, record) => renderCurrentCurrency(record, 'price'),
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de ventas en períodos seleccionados (expresado en USD)">Ventas (USD) <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSoldUSD',
                    width: columnWidths['sumTotalSoldUSD'],
                    align: 'center',
                    key: 'sumTotalSoldUSD',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumTotalSoldUSD'),
                        }
                    },
                    render: (value, record) => renderCurrentCurrency(record, 'sumTotalSoldUSD', marketplaceCurrency.Amazon),
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de unidades vendidas en período seleccionado">Unidades # <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumQuantitySold',
                    width: columnWidths['sumQuantitySold'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumQuantitySold'),
                        }
                    },
                    key: 'sumQuantitySold',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Cantidad de pedidos realizados en el período">Órdenes <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'ordersQuantity',
                    align: 'center',
                    width: columnWidths['ordersQuantity'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('ordersQuantity'),
                        }
                    },
                    key: 'ordersQuantity',
                },
                ,
                {
                    ellipsis: true,
                    title: <Tooltip title="Número de días sin ventas">Días sin ventas <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'numberUnsoldDays',
                    width: columnWidths['numberUnsoldDays'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('numberUnsoldDays'),
                        }
                    },
                    align: 'center',
                    key: 'numberUnsoldDays',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Porcentaje de participación en las ventas totales en cada marketplace">% de participación <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'salesShare',
                    width: columnWidths['salesShare'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('salesShare'),
                        }
                    },
                    align: 'center',
                    key: 'salesShare',
                    render: (value, record) => renderCurrentCurrency(record, 'salesShare', '%'),
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Unidades reembolsadas en período seleccionado">Reembolso <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'refunds',
                    width: columnWidths['refunds'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('refunds'),
                        }
                    },
                    align: 'center',
                    key: 'refunds',
                },  
            ]
        },   
        {
            title: <Tooltip title="Agregar Tooltip">Stock</Tooltip>,
            onHeaderCell: column => {
                return {
                    title: column.title,
                }
            },
            children: [
                {
                    ellipsis: true,
                    title: <Tooltip title="Stock Disponible">Disponible</Tooltip>,
                    dataIndex: 'availableStock',
                    key: 'availableStock',
                    width: 50,
                    align: 'center',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Stock Total">Total</Tooltip>,
                    dataIndex: 'totalStock',
                    key: 'totalStock',
                    width: 50,
                    align: 'center',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Dias sin Stock">Dias sin Stock</Tooltip>,
                    dataIndex: 'daysOutStock',
                    key: 'daysOutStock',
                    width: 50,
                    align: 'center',
                },
            ]
        }              
    ];

    const arrayClients = []

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (rows) {
            setLoadingProgressForClient(0);
            setOrdersWithoutOrderItems(Number(rows.reduce((total, { ordersWithoutOrderItems }) => total += ordersWithoutOrderItems, 0)));
        }
        orderRowsTable(rows)
    }, [rows]);

    const orderRowsTable = (data) => {
        let arrayMarketplace = [] 
        let arrayClient = [] 
        for (const clientFather of rows) {
            if (clientFather?.children?.length > 0) {                       
                for (const marketplace of clientFather?.children) {
                    const marketSinChildre = {
                        ...marketplace,
                        childrensTemp: marketplace.children
                    }
                    delete marketSinChildre.children
                    arrayMarketplace.push(marketSinChildre)
                }
            }
            arrayClient.push({
                ...clientFather,
                children: arrayMarketplace
            })
            arrayMarketplace = []
        }
        setColumnTable(arrayClient)
    }

    const openModal = (record) => {
        let arrayListing = []
        let structureData = {
            ...record,
            children: record?.childrensTemp
        }
        for (const listing of structureData?.children) {
            if (listing?.children?.length > 0) {
                arrayListing.push({
                    ...listing,
                    price: `-`,
                    numberUnsoldDays: sumaDataArray(listing?.children, 'numberUnsoldDays'),
                    salesShare: '-',
                    refunds: sumaDataArray(listing?.children, 'refunds'),
                    availableStock: sumaDataArray(listing?.children, 'availableStock'),
                    totalStock: sumaDataArray(listing?.children, 'totalStock'),
                    daysOutStock: sumaDataArray(listing?.children, 'daysOutStock'),
                })
            } else {
                arrayListing.push(listing)
            }
        }      
        setTitleModal(`Productos ${record.name}`)
        showModal()
        setDataProductModal(arrayListing)
    }

    const newRows = () => {
        let client = []
        let market = []
        let i = 0
        if (rows?.length > 0) {
            for (const clientFather of rows) {
                if (clientFather?.children?.length > 0) {                         
                    for (const marketplace of clientFather.children) {
                        delete marketplace.children
                        market.push({
                            ...marketplace
                        })
                    }
                }
                client.push({
                    ...clientFather,
                    children: market
                })
                market = []
                i++
            }
        }
        setDataRow(client)
    }

    const viewListProductsByClient = () => {
        let client = []
        let market = []
        let i = 0
        if (rows?.length > 0) {
            for (const clientFather of rows) {
                if (rows[i]?.children?.length > 0) {                         
                    for (const marketplace of rows[i].children) {
                        delete marketplace.children
                        market.push({
                            ...marketplace
                        })
                    }
                }
                client.push({
                    ...rows[i],
                    children: market
                })
                market = []
                i++
            }
        }
        setDataRow(client)
    }

    const exportExcel = async () => {
        mixPanelCreateEvent('Action Button', {action: 'Export details dashboard data in excel'})
        setLoadingExport(true);
        try {
            const { data } = await dashboard.exportAnalytics(rows);
            const filename = `Analytics.xlsx`;
            let blob = new Blob([data], { type: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(blob, filename);
        } catch (error) {
            console.log('onClickExportHandler#Orders', error);
        } finally {
            setLoadingExport(false);
        }
    }

    const syncSaleOrderItems = async (partnerId) => {
        setLoadingOrderItems(true);
        try {
            const { data } = await saleOrderApi.syncSaleOrderItems(partnerId || session.userInfo.commercial_partner_id[0]);
            openNotification({ status: true, content: "Los items estan siendo sincronizados, recargue la página en unos minutos." });
        } catch (error) {
            openNotification({ status: false, content: error?.response?.data?.message });
        }
        setLoadingOrderItems(false);
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const renderDolar = (value, row) => {
        return !value ? value : <span>{numberWithCommas(value)} USD</span>;
    }
    const renderCurrentCurrency = (value, row, currency) => {
        return !value[row] ? undefined : <span>{value[row] === '-' ? '-' : numberWithCommas(value[row])} {currency ? value[row] === '-' ? '' : currency : value?.marketplaceCurrency }</span>;
    }
    const renderPercent = (value, row) => {
        if ([undefined, null].includes(value)) {
            return '';
        }
        return {
            children: <span>{value} %</span>,
            props: {
                style: { color: parseInt(value) >= 0 ? "#5BD692" : "#FF0000" }
            }
        }
    };

    const renderPartner = (record) => {
        if (session.userInfo.isAdmin && record?.ordersWithoutOrderItems > 0) {
            return <Row>
                <Col span={12}>
                    <Text>{record.name}{' '}</Text>
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Tooltip title={`Tienes ${record?.ordersWithoutOrderItems} órdenes sin el detalle de los items. Los totales pueden no ser los correctos. Presiona para sincronizar.`}>
                            <Button icon={<SyncOutlined />} size={'small'} loading={loadingOrderItems}
                                onClick={() => syncSaleOrderItems(record.partnerId)}></Button>
                        </Tooltip>
                    </Row>
                </Col>
            </Row>
        } else {
            return <Text>{record.name}</Text>;
        }
    }
    const renderMarket = (record) => {
        return <a href="javascript:void(0)" onClick={() => openModal(record)}>{record.name} <FullscreenOutlined /></a>;
    }
    const renderListing = (record) => {
        return <Tooltip placement="rightTop" title={record.name}>{record.name.substring(0, 40)}</Tooltip>;
    }
    const renderProduct = (record) => {
        const imgUrl = record?.mainImages?.length > 0 ? record?.mainImages[0]?.url : null;
        return (<>
            <Row align="middle" justify="start">
                <Col span={24}>
                    <Space>
                        {imgUrl ?
                            <Avatar className="home-listing-table-avatar" src={<Image src={`${imgUrl}`} />} /> :
                            <Avatar className="home-listing-table-avatar" icon={<PictureOutlined />} />
                        }
                        <Space direction="vertical">
                            {getLinkToStore[record?.marketplace](record)}
                            <Tooltip title={record.name}>
                                SKU: {record.sku}
                            </Tooltip>
                        </Space>

                    </Space>
                </Col>
            </Row></>
        );
    }

    const getLinkToStore = {
        "Amazon": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.com/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Mexico": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.mx/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Canada": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.ca/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Amazon Brazil": (item) => {
            return item?.asin ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.amazon.br/dp/${item.asin}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.com/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Canada": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.ca/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Spain": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.es/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Ebay Germany": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.ebay.de/itm/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Walmart": (item) => {
            return item?.externalId ?
                <Tooltip placement="topLeft" title={item.title}><Link href={`https://www.walmart.com/ip/${item.externalId}`} target="_blank"> {`${item.title}`}</Link></Tooltip>
                : <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
        "Shopify": (item) => {
            return <Tooltip placement="topLeft" title={item.title}><Text> {`${item.title}`}</Text></Tooltip>
        },
    }

    const components = {
        header: {
            cell: ResizableTitle,
        },
    };

    const handleResize = index => (e, { size }) => {
        setColumnWidths(columns => {
            const nextColumns = { ...columns };
            nextColumns[index] = size.width
            return nextColumns;
        });
    };

    const sellerColums = [
        {
            ellipsis: true,
            title: rows?.length > 0 && <Row><Text style={{ textAlign: 'center', width: '100%' }}>Sellers</Text></Row>,
            dataIndex: 'name',
            key: 'name',
            fixed: 'center',
            width: 30,
            onHeaderCell: column => {
                return {
                    width: column.width,
                    title: column.title,
                    onResize: handleResize('name'),
                }
            },
            render: (value, record) => {
                let component;
                if (record.isPartner) {
                    component = renderPartner(record);
                }
                if (record.isMarket) {
                    component = renderMarket(record);
                }
                return component;
            }
        },
        {
            ellipsis: true,
            title: <Tooltip title="Total de ventas en períodos seleccionados (expresado en USD)">Ventas (USD) <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'sumTotalSoldUSD',
            align: 'center',
            key: 'sumTotalSoldUSD',
            onHeaderCell: column => {
                return {
                    width: column.width,
                    title: column.title,
                    onResize: handleResize('sumTotalSoldUSD'),
                }
            },
            render: renderDolar,
        },
        {
            ellipsis: true,
            title: <Tooltip title="Total de unidades vendidas en período seleccionado">Unidades # <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'sumQuantitySold',
            align: 'center',
            onHeaderCell: column => {
                return {
                    width: column.width,
                    title: column.title,
                    onResize: handleResize('sumQuantitySold'),
                }
            },
            key: 'sumQuantitySold',
        },
        {
            ellipsis: true,
            title: <Tooltip title="Cantidad de pedidos realizados en el período">Órdenes <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'ordersQuantity',
            align: 'center',
            onHeaderCell: column => {
                return {
                    width: column.width,
                    title: column.title,
                    onResize: handleResize('ordersQuantity'),
                }
            },
            key: 'ordersQuantity',
        },
        {
            ellipsis: true,
            title: <Tooltip title="Número de días sin ventas">Días sin ventas <InfoCircleOutlined /></Tooltip>,
            dataIndex: 'numberUnsoldDays',
            align: 'center',
            onHeaderCell: column => {
                return {
                    width: column.width,
                    title: column.title,
                    onResize: handleResize('numberUnsoldDays'),
                }
            },
            key: 'numberUnsoldDays',
        }       
    ];
    /*const salesColums = [
        {
            title: <Tooltip title="">Ventas</Tooltip>,
            onHeaderCell: column => {
                return {
                title: column.title,
            }},
            children: [
                {
                    title: <Tooltip title="Precio de venta actual publicado">Precio <InfoCircleOutlined /></Tooltip>,
                    ellipsis: true,
                    dataIndex: 'price',
                    key: 'price',
                    width: columnWidths['price'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('price'),
                    }},
                    render: renderDolar,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de ventas en períodos seleccionados">Ventas $ <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSold',
                    width: columnWidths['sumTotalSold'],
                    align: 'center',
                    key: 'sumTotalSold',
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('sumTotalSold'),
                    }},
                    render: renderDolar,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Variación de ventas respecto al período anterior">Variación $ <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSoldVariance',
                    width: columnWidths['sumTotalSoldVariance'],
                    align: 'center',
                    key: 'sumTotalSoldVariance',
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('sumTotalSoldVariance'),
                    }},
                    render: renderPercent,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de unidades vendidas en período seleccionado">Unidades # <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumQuantitySold',
                    width: columnWidths['sumQuantitySold'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('sumQuantitySold'),
                    }},
                    key: 'sumQuantitySold',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Variaciones de unidades vendidas respecto a período anterior">Variación # <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumQuantitySoldVariance',
                    align: 'center',
                    width: columnWidths['sumQuantitySoldVariance'],
                    key: 'sumQuantitySoldVariance',
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('sumQuantitySoldVariance'),
                    }},
                    render: renderPercent,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Cantidad de pedidos realizados en el período">Órdenes <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'ordersQuantity',
                    align: 'center',
                    width: columnWidths['ordersQuantity'],
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('ordersQuantity'),
                    }},
                    key: 'ordersQuantity',
                },
                ,
                {
                    ellipsis: true,
                    title: <Tooltip title="Número de días sin ventas">Días sin ventas <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'numberUnsoldDays',
                    width: columnWidths['numberUnsoldDays'],
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('numberUnsoldDays'),
                    }},
                    align: 'center',
                    key: 'numberUnsoldDays',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Porcentaje de participación en las ventas totales en cada marketplace">% de participación <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'salesShare',
                    width: columnWidths['salesShare'],
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('salesShare'),
                    }},
                    align: 'center',
                    key: 'salesShare',
                    render: renderPercent,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Unidades reembolsadas en período seleccionado">Reembolso <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'refunds',
                    width: columnWidths['refunds'],
                    onHeaderCell: column => {
                        return {
                        width: column.width,
                        title: column.title,
                        onResize: handleResize('refunds'),
                    }},
                    align: 'center',
                    key: 'refunds',
                },
            ]
        }
    ];*/

    /*const salesColums = [
        {
            title: <Tooltip title="">Ventas</Tooltip>,
            onHeaderCell: column => {
                return {
                    title: column.title,
                }
            },
            children: [
                {
                    title: <Tooltip title="Precio de venta actual publicado">Precio <InfoCircleOutlined /></Tooltip>,
                    ellipsis: true,
                    dataIndex: 'price',
                    key: 'price',
                    width: columnWidths['price'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('price'),
                        }
                    },
                    // render: renderDolar,
                    render: (value, record) => renderCurrentCurrency(record),
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de ventas en períodos seleccionados (expresado en USD)">Ventas (USD) <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSoldUSD',
                    width: columnWidths['sumTotalSoldUSD'],
                    align: 'center',
                    key: 'sumTotalSoldUSD',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumTotalSoldUSD'),
                        }
                    },
                    render: renderDolar,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de ventas en períodos seleccionados (expresado en moneda original)">Ventas (MO) <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSold',
                    width: columnWidths['sumTotalSold'],
                    align: 'center',
                    key: 'sumTotalSold',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumTotalSold'),
                        }
                    },
                    render: (value, record) => <span>{record?.sumTotalSold && (record?.marketplaceCurrency || record?.currency) ?
                        `${numberWithCommas(record?.sumTotalSold)} ${record?.marketplaceCurrency || record?.currency}` : ''}</span>,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de unidades vendidas en período seleccionado">Unidades # <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumQuantitySold',
                    width: columnWidths['sumQuantitySold'],
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumQuantitySold'),
                        }
                    },
                    key: 'sumQuantitySold',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Cantidad de pedidos realizados en el período">Órdenes <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'ordersQuantity',
                    align: 'center',
                    width: columnWidths['ordersQuantity'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('ordersQuantity'),
                        }
                    },
                    key: 'ordersQuantity',
                },
                ,
                {
                    ellipsis: true,
                    title: <Tooltip title="Número de días sin ventas">Días sin ventas <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'numberUnsoldDays',
                    width: columnWidths['numberUnsoldDays'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('numberUnsoldDays'),
                        }
                    },
                    align: 'center',
                    key: 'numberUnsoldDays',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Porcentaje de participación en las ventas totales en cada marketplace">% de participación <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'salesShare',
                    width: columnWidths['salesShare'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('salesShare'),
                        }
                    },
                    align: 'center',
                    key: 'salesShare',
                    render: renderPercent,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Unidades reembolsadas en período seleccionado">Reembolso <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'refunds',
                    width: columnWidths['refunds'],
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('refunds'),
                        }
                    },
                    align: 'center',
                    key: 'refunds',
                },
            ]
        }
    ];*/
    const salesColums = [
        {
            title: <Tooltip title="">Ventas</Tooltip>,
            onHeaderCell: column => {
                return {
                    title: column.title,
                }
            },
            children: [
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de ventas en períodos seleccionados (expresado en USD)">Ventas (USD) <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumTotalSoldUSD',
                    width: 20,
                    align: 'center',
                    key: 'sumTotalSoldUSD',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumTotalSoldUSD'),
                        }
                    },
                    render: renderDolar,
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Total de unidades vendidas en período seleccionado">Unidades # <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'sumQuantitySold',
                    width: 20,
                    align: 'center',
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('sumQuantitySold'),
                        }
                    },
                    key: 'sumQuantitySold',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Cantidad de pedidos realizados en el período">Órdenes <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'ordersQuantity',
                    align: 'center',
                    width: 20,
                    onHeaderCell: column => {
                        return {
                            width: column.width,
                            title: column.title,
                            onResize: handleResize('ordersQuantity'),
                        }
                    },
                    key: 'ordersQuantity',
                },
                ,
                {
                    ellipsis: true,
                    title: <Tooltip title="Número de días sin ventas">Días sin ventas <InfoCircleOutlined /></Tooltip>,
                    dataIndex: 'numberUnsoldDays',
                    width: 20,
                    align: 'center',
                    key: 'numberUnsoldDays',
                },
            ]
        }
    ];

    /*const stockColums = [
        {
            title: <Tooltip title="Agregar Tooltip">Stock</Tooltip>,
            onHeaderCell: column => {
                return {
                    title: column.title,
                }
            },
            children: [
                {
                    ellipsis: true,
                    title: <Tooltip title="Agregar Tooltip">Disponible</Tooltip>,
                    dataIndex: 'availableStock',
                    key: 'availableStock',
                    width: 50,
                    align: 'center',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Agregar Tooltip">Total</Tooltip>,
                    dataIndex: 'totalStock',
                    key: 'totalStock',
                    width: 50,
                    align: 'center',
                },
                {
                    ellipsis: true,
                    title: <Tooltip title="Agregar Tooltip">Dias sin Stock</Tooltip>,
                    dataIndex: 'daysOutStock',
                    key: 'daysOutStock',
                    width: 50,
                    align: 'center',
                },
            ]
        }
    ];*/
    const stockColums = []
    const getColumnsByFilter = () => {
        let columns = [];
        if (filter?.category?.includes('sales')) {
            columns.push(...salesColums);
        }
        if (filter?.category?.includes('stock')) {
            columns.push(...stockColums);
        }
        return columns;
    }

    const columns = [...sellerColums, ...getColumnsByFilter()];

    let progress = Math.floor((loadingProgress.progress * 100) / loadingProgress.totalToProcess);

    useEffect(() => {
        let timer;
        if (loadingProgress?.totalToProcess == 1) {
            if (loadingProgressForClient < 90) {
                timer = setTimeout(() => {
                    if (loadingProgressForClient < 90) {
                        setLoadingProgressForClient(loadingProgressForClient + 10);
                    }
                }, 2000);
            }
        }
        return () => clearTimeout(timer);
    }, [loadingProgress, loadingProgressForClient]);

    const tableLoading = {
        spinning: loading,
        indicator: <Progress type="circle" percent={loadingProgress.totalToProcess > 1 ? progress : loadingProgressForClient} width={80} />
    }

    return rows.length > 0 ?
        (<>
            <ModalTable width={1400} titleModal={titleModal} columns={[]} columnsMain={columnsMainModalListProducts} fba={null} data={dataProductModal} isModalVisible={isModalVisible} handleOk={handleOk} handleCancel={handleCancel} />
            <Row>
                <Col sm={17} md={17} span={17}>
                    <Form
                        layout="inline"
                        style={{ marginBottom: 16 }}
                    >
                        <Form.Item
                            label={t('dashboard.fields.expandable')}>
                            <Switch onChange={setExpandable} />
                        </Form.Item>
                    </Form>
                </Col>
                <Col sm={7} md={7} span={7}>
                    <Row justify="end">
                        {ordersWithoutOrderItems > 0 && !session.userInfo.isAdmin &&
                            <>
                                <Col span={14} style={{ paddingRight: 5 }}>
                                    <Tooltip title={t('onboarding.draft.syncOrderItemsDescription')}>
                                        <Button
                                            loading={loadingOrderItems}
                                            className="draft-buttons btn-basic-green"
                                            onClick={syncSaleOrderItems}>{t('onboarding.draft.syncOrderItems')}
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </>
                        }
                        <Col span={10}>
                            <Button
                                loading={loadingExport}
                                className="draft-buttons btn-basic-green"
                                onClick={exportExcel}>{t('onboarding.draft.exportExcel')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {expandable ? (
                <Table key="table-expand"
                    bordered
                    expandable={{ defaultExpandAllRows: true, rowExpandable: record => true, indentSize: 30 }}
                    loading={tableLoading}
                    columns={sellerColums}
                    components={components}
                    dataSource={columnTable}
                    pagination={false}
                    size="small"
                    scroll={{
                        y: 500,
                        x: 1500,
                    }} />
            ) :
                (<Table
                    key="table-not-expand-all"
                    bordered
                    expandable
                    loading={tableLoading}
                    components={components}
                    columns={sellerColums}
                    dataSource={columnTable}
                    pagination={false}
                    size="small"
                    scroll={{
                        y: 500,
                        x: 1500,
                    }} />
                )}
        </>) :
        (
            <Table key="loading-not-done" bordered columns={columns} loading={tableLoading} />
        );
};

export default React.memo(DashBoardTable);